import { FC, useEffect, useState } from "react";
import InvoicesTable from "./InvoicesTable";
import { InvoiceDetailsProps } from "./InsuredInvoiceDetails/InsuredInvoiceDetailsProps";
import InsuredInvoiceDetails from "./InsuredInvoiceDetails/InsuredInvoiceDetails";
type InvoicesTableProps = {
  insuredId: number;
  tabKey: string;
};

const InvoiceTabContent: FC<InvoicesTableProps> = ({ insuredId, tabKey }) => {
  const [tableContainerKey, setTableContainerKey] = useState(0);
  const [currentPolicy, setCurrentPolicy] = useState<number | null>(null);

  const invoiceDefaultValues: InvoiceDetailsProps = {
    insuredId: insuredId,
    invoiceId: 0,
    policyId: currentPolicy,
    formDataChanged: false,
    originalFormData: null,
    renderingOption: "table",
    tabKey: tabKey,
  };

  const [invoiceDetailsProps, setInvoiceDetailsProps] =
    useState<InvoiceDetailsProps>({
      ...invoiceDefaultValues,
      policyId: currentPolicy,
      insuredId: insuredId,
      tabKey: tabKey,
    });

  useEffect(() => {
    if (
      invoiceDetailsProps.formDataChanged === true &&
      invoiceDetailsProps.renderingOption === "table"
    ) {
      setTableContainerKey(tableContainerKey + 1);
    }
  }, [invoiceDetailsProps]);

  useEffect(() => {
    if (invoiceDetailsProps?.renderingOption === "add-view") {
      setInvoiceDetailsProps({
        ...invoiceDetailsProps,
        policyId: currentPolicy,
        insuredId: insuredId,
        tabKey: tabKey,
      });
    }
  }, [invoiceDetailsProps?.renderingOption]);

  const editInvoiceIconClicked = (metaData) => {
    setInvoiceDetailsProps({
      ...invoiceDetailsProps,
      insuredId: parseInt(metaData?.InsuredId),
      invoiceId: parseInt(metaData?.InvoiceId),
      formDataChanged: false,
      originalFormData: null,
      renderingOption: "edit-view",
    });
  };

  const renderInsuredContent = () => {
    if (invoiceDetailsProps.renderingOption === "table") {
      return (
        <InvoicesTable
          insuredId={insuredId}
          tabKey={tabKey}
          editInvoiceIconClicked={editInvoiceIconClicked}
          currentPolicy={currentPolicy}
          setCurrentPolicy={setCurrentPolicy}
          addButtonClicked={() => {
            setInvoiceDetailsProps({
              ...invoiceDefaultValues,
              renderingOption: "add-view",
            });
          }}
          key={tableContainerKey}
        />
      );
    }
    if (
      ["edit-view", "add-view"].includes(invoiceDetailsProps.renderingOption)
    ) {
      return (
        <InsuredInvoiceDetails
          invoiceDetailsProps={invoiceDetailsProps}
          setInvoiceDetailsProps={setInvoiceDetailsProps}
        />
      );
    }
    return <></>;
  };
  return <>{renderInsuredContent()}</>;
};
export default InvoiceTabContent;
