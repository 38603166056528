import { AssignmentTypeDto } from "../../../../dtos/assignment-type-dto";
import { AssignmentTypesEnum } from "../../../../dtos/assignment-types-enum";
import { PolicyUpdateStatusDto } from "../../../../dtos/policy-update-status-dto";
import { SelectOptions } from "../../../../dtos/select-options";

export type PolicyStatusModalConfig = {
  isOpen: boolean;
  policyId: number | null;
  quoteId: number | null;
  currentStatus: number | null;
  isAudit: boolean;
  insuredId: number | null;
};

export type PolicyAssignmentModalConfig = {
  isOpen: boolean;
  policyId: number | null;
};

export type PolicyChangeStatusModalProps = {
  tabKey: string;
  modalConfiguration: PolicyStatusModalConfig;
  closeModal: () => void;
  onSaveSuccessful?: (policyUpdated?: PolicyUpdateStatusDto | null) => void;
  auditStatusOptions: SelectOptions[];
  assignmentsGroups: AssignmentTypeDto[] | null;
};

export type PolicyStatusFormData = {
  policyId: number | null;
  insuredId: number | null;
  quoteId: number | null;
  status: number;
  description: string | null;
  comments: string;
  assigneeId: number | null;
  newAssigneeId: number | null;
  assigneeType: number | null;
};

export const defaultFormData = {
  policyId: null,
  insuredId: null,
  quoteId: null,
  status: -1,
  description: null,
  comments: "",
  assigneeId: null,
  newAssigneeId: null,
  assigneeType: null,
};

export const getTeamForPolicy = (teams) =>
  teams.filter(
    (team) => parseInt(team?.stringValue ?? "0") === AssignmentTypesEnum.POLICY
  );

export const getCurrentAssigneeId = (
  assignmentsGroups,
  policyId
): number | null => {
  const assignmentsGroupsForPolicy = assignmentsGroups?.find(
    (assignmentGroup) =>
      assignmentGroup?.assignmentType === AssignmentTypesEnum.POLICY
  );
  return (
    assignmentsGroupsForPolicy?.assignments?.find(
      (assignment) => assignment?.assignmentForeignId === policyId
    )?.assigneeId ?? null
  );
};

export const getClearAssignmentOptionIfNeeded = (
  assigneeId: number | null
): Partial<SelectOptions>[] => {
  if (assigneeId !== null) {
    return [{ intValue: -1, displayName: "Clear Assignment" }];
  }
  return [];
};
