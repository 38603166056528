import { FC, useState } from "react";
import { Box } from "@mui/material";
import { Col, Font, Row, Switch } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { getConfigurationForColumn } from "../PolicyCalculator/PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import { documentsGroupRowStyles } from "./DocumentStyles";
import { PolicyQuoteDocumentIndividualProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { usePermissions } from "../../../../hooks";
import Link from "../../../TrueUI/Typography/Link";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import {
  ERROR_TEMPLATE_NAME,
  getSourceJSONFromDocSourceForIndividualPrinting,
} from "./DocumentsUtils";
import { DocSourceEnum } from "../../../../dtos/doc-source-enum";

const DocumentIndividual: FC<PolicyQuoteDocumentIndividualProps> = ({
  tabKey,
  policyDocuments,
  selectedDocuments,
  customJSONToPrint,
  onSelectedCheckboxDocument,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const hasPermission = usePermissions([1, 2, 3]); // TODO: @elara Update until Antonio's PR will be ready);

  const onCheckBoxChange = (checked: boolean, docId: number) => {
    onSelectedCheckboxDocument(checked, docId, "document");
  };

  const evaluatePrintingConfigurations = (
    document: ProgramPolicyDocumentDto,
    templateNames: string[]
  ) => {
    if (templateNames.includes(ERROR_TEMPLATE_NAME)) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "No print template found.",
      });

      return { isFullyConfigured: false };
    }

    if (
      document.docSource === DocSourceEnum.CUSTOM_JSON &&
      customJSONToPrint === ""
    ) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          "There is no custom JSON set up for the current policy period.",
      });

      return { isFullyConfigured: false };
    }

    return { isFullyConfigured: true };
  };

  const printIndividualDocument = (document: ProgramPolicyDocumentDto) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    const templateNames =
      document.fileId !== null
        ? [document.templateName ?? ERROR_TEMPLATE_NAME]
        : policyMergeFields?.endorsementForms?.map((form) => {
            return form.templateName ?? ERROR_TEMPLATE_NAME;
          }) ?? [];
    const { isFullyConfigured } = evaluatePrintingConfigurations(
      document,
      templateNames
    );

    if (isFullyConfigured) {
      sendMergeFormRequest({
        ...getSourceJSONFromDocSourceForIndividualPrinting(
          document,
          customJSONToPrint,
          getAtom()
        ),
        templateNameWithExtensionList: templateNames,
        customFileName: `${policyMergeFields?.insured?.insuredName ?? ""} ${
          document.docName ?? ""
        }`,
      });
    }
  };

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection={"column"}>
      <Col {...getConfigurationForColumn(12, "flex-start", "center")}>
        <Font>INDIVIDUAL POLICY DOCUMENTS</Font>
      </Col>
      {policyDocuments.map((document, idx) => (
        <Box sx={documentsGroupRowStyles()} key={`documents-individual-${idx}`}>
          <Col {...getConfigurationForColumn(12, "flex-start", "center")}>
            <Switch
              inputWidth="auto"
              tabIndex={idx}
              control="checkbox"
              isChecked={selectedDocuments.includes(document.programDocId)}
              onChangeIsChecked={(checked) =>
                onCheckBoxChange(checked, document.programDocId)
              }
              primaryLabelColor
              labelFontType={"BODY"}
              readOnly={!hasPermission}
            />
            <Link
              linkFontType="BODY"
              displayValue={`${document.docName}`}
              onClick={() => printIndividualDocument(document)}
            />
          </Col>
        </Box>
      ))}
      <DialogConfirmation
        {...dialogConfiguration}
        id="id-dialog-confirmation-document-individual"
        optionYesOverrideLabel="OK"
        onOptionYesEvent={() => setDialogConfiguration(null)}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </Row>
  );
};

export default DocumentIndividual;
