import { PolicyExposureBlob } from "../../../../../../dtos/policy-exposure-blob";
import { PolicyRatingBlob } from "../../../../../../dtos/policy-rating-blob";
import { PolicyStateBlob } from "../../../../../../dtos/policy-state-blob";
import { QuoteRatingTypeEnum } from "../../../../../../dtos/quote-rating-type-enum";
import { GlobalInsuredAtomProperties } from "../../../../InsuredAtoms";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getPolicyQuoteStates,
  getStateByStateCodeAndDates,
  getStateByStateCodeAndDatesByStates,
} from "../../../PolicyQuoteForm/PolicyQuoteUtils";
import { isDateEqualDate } from "../../../../../../utilities/dateFunctions";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { PolicyLimitDto } from "../../../../../../dtos/policy-limit-dto";
import { sumAllValuesOfAnArray } from "../../../../../../utilities/arrayFunctions";
import { PolicyLimitsOption } from "../../../../../../dtos/policy-limits-option";
import { RateElementsConfigurationDto } from "../../../../../../dtos/rate-elements-configuration-dto";
import { PolicyReferenceDto } from "../../../../../../dtos/policy-reference-dto";
import { getHazardGroupOfHighestManualPremiumItem } from "../../ExposureTable/ExposureTableRowFunctions";
import { DiscountDto } from "../../../../../../dtos/discount-dto";
import { customRound } from "../../../../../../utilities/stringFunctions";
import { divideAndRound } from "../../../../../../utilities/mathFunctions";
import { sumExposuresAmount } from "../../../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import { RateOptionElementDto } from "../../../../../../dtos/rate-option-element-dto";
import { RatingRateOption } from "../../../../../../dtos/rating-rate-option";
import { PolicyReferenceOption } from "../../../../../../dtos/policy-reference-option";

export const PREMIUM_BASIS_PREVIOUS = 0;

export const getRatingInAtomForCurrentState = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rating: PolicyRatingBlob,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );
  const ratingInAtom = currentState?.ratings?.find(
    (ratingInAtom) => ratingInAtom.rateElementID === rating.rateElementID
  );

  return ratingInAtom ? ratingInAtom : rating;
};

export const getStatesUpdatedByRatingList = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  ratings: PolicyRatingBlob[],
  atomValue: GlobalInsuredAtomProperties | null,
  configurations?: RateElementsConfigurationDto | null
) => {
  const currentStates = getPolicyQuoteStates(atomValue?.policyQuoteInformation);

  const newStates = currentStates.map((state) => {
    if (
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
    ) {
      return {
        ...state,
        rateElementConfigurations: configurations,
        ratings: ratings,
      };
    } else {
      return { ...state };
    }
  });

  return newStates;
};

export const getConfigurationForColumn = (
  columnSpan: number,
  horizontalAlign: "flex-start" | "flex-end" | "center",
  verticalAlign: "flex-start" | "flex-end" | "center",
  className?: string
) => {
  return {
    breakpoints: { md: columnSpan, lg: columnSpan, xl: columnSpan },
    horizontalAlign: horizontalAlign,
    verticalAlign: verticalAlign,
    className,
  };
};

const getPolicyLimitValidatedOption = (
  rating: PolicyRatingBlob,
  configurations?: RateElementsConfigurationDto | null
) => {
  if (
    rating.elementType === QuoteRatingTypeEnum.POLICY_LIMITS &&
    rating.policyLimits?.policyLimitsID
  ) {
    return rating.policyLimits;
  }
  if (
    rating.elementType === QuoteRatingTypeEnum.POLICY_LIMITS &&
    !rating.policyLimits?.policyLimitsID
  ) {
    const defaultOption =
      configurations !== undefined &&
      configurations !== null &&
      configurations.policyLimitsOptions.length > 0
        ? configurations.policyLimitsOptions[0]
        : null;

    return {
      policyLimitsID: defaultOption?.id ?? 0,
      optionDisplay: defaultOption?.displayValue ?? "",
      elRate: defaultOption?.rate ?? 0,
    } as PolicyLimitsOption;
  }
  return rating.policyLimits;
};

const getPolicyReferenceValidatedOption = (rating: PolicyRatingBlob) => {
  if (
    rating.elementType === QuoteRatingTypeEnum.X_MOD &&
    rating.policyReference?.policyReferenceID
  ) {
    return rating.policyReference;
  }
  if (
    rating.elementType === QuoteRatingTypeEnum.X_MOD &&
    !rating.policyReference?.policyReferenceID
  ) {
    return {
      policyReferenceID: -1,
      optionDisplay: "No Mod Applicable",
    } as PolicyReferenceOption;
  }
  return rating.policyLimits;
};

const getRateOptionValidatedOption = (
  rating: PolicyRatingBlob,
  rateOptionList?: RateOptionElementDto[]
) => {
  if (
    rating.elementType === QuoteRatingTypeEnum.SELECTION &&
    rating.rateOption?.rateOptionID
  ) {
    return rating.rateOption;
  }
  if (
    rating.elementType === QuoteRatingTypeEnum.SELECTION &&
    !rating.rateOption?.rateOptionID
  ) {
    const defaultOption =
      rateOptionList !== undefined &&
      rateOptionList !== null &&
      rateOptionList.length > 0
        ? rateOptionList[0]
        : null;

    return {
      rateOptionID: defaultOption?.id,
      optionDisplay: defaultOption?.displayValue,
    } as RatingRateOption;
  }
  return rating.rateOption;
};

export const getInitialRowResult = (
  rating: PolicyRatingBlob,
  configurations?: RateElementsConfigurationDto | null,
  rateOptionList?: RateOptionElementDto[]
) => {
  const policyLimits = getPolicyLimitValidatedOption(rating, configurations);
  const policyReference = getPolicyReferenceValidatedOption(rating);
  const rateOption = getRateOptionValidatedOption(rating, rateOptionList);

  return {
    calculatedAmount: rating.amount ?? 0,
    runningTotal: rating.runningTotal ?? 0,
    rate:
      rating.elementType === QuoteRatingTypeEnum.ENTERED_AMOUNT
        ? rating.rate ?? 0
        : rating.rate ?? 1,
    optionalElement: rating.optionalElement ?? false,
    isHidden: rating.isHidden ?? false,
    policyLimits,
    policyReference,
    rateOption,
  } as PremiumTableRowResultProps;
};

export const sumManualPremium = (exposures?: PolicyExposureBlob[]) => {
  return exposures !== undefined && exposures?.length > 0
    ? exposures
        .map((exposure) => exposure?.manualPremiumAtMod ?? 0)
        .reduce((a, b) => (a ?? 0) + (b ?? 0))
    : 0;
};

export const getPreviousRunningTotal = (
  rateIndex: number,
  ratingList?: PolicyRatingBlob[] | null
) => {
  const previousRates = ratingList?.filter(
    (rating, index) =>
      index < rateIndex &&
      Object.values(QuoteRatingTypeEnum).includes(rating.elementType ?? 0)
  );
  const ratesFromLastToFirst = previousRates?.reverse();
  const previousValidRateRunningTotal =
    ratesFromLastToFirst?.find((rating) => !rating.isHidden)?.runningTotal ?? 0;

  return previousValidRateRunningTotal;
};

export const getRunningTotalByPremiumBasisRow = (
  rateIndex: number,
  rating: PolicyRatingBlob,
  currentState?: PolicyStateBlob
) => {
  const runningTotal =
    rating.premiumBasis === PREMIUM_BASIS_PREVIOUS
      ? getPreviousRunningTotal(rateIndex ?? 0, currentState?.ratings)
      : currentState?.ratings?.find(
          (currentRating) => currentRating.rateElementID === rating.premiumBasis
        )?.runningTotal;

  return runningTotal ?? 0;
};

const getRatingWithDefaultValues = (rating: PolicyRatingBlob) =>
  ({
    ...rating,
    amount: rating.amount ?? 0,
    runningTotal: rating.runningTotal ?? 0,
    rate: rating.rate ?? 1,
    rateOption: rating.rateOption ?? null,
    policyLimits: rating.policyLimits ?? null,
    policyReference: rating.policyReference ?? null,
    optionalElement: rating.optionalElement ?? null,
    scheduleRatings: rating.scheduleRatings ?? null,
    isHidden: rating.isHidden ?? null,
  } as PolicyRatingBlob);

export const getRatingsUpdatedByAtomValue = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  ratingsOnDB: PolicyRatingBlob[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDates(
    stateCode,
    effectiveDate,
    expirationDate,
    atomValue
  );

  const readOnly = atomValue?.policyQuoteInformation?.readOnly;

  const newRatings = readOnly
    ? currentState?.ratings?.map((rating) => getRatingWithDefaultValues(rating))
    : ratingsOnDB.map((ratingOnDB) => {
        const ratingInJSON = currentState?.ratings?.find(
          (rating) => rating.rateElementID === ratingOnDB.rateElementID
        );
        if (ratingInJSON !== undefined) {
          return getRatingWithDefaultValues(ratingInJSON);
        }

        return getRatingWithDefaultValues(ratingOnDB);
      });

  return newRatings;
};

export const getRatingsUpdatedByState = (currentState: PolicyStateBlob) => {
  const newRatings = currentState?.ratings?.map(
    (rating) =>
      ({
        ...rating,
        amount: rating.amount ?? 0,
        runningTotal: rating.runningTotal ?? 0,
        rate: rating.rate ?? 1,
        rateOption: rating.rateOption ?? null,
        policyLimits: rating.policyLimits ?? null,
        policyReference: rating.policyReference ?? null,
        optionalElement: rating.optionalElement ?? null,
        scheduleRatings: rating.scheduleRatings ?? null,
        isHidden: rating.isHidden ?? null,
      } as PolicyRatingBlob)
  );

  return newRatings;
};

export const getPolicyLimitAsSelectOptionList = (
  policyLimits: PolicyLimitDto[]
) => {
  return policyLimits.map((policyLimit) => ({
    displayName: policyLimit.displayValue,
    intValue: policyLimit.id ?? 0,
  })) as Partial<SelectOptions>[];
};

export const getConfigurationListBySourceName = (
  sourceName: string,
  hazardGroup: string,
  configuration?: RateElementsConfigurationDto | null
) => {
  if (configuration !== undefined && configuration !== null) {
    return configuration?.rateOptions.filter(
      (rateOption) =>
        rateOption.sourceName === sourceName &&
        (rateOption.hazardGroup === hazardGroup ||
          rateOption.hazardGroup === null)
    );
  }
  return [];
};

export const getRateOptionAsSelectOptionList = (
  sourceName: string,
  currentState?: PolicyStateBlob | null,
  configuration?: RateElementsConfigurationDto | null
) => {
  if (configuration !== undefined && configuration !== null) {
    const hazardGroup = getHazardGroupOfHighestManualPremiumItem(
      currentState?.exposures ?? []
    );
    const optionsBySourceName = getConfigurationListBySourceName(
      sourceName,
      hazardGroup,
      configuration
    ).sort((a, b) => b.rate - a.rate);

    const filteredOptionsAsSelectOptions = optionsBySourceName.map(
      (rateOption) => ({
        displayName: rateOption.displayValue,
        intValue: rateOption.id ?? 0,
      })
    ) as Partial<SelectOptions>[];

    return {
      selectOptions: filteredOptionsAsSelectOptions,
      filteredOptions: optionsBySourceName,
    };
  }
  return { selectOptions: [], filteredOptions: [] };
};

export const getPolicyReferenceAsSelectOptionList = (
  policyReferenceList: PolicyReferenceDto[]
) => {
  return policyReferenceList.map((policyReference) => ({
    displayName: policyReference.value1,
    intValue: policyReference.valueN ?? 0,
  })) as SelectOptions[];
};

export const getProRateCalculation = (
  value: number,
  numberOfDaysInYear: number,
  numberOfDaysInPolicy: number
) => (numberOfDaysInPolicy / numberOfDaysInYear) * value;

export const getDifferenceBetweenMinimumPremiumAndRunningTotal = (
  previousRunningTotal: number,
  minimumPremium: number
) => {
  if (minimumPremium - previousRunningTotal > 0)
    return minimumPremium - previousRunningTotal;
  else return 0;
};

const getHighestManualPremiumExposureByState = (
  states: PolicyStateBlob[],
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date
) => {
  const NO_PREMIUM_REALLY_LOW_VALUE_FOR_ORDERING = -10000000000000;

  const currentState = states.find(
    (state) =>
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
  );

  const exposuresOrderedByPremiumFromHighestToLower =
    currentState?.exposures
      ?.slice()
      ?.sort(
        (a, b) =>
          (b.manualPremiumAtMod ?? NO_PREMIUM_REALLY_LOW_VALUE_FOR_ORDERING) -
          (a.manualPremiumAtMod ?? NO_PREMIUM_REALLY_LOW_VALUE_FOR_ORDERING)
      ) ?? [];

  const exposureWithHighestManualPremium =
    exposuresOrderedByPremiumFromHighestToLower.length > 0
      ? exposuresOrderedByPremiumFromHighestToLower[0]
      : null;

  return exposureWithHighestManualPremium;
};

export const isCurrentStateTheGoverningState = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  states: PolicyStateBlob[]
) => {
  if (states.length > 0) {
    const statesAndHighestManualPremium = states.map((state) => {
      const exposureWithHighestPremium = getHighestManualPremiumExposureByState(
        states,
        state.stateCode ?? "",
        state.effectiveDate ?? new Date(),
        state.expirationDate ?? new Date()
      );
      const classCodeWithSuffix = `${
        exposureWithHighestPremium?.classCode ?? ""
      }${exposureWithHighestPremium?.classSuffix ?? ""}`;
      return {
        stateCode: state.stateCode ?? "",
        effectiveDate: state.effectiveDate ?? new Date(),
        expirationDate: state.expirationDate ?? new Date(),
        highestManualPremium:
          exposureWithHighestPremium?.manualPremiumAtMod ?? 0,
        classCodeWithHighestPremium: classCodeWithSuffix,
      };
    }); //[{stateCode: "MD", effectiveDate: 1/1/1, expirationDate: 1/1/1, highestManualPremium: 2000, classCode: "0010"}, {stateCode: "NV",effectiveDate: 1/1/1, expirationDate: 1/1/1, highestManualPremium: 1000, classCode: "0010"}]

    const statesSortedByHighestManualPremiumDesc =
      statesAndHighestManualPremium.sort((a, b) => {
        if (a.highestManualPremium === b.highestManualPremium) return 0;
        if (a.highestManualPremium < b.highestManualPremium) return 1;
        return -1;
      });

    const stateWithHighestManualPremium =
      statesSortedByHighestManualPremiumDesc[0];

    if (
      stateWithHighestManualPremium.stateCode === stateCode &&
      stateWithHighestManualPremium.effectiveDate === effectiveDate &&
      stateWithHighestManualPremium.expirationDate === expirationDate
    )
      return {
        isGoverningState: true,
        classCodeWithHighestPremium:
          stateWithHighestManualPremium.classCodeWithHighestPremium,
      };
    else
      return {
        isGoverningState: false,
        classCodeWithHighestPremium: "",
      };
  }
  return {
    isGoverningState: false,
    classCodeWithHighestPremium: "",
  };
};

export const isCurrentStateTheStateWithHighestMinimumPremium = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  states: PolicyStateBlob[]
) => {
  if (states.length > 0) {
    const statesAndMinimumPremium = states.map((state) => {
      if (state.ratings !== undefined && state.ratings !== null) {
        const minPremiumValue =
          state.ratings.find(
            (rating) => rating.elementType === QuoteRatingTypeEnum.MIN_PREM
          )?.minimumValue ?? -1000000;

        return {
          stateCode: state.stateCode,
          effectiveDate: state.effectiveDate ?? new Date(),
          expirationDate: state.expirationDate ?? new Date(),
          minimumPremium: minPremiumValue,
        };
      } else return { stateCode: state.stateCode, minimumPremium: -1000000 };
    }); //[{stateCode: "MD", effectiveDate: 1/1/1, expirationDate: 1/1/1, minimumPremium: 2000}, {stateCode: "NV", effectiveDate: 1/1/1, expirationDate: 1/1/1, minimumPremium: 1000}]

    const statesSortedByHighestMinimumPremiumDesc =
      statesAndMinimumPremium.sort((a, b) => {
        if (a.minimumPremium === b.minimumPremium) return 0;
        if ((a.minimumPremium ?? 0) < (b.minimumPremium ?? 0)) return 1;
        return -1;
      });

    const stateWithHighestMinimumPremium =
      statesSortedByHighestMinimumPremiumDesc[0];

    if (
      stateWithHighestMinimumPremium.stateCode === stateCode &&
      stateWithHighestMinimumPremium.effectiveDate === effectiveDate &&
      stateWithHighestMinimumPremium.expirationDate === expirationDate
    )
      return {
        isStateWithHMP: true,
        stateCode: stateWithHighestMinimumPremium.stateCode,
        effectiveDate: stateWithHighestMinimumPremium.effectiveDate,
        expirationDate: stateWithHighestMinimumPremium.expirationDate,
      };
    else
      return {
        isStateWithHMP: false,
        stateCode: stateWithHighestMinimumPremium.stateCode,
        effectiveDate: stateWithHighestMinimumPremium.effectiveDate,
        expirationDate: stateWithHighestMinimumPremium.expirationDate,
      };
  }
  return {
    isStateWithHMP: true,
    stateCode: null,
    effectiveDate: null,
    expirationDate: null,
  };
};

export const getSumOfPremiumBasisForSpecifiedRatingMultiState = (
  states: PolicyStateBlob[],
  specificRating: PolicyRatingBlob
) => {
  const premiumBasisOfAllStates = states.map((state) => {
    if (
      state.ratings !== undefined &&
      state.ratings !== null &&
      state.ratings.length > 0
    ) {
      const premiumBasisAndIndex = state.ratings.map((rating, index) => {
        return rating.elementType === specificRating.elementType
          ? { rating: rating, ratingIndex: index }
          : null;
      });

      const ratingResult = premiumBasisAndIndex.find(
        (object) => object !== null
      );

      if (
        ratingResult?.rating.premiumBasis !== undefined &&
        ratingResult.ratingIndex !== undefined
      ) {
        const runningTotal =
          ratingResult?.rating.premiumBasis === PREMIUM_BASIS_PREVIOUS
            ? getPreviousRunningTotal(
                ratingResult.ratingIndex ?? 0,
                state.ratings
              )
            : state.ratings.find(
                (currentRating) =>
                  currentRating.rateElementID ===
                  ratingResult?.rating.premiumBasis
              )?.runningTotal;

        return runningTotal ?? 0;
      }
      return 0;
    }
    return 0;
  });

  const sumOfAllPremiumBasis = sumAllValuesOfAnArray(premiumBasisOfAllStates);

  return sumOfAllPremiumBasis;
};

export const getNameToExposureTableColumns = (
  props: PremiumTableRowProps,
  columnIndex: 0 | 1 | 2 | 3 | 4
) => {
  const columnNames = [
    "name",
    "rate",
    "calculated-amount",
    "previous-running-total",
    "running-total",
  ];
  return `exposure-table-${props?.nameElement}-${columnNames[columnIndex]}`;
};

export const getValidatedCalculatedAmountByMinMax = (
  calculatedAmount: number,
  rating: PolicyRatingBlob
) => {
  if (
    rating.minimumValue !== undefined &&
    rating.minimumValue !== null &&
    calculatedAmount < rating.minimumValue
  ) {
    return rating.minimumValue;
  }
  if (
    rating.maximumValue !== undefined &&
    rating.maximumValue !== null &&
    calculatedAmount > rating.maximumValue
  ) {
    return rating.maximumValue;
  }

  return calculatedAmount;
};

export const sumRunningTotalBySpecificPremiumBasis = (
  premiumBasis: QuoteRatingTypeEnum,
  states: PolicyStateBlob[]
) => {
  const sumRunningTotal = states.reduce((previousValue, state) => {
    const runningTotal =
      state?.ratings?.find(
        (currentRating) => currentRating.elementType === premiumBasis
      )?.runningTotal ?? 0;
    return previousValue + runningTotal;
  }, 0);
  return sumRunningTotal;
};

export const calculatedResultForDiscountTable = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  standardPremium: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const discountRate = discountRates?.filter(
    (discount) =>
      standardPremium > (discount?.lowThreshold ?? 0) &&
      standardPremium < (discount?.highThreshold ?? 0)
  );
  const discountRateValue = discountRate[0]
    ? discountRate[0].discountRate ?? 0
    : 0;
  const rateToDivide = 100 - discountRateValue;
  const rate = customRound(divideAndRound(rateToDivide, 100).toString(), 3);
  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * rate).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    rate: rate,
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
  };
  return rowResult;
};

export const calculatedResultForAgencyDiscount = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  standardPremium: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  states: PolicyStateBlob[]
) => {
  if ((discountRates[0]?.discountOverride ?? false) === false) {
    const rowResult: PremiumTableRowResultProps =
      calculatedResultForDiscountTable(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        standardPremium,
        rating,
        discountRates,
        states
      );
    return rowResult;
  } else {
    const currentState = getStateByStateCodeAndDatesByStates(
      stateCode,
      effectiveDate,
      expirationDate,
      states
    );
    const discountRateValue = discountRates?.[0].discountRate ?? 0;
    const rateToDivide = 100 - discountRateValue;
    const rate = customRound(divideAndRound(rateToDivide, 100).toString(), 3);

    const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
      rateIndex,
      rating,
      currentState
    );
    const previousRunningTotal = getPreviousRunningTotal(
      rateIndex,
      currentState?.ratings
    );
    const runningTotalCalculated = customRound(
      (runningTotalByPremiumBasis * rate).toString(),
      rating.rounding ?? 0
    );
    const calculatedAmount = customRound(
      (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
      rating.rounding ?? 0
    );
    const newRunningTotal = previousRunningTotal + calculatedAmount;

    const rowResult: PremiumTableRowResultProps = {
      rate: rate,
      runningTotal: newRunningTotal,
      calculatedAmount: calculatedAmount,
    };
    return rowResult;
  }
};

export const calculatedResultForDiscountEditableTable = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  isRateEdited: boolean,
  rateIndex: number,
  standardPremium: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const discountRate = discountRates?.filter(
    (discount) =>
      standardPremium > (discount.lowThreshold ?? 0) &&
      standardPremium < (discount.highThreshold ?? 0)
  );
  const discountRateValue = discountRate[0]
    ? discountRate[0].discountRate ?? 0
    : 0;
  const rateToDivide = 100 - discountRateValue;
  const calculatedRate = isRateEdited
    ? rate
    : customRound(divideAndRound(rateToDivide, 100).toString(), 3);

  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * calculatedRate).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    rate: calculatedRate,
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
  };
  return rowResult;
};

export const calculatedResultForDiscountTier = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  standardPremium: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const discounts: [{ total?: number; discountRate?: number }] = [{}];
  for (let index = 0; index < discountRates.length; index++) {
    const discountAvg = (discountRates[index].discountRate ?? 0) / 100;
    if (standardPremium > (discountRates[index].highThreshold ?? 0)) {
      if (index === 0) {
        discounts.pop();
        discounts.push({
          total: discountRates[index].highThreshold ?? 0,
          discountRate: discountRates[index].discountRate ?? 0,
        });
      } else {
        const total =
          (discountRates[index].highThreshold ?? 0) -
          (discountRates[index - 1].highThreshold ?? 0);
        const discountRate = total * discountAvg;
        discounts.push({ total, discountRate });
      }
    } else {
      if (index === 0) {
        discounts.pop();
      }
      const prev = discounts.reduce(
        (acc, discount) => (discount?.total ?? 0) + acc,
        0
      );
      const total = standardPremium - prev;
      const discountRate = total * discountAvg;
      discounts.push({ total, discountRate });
      break;
    }
  }

  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (
      runningTotalByPremiumBasis -
      discounts.reduce(
        (acc, discount) => (discount?.discountRate ?? 0) + acc,
        0
      )
    ).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    rate: 0,
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
  };
  return rowResult;
};

export const getRunningTotalByTotalExposure = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode ?? "",
    effectiveDate ?? new Date(),
    expirationDate ?? new Date(),
    states
  );
  const runningTotal = sumExposuresAmount(currentState?.exposures ?? []);
  return (runningTotal ?? 0) / 100;
};

export const getDiscountRates = (
  configurations: RateElementsConfigurationDto,
  rating: PolicyRatingBlob
) => {
  if (
    configurations !== undefined &&
    configurations !== null &&
    configurations.agencyDiscountList.length > 0 &&
    rating.elementType === QuoteRatingTypeEnum.AGENCY_DISCOUNT
  ) {
    return configurations.agencyDiscountList;
  }

  if (
    rating.elementType === QuoteRatingTypeEnum.AGENCY_DISCOUNT ||
    rating.elementType === QuoteRatingTypeEnum.DISCOUNT_TABLE ||
    rating.elementType === QuoteRatingTypeEnum.DISCOUNT_TABLE_EDIT ||
    rating.elementType === QuoteRatingTypeEnum.DISCOUNT_TIER
  ) {
    return (
      configurations.discountList?.filter(
        (discount) => discount.discountTableName === rating.sourceName
      ) ?? []
    );
  }
  return [];
};

export const getRatingsUpdatedByRowResult = (
  rateIndex: number,
  rowResult: PremiumTableRowResultProps,
  state?: PolicyStateBlob
) => {
  const newRatings = state?.ratings?.map((rating, index) => {
    if (rateIndex === index) {
      return {
        ...rating,
        rate: rowResult.rate,
        amount: rowResult.calculatedAmount,
        runningTotal: rowResult.runningTotal,
        optionalElement: rowResult.optionalElement,
        policyLimits: rowResult.policyLimits,
        rateOption: rowResult.rateOption,
        policyReference: rowResult.policyReference,
        scheduleRatings: rowResult.scheduleRatings,
        isHidden: rowResult.isHidden,
      };
    } else {
      return { ...rating };
    }
  });

  return newRatings;
};

export const getStatesUpdatedByRowResult = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rowResult: PremiumTableRowResultProps,
  states: PolicyStateBlob[]
) => {
  const newStates = states.map((state) => {
    if (
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
    ) {
      return {
        ...state,
        ratings: getRatingsUpdatedByRowResult(rateIndex ?? 0, rowResult, state),
      };
    } else {
      return { ...state };
    }
  });

  return newStates;
};

export const getDefaultOptionForPolicyLimits = (
  options: PolicyLimitDto[],
  selectedPolicyLimit?: PolicyLimitsOption | null,
  defaultPolicyLimit?: PolicyLimitDto | null
) => {
  const defaultOption = options.find(
    (option) => option.id === selectedPolicyLimit?.policyLimitsID
  );

  if (defaultOption !== undefined) return defaultOption;
  if (options.length > 0) {
    const defaultOptionFromPolicyInformation = options.find(
      (option) =>
        option.eachAccident === defaultPolicyLimit?.eachAccident &&
        option.policyLimit === defaultPolicyLimit.policyLimit &&
        option.eachEmployee === defaultPolicyLimit.eachEmployee
    );

    if (defaultOptionFromPolicyInformation !== undefined)
      return defaultOptionFromPolicyInformation;
    else return options[0];
  }

  return null;
};

export const getDefaultOptionForRateOption = (
  options: RateOptionElementDto[],
  selectedRateOption?: RatingRateOption | null
) => {
  const defaultOption = options.find(
    (option) => option.id === selectedRateOption?.rateOptionID
  );

  if (defaultOption !== undefined) return defaultOption;
  if (options.length > 0) return options[0];

  return null;
};
