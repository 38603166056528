import { PageChangeEvent, Pager } from "@progress/kendo-react-data-tools";
import { FC } from "react";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import Select from "../../Select/Select";
import Font from "../../Typography/Font";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { TableInstanceType2 } from "./TableProperties";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";

const pageSizes: number[] = [5, 10, 25, 50, 100];
const optionsSizes = pageSizes.map((size) => ({
  displayName: size.toString(),
  intValue: size,
}));

const BaseTable2Pagination: FC<any> = (props) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(props.uiid) as RecoilState<TableInstanceType2>
  );

  const skip = conditionHasValue(tableInstance)
    ? tableInstance?.selectedPage * tableInstance?.rowsPerPage
    : 0;
  const filteredData =
    tableInstance?.sortedAndFilteredDataWithOutPaginate?.length ?? 0;

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(props.uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: props.uiid,
        });
      },
    []
  );

  const onChangePage = (event: PageChangeEvent) => {
    const { skip, take } = event;
    const newPage = skip / take;
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      selectedPage: newPage,
    });
  };

  const onChangeRowsPerPage = (value) =>
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      rowsPerPage: value,
      selectedPage: 0,
    });

  const getInitialItem = () => {
    if (tableInstance?.selectedPage === 0) {
      if (filteredData > 0) {
        return 1;
      }
      return 0;
    }
    return skip + 1;
  };

  const getCurrentItems = () =>
    skip + tableInstance?.sortedAndFilteredData?.length;

  return (
    <>
      {tableInstance?.paginate && (
        <div className="true_pagination">
          <Pager
            className="kendo-true-pagination"
            skip={skip}
            take={tableInstance?.rowsPerPage ?? 10}
            total={filteredData}
            buttonCount={6}
            info={false}
            onPageChange={onChangePage}
            previousNext
          ></Pager>
          <Select
            id={`true-select-for-pagination-${props.uiid}`}
            name="select-for-pagination"
            inputWidth={"100px"}
            options={optionsSizes}
            optionsContainerPosition={"top"}
            label={"Items per page"}
            labelPosition={"end"}
            labelFontType={"BODY"}
            value={tableInstance?.rowsPerPage ?? 10}
            onChange={onChangeRowsPerPage}
          ></Select>
          <Font>
            {`Items ${getInitialItem()} to ${getCurrentItems()} of ${filteredData}`}
          </Font>
        </div>
      )}
    </>
  );
};

export default BaseTable2Pagination;
