import { BaseTableDisplayOptions } from "../dtos/base-table-display-options";
import { SelectOptions } from "../dtos/select-options";

export const BaseTableDisplayOptionsToSelectOptions = (
  options: BaseTableDisplayOptions[]
) =>
  options.map((option) => ({
    displayName: option.text,
    stringValue: option.value,
  }));

export const getBaseTableDisplayOptionsBySelectOptions = (
  options: SelectOptions[],
  valueType: "string" | "int" | "decimal" | "boolean" | "date"
): BaseTableDisplayOptions[] =>
  options.map((option) => {
    if (valueType === "string")
      return { text: option.displayName, value: option.stringValue ?? "" };
    if (valueType === "int")
      return {
        text: option.displayName,
        value: option.intValue?.toString() ?? "",
      };
    if (valueType === "decimal")
      return {
        text: option.displayName,
        value: option.decimalValue?.toString() ?? "",
      };
    if (valueType === "boolean")
      return {
        text: option.displayName,
        value: option.booleanValue === true ? "true" : "false",
      };
    if (valueType === "date")
      return {
        text: option.displayName,
        value: option.dateValue?.toString() ?? "",
      };
    return { text: option.displayName, value: option.stringValue ?? "" };
  });

export const getSelectOptionsByFieldName = (
  fieldName: string,
  references: any
) =>
  references
    .filter((reference) => reference?.fieldName === fieldName)
    .map((reference) => ({
      displayName: reference?.value1 ?? "",
      intValue: reference?.valueN ?? 0,
    })) ?? [];

export const getSelectOptionFromClaimReferenceList = (
  fieldName: string,
  references: any
) =>
  references
    .filter((reference) => reference?.fieldName === fieldName)
    .map((reference) => ({
      displayName: reference?.value1 ?? "",
      stringValue: reference?.value1 ?? "",
    })) ?? [];

export const formatNumberFloat = (x: string) => {
  return x.toString().replaceAll(",", "");
};
