import { MutableRefObject, useMemo, useEffect, useState } from "react";

interface Dimensions {
  width: number;
  height: number;
}

const updateDimensionsIfChanged = (
  currentDimensions: Dimensions,
  newDimensions: Dimensions,
  setDimensions: (dimensions: Dimensions) => void
) => {
  if (
    currentDimensions.width !== newDimensions.width ||
    currentDimensions.height !== newDimensions.height
  ) {
    setDimensions(newDimensions);
  }
};

export function useResizeObserver(
  ref: MutableRefObject<HTMLElement | undefined>,
  deps: unknown[]
): Dimensions {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const observer = useMemo(() => {
    return new ResizeObserver((entries) => {
      const newDimensions = {
        width: entries[0].target.scrollWidth,
        height: entries[0].target.scrollHeight,
      };

      updateDimensionsIfChanged(dimensions, newDimensions, setDimensions);
    });
  }, [dimensions]);

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, [deps, observer, ref]);

  return dimensions;
}
