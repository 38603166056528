import { DocSourceEnum } from "../../../../dtos/doc-source-enum";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { ProgramPacketDocumentDto } from "../../../../dtos/program-packet-document-dto";
import { ProgramPacketDto } from "../../../../dtos/program-packet-dto";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { TemplateNameAndSourceDto } from "../../../../dtos/template-name-and-source-dto";
import { removeDuplicateObjectsByPath } from "../../../../utilities/arrayFunctions";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import { getJSONDataWithoutConfigurations } from "../PolicyQuoteForm/PolicyQuoteUtils";

export const ERROR_TEMPLATE_NAME = "_ERROR_TEMPLATE_NO_FOUND_";

export const getFilteredAndSortedPacketDocuments = (
  packetDocuments?: ProgramPacketDocumentDto[],
  selectedPackets?: number[]
) => {
  const filteredAndSortedPacketDocuments =
    packetDocuments
      ?.filter((document) => selectedPackets?.includes(document.packetID))
      .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1)) ?? [];

  const packetDocumentsWithoutDuplicateDocs: ProgramPacketDocumentDto[] =
    removeDuplicateObjectsByPath(
      filteredAndSortedPacketDocuments,
      "programPolicyDocument.programDocId"
    );

  return packetDocumentsWithoutDuplicateDocs;
};

export const getFilteredPolicyDocuments = (
  policyDocuments?: ProgramPolicyDocumentDto[],
  filteredDocuments?: ProgramPacketDocumentDto[]
) =>
  policyDocuments?.filter(
    (document) =>
      !filteredDocuments
        ?.map((doc) => doc.programPolicyDocument.programDocId)
        .includes(document.programDocId)
  ) ?? [];

export const getAllTemplateNamesToPrint = (
  selectedPackets: number[],
  selectedDocuments: number[],
  programDocuments: ProgramPacketDocumentDto[],
  policyDocuments: ProgramPolicyDocumentDto[],
  endorsementForms: ProgramEndorsementBlob[]
) => {
  const templateNamesWithDocSourceFromPacket = programDocuments.map(
    (packetDocument) => {
      const formType =
        packetDocument.programPolicyDocument.docSource ===
        DocSourceEnum.CUSTOM_JSON
          ? FormTypeEnum.CUSTOM_FORM
          : FormTypeEnum.POLICY_FORM;
      if (
        selectedPackets.includes(packetDocument.packetID) &&
        packetDocument.programPolicyDocument.fileId !== null
      )
        return [
          {
            templateName:
              packetDocument.programPolicyDocument.templateName ??
              ERROR_TEMPLATE_NAME,
            mergeFieldSource: formType,
          } as TemplateNameAndSourceDto,
        ];
      else if (
        selectedPackets.includes(packetDocument.packetID) &&
        packetDocument.programPolicyDocument.fileId === null // if it's the packet for the endorsement forms.
      ) {
        return endorsementForms.map((form) => {
          return {
            templateName: form.templateName ?? ERROR_TEMPLATE_NAME,
            mergeFieldSource: formType,
          } as TemplateNameAndSourceDto;
        });
      }
      return [];
    }
  );

  const templateNamesWithDocSourceFromPacketJoined =
    templateNamesWithDocSourceFromPacket.flatMap((x) => x);

  const templateNamesWithDocSourceBySelectedDocument = policyDocuments.map(
    (policyDocument) => {
      const formType =
        policyDocument.docSource === DocSourceEnum.CUSTOM_JSON
          ? FormTypeEnum.CUSTOM_FORM
          : FormTypeEnum.POLICY_FORM;

      if (selectedDocuments.includes(policyDocument.programDocId)) {
        return {
          templateName: policyDocument.templateName ?? ERROR_TEMPLATE_NAME,
          mergeFieldSource: formType,
        } as TemplateNameAndSourceDto;
      }
      return {
        templateName: "",
        mergeFieldSource: formType,
      } as TemplateNameAndSourceDto;
    }
  );

  const allTemplateNamesWithDocSource =
    templateNamesWithDocSourceFromPacketJoined?.concat(
      templateNamesWithDocSourceBySelectedDocument ?? []
    ) ?? [];

  const allTemplateNameWithDocSourceFiltered =
    allTemplateNamesWithDocSource.filter(
      (templateNameAndSource) => templateNameAndSource.templateName !== ""
    );

  const hasInvalidTemplateNames = !allTemplateNameWithDocSourceFiltered.every(
    (templateNameAndDocSource) =>
      templateNameAndDocSource.templateName !== ERROR_TEMPLATE_NAME
  );

  return {
    hasInvalidTemplateNames,
    allTemplateWithDocSourceNames: allTemplateNameWithDocSourceFiltered.filter(
      (x) => x.templateName !== ERROR_TEMPLATE_NAME
    ),
  };
};

export const getDefaultFileName = (
  insuredName: string,
  selectedPackets: number[],
  selectedDocuments: number[],
  programPackets: ProgramPacketDto[],
  policyDocuments: ProgramPolicyDocumentDto[]
) => {
  if (selectedPackets.length === 1 && selectedDocuments.length === 0) {
    const packetName =
      programPackets.find(
        (programPacket) => programPacket.packetId === selectedPackets[0]
      )?.packetName ?? "";

    return `${insuredName} ${packetName}`;
  } else if (selectedPackets.length === 0 && selectedDocuments.length === 1) {
    const documentName =
      policyDocuments.find(
        (policyDocument) => policyDocument.programDocId === selectedDocuments[0]
      )?.docName ?? "";

    return `${insuredName} ${documentName}`;
  }
  return `${insuredName} Policy Documents`;
};

export const getSourceJSONFromDocSourceForIndividualPrinting = (
  document: ProgramPolicyDocumentDto,
  customJSONToPrint: string,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const formType =
    document.docSource === DocSourceEnum.CUSTOM_JSON
      ? FormTypeEnum.CUSTOM_FORM
      : FormTypeEnum.POLICY_FORM;
  const mergeFieldPropName =
    document.docSource === DocSourceEnum.CUSTOM_JSON
      ? "customMergeFields"
      : "policyMergeFields";
  const mergeFieldsToUse =
    document.docSource === DocSourceEnum.CUSTOM_JSON
      ? JSON.parse(customJSONToPrint)
      : getJSONDataWithoutConfigurations(atomValue?.policyQuoteInformation);

  return {
    formType,
    [mergeFieldPropName]: mergeFieldsToUse,
  };
};
