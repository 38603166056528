import { Button, Col, Loading, Row } from "../TrueUI";
import { useState } from "react";
import InputMemo from "../TrueUI/Inputs/InputMemo";
import { useFormRequest } from "../../hooks/useFileStorage";
import { FormTypeEnum } from "../../dtos/form-type-enum";
import { isJsonString } from "../../utilities/stringFunctions";
import { rowWithNoMarginNorGutter } from "../TrueUI/Grids/Row";
import Upload from "../TrueUI/Upload/MultiUpload";

const MergeSandbox = () => {
  const [files, setFiles] = useState<{ [key: string]: number }>();
  const [clear, setClear] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [jsonString, setJsonString] = useState<string>("");
  const [templateNames, setTemplateNames] = useState<
    { storageFileName: string; fileArchiveId: number }[]
  >([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const {
    sendMergeFormRequest,
    PdfToDocxAsImagePages,
    isMergeRequestSuccessful,
  } = useFormRequest();

  const getJsonObjectFromString = () => JSON.parse(jsonString);

  async function onMergeClick(): Promise<boolean> {
    if (!templateNames) {
      setErrorMessages(["Please select all template files (docx) first."]);
      return false;
    }

    if (!isJsonString(jsonString)) {
      setErrorMessages(["Json format is not valid."]);
      return false;
    }

    await sendMergeFormRequest(
      {
        customMergeFields: getJsonObjectFromString(),
        formType: FormTypeEnum.CUSTOM_FORM,
        templateNameWithExtensionList: templateNames.map(
          (t) => t.storageFileName
        ),
        customFileName: `combined-document`,
      },
      "download"
    );

    if (!isMergeRequestSuccessful) {
      setErrorMessages([
        "Merging failed. Please try again and avoid clicking Merge until templates uploading is completed.",
      ]);
      return false;
    }

    setErrorMessages([""]);
    return true;
  }

  const onCovertPdfToTemplateClick = () => {
    templateNames.forEach(async (pdfFile) => {
      await PdfToDocxAsImagePages(pdfFile.storageFileName);
    });
  };

  const processAfterUploadingFile = (uploadedFiles, fileInfo) => {
    if (fileInfo && fileInfo.length === 1) {
      const newFile = fileInfo[0];
      setFiles(uploadedFiles);
      setTemplateNames((currentlySelectedTemplates) => [
        ...currentlySelectedTemplates,
        {
          storageFileName: newFile.fileName,
          fileArchiveId: newFile.fileArchiveId,
        },
      ]);
      setIsUploading(false);
    }
  };

  const processAfterRemovingFile = (updatedFiles) => {
    if (isUploading === false) {
      setFiles(updatedFiles);

      const fileIds = Object.keys(updatedFiles).map((key) => updatedFiles[key]);

      const updatedTemplateNames = templateNames.filter((template) =>
        fileIds.includes(template.fileArchiveId)
      );

      setTemplateNames(updatedTemplateNames);
    }
  };

  return (
    <Row rowDirection="column">
      <Col>
        <Row rowDirection="column" {...rowWithNoMarginNorGutter}>
          {isUploading && <Loading isLoading={isUploading} />}
          <Upload
            name="upload-template-file"
            files={files}
            onRemovedFile={(updatedFiles) =>
              processAfterRemovingFile(updatedFiles)
            }
            clearFiles={clear}
            resetClearFiles={setClear}
            allowedExtensions={["docx", "pdf"]}
            label="Label"
            onUploadFileList={(uploadedFiles, fileInfo) =>
              processAfterUploadingFile(uploadedFiles, fileInfo)
            }
            autoUpload
          />
        </Row>
      </Col>
      <Col>
        <InputMemo
          id={"id-json-content"}
          name={"name-json-content"}
          variant="filled"
          resize
          rows={25}
          label="Paste json content for merge fields here:"
          value={jsonString}
          onChangeRawValue={(value) => {
            setJsonString(value);
          }}
          errorMessage={errorMessages}
        />
      </Col>
      <Col>
        <div style={{ marginRight: "10px" }}>
          <Button onClick={onMergeClick}>Merge</Button>
        </div>
        <Button onClick={onCovertPdfToTemplateClick}>
          Convert PDF to Template
        </Button>
      </Col>
    </Row>
  );
};

export default MergeSandbox;
