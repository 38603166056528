import { FC, useEffect, useState } from "react";
import {
  Col,
  Input,
  InputDate,
  Row,
  Select,
} from "../../../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../../../TrueUI/Grids/Row";
import { IconButton } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../../../utilities/inputPropsConfigurationFunctions";
import { useApiGet } from "../../../../../../../../hooks";
import { PaymentEOBDetailDto } from "../../../../../../../../dtos/payment-eob-detail-dto";
import { isAPITotallyComplete } from "../../../../../../../../utilities/apiFunctions";
import { ServiceFeeScheduleDto } from "../../../../../../../../dtos/service-fee-schedule-dto";
import DialogConfirmation from "../../../../../../../TrueUI/Dialogs/DialogConfirmation";
import { FormBillIndividualDetailsProps } from "../TypesPaymentBillDetails";
import { useRecoilState } from "recoil";
import { ClaimFinancialPaymentBillDetailsAtom } from "../../ClaimFinancialAtoms";

const FormBillIndividualDetails: FC<FormBillIndividualDetailsProps> = ({
  claimId,
  payeeId,
  paymentId,
  payToClaimant,
}) => {
  const billIndividualDetailsDefaultValues = {
    quantity: 1,
    serviceFromDate: new Date(),
    serviceToDate: new Date(),
  };
  const [paymentBillDetailsAtom, setPaymentBillDetailsAtom] = useRecoilState(
    ClaimFinancialPaymentBillDetailsAtom
  );

  const [showAlert, setShowAlert] = useState(false);
  const [billIndividualDetails, setBillIndividualDetails] =
    useState<Partial<PaymentEOBDetailDto> | null>(
      billIndividualDetailsDefaultValues
    );
  const [serviceFeeSchedule, setServiceFeeSchedule] =
    useState<Partial<ServiceFeeScheduleDto> | null>(null);

  const { responseGet, dispatchGet } = useApiGet<ServiceFeeScheduleDto>(
    `api/ServiceFeeSchedule/GetServiceFeeScheduleByServiceCode?serviceCode=${billIndividualDetails?.serviceCode}&claimId=${claimId}&payeeId=${payeeId}&paymentId=${paymentId}&payToClaimant=${payToClaimant}`
  );

  const getAllowedAmount = (
    serviceFeeSchedule: ServiceFeeScheduleDto | null
  ) => {
    const amountAllowed: { value: number } = {
      value:
        serviceFeeSchedule?.feeAmount ??
        billIndividualDetails?.amountBilled ??
        0,
    };
    if (
      (serviceFeeSchedule?.feePct ?? 0) > 0 &&
      (serviceFeeSchedule?.feeAmount ?? 0) === 0
    ) {
      const feePctPercentage = (serviceFeeSchedule?.feePct ?? 0) / 100;
      const charges =
        feePctPercentage * (billIndividualDetails?.amountBilled ?? 0);
      amountAllowed.value = charges;
    }
    setBillIndividualDetails({
      ...billIndividualDetails,
      amountAllowed: amountAllowed.value,
    });
  };

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setServiceFeeSchedule(responseGet?.responseData ?? null);
      if (
        (responseGet?.responseData?.serviceFeeScheduleID ?? 0) === 0 &&
        (billIndividualDetails?.serviceCode?.length ?? 0) > 0
      ) {
        setShowAlert(true);
        setBillIndividualDetails({
          ...billIndividualDetails,
          amountAllowed: billIndividualDetails?.amountBilled ?? 0,
        });
      } else {
        getAllowedAmount(responseGet?.responseData ?? null);
      }
    }
  }, [responseGet]);

  useEffect(() => {
    setBillIndividualDetails({
      ...billIndividualDetails,
      eOBCode: serviceFeeSchedule?.eobCode ?? "00",
    });
  }, [serviceFeeSchedule?.eobCode]);

  const [payAmount, setPayAmount] = useState(
    (billIndividualDetails?.amountAllowed ?? 0) *
      (billIndividualDetails?.quantity ?? 0)
  );

  useEffect(() => {
    const amountAllowed =
      billIndividualDetails?.amountAllowed ??
      billIndividualDetails?.amountBilled ??
      0;
    setPayAmount(amountAllowed * (billIndividualDetails?.quantity ?? 0));
  }, [billIndividualDetails?.amountAllowed, billIndividualDetails?.quantity]);

  useEffect(() => {
    if ((serviceFeeSchedule?.serviceFeeScheduleID ?? 0) !== 0) {
      getAllowedAmount(serviceFeeSchedule);
    } else {
      setBillIndividualDetails({
        ...billIndividualDetails,
        amountAllowed: billIndividualDetails?.amountBilled ?? 0,
      });
    }
  }, [billIndividualDetails?.amountBilled]);

  return (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        numberOfColumns={24}
        verticalAlign={"flex-end"}
      >
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
          <InputDate
            tabIndex={1}
            id="id-service-from-date"
            name={"bill-individual-detail-service-from-date"}
            label="Service From"
            value={billIndividualDetails?.serviceFromDate ?? new Date()}
            onChangeRawValue={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                serviceFromDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
          <InputDate
            tabIndex={2}
            id="id-service-to-date"
            name={"bill-individual-detail-service-to-date"}
            label="Service To"
            value={
              billIndividualDetails?.serviceToDate ??
              billIndividualDetails?.serviceFromDate ??
              new Date()
            }
            onChangeRawValue={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                serviceToDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
          <Input
            tabIndex={3}
            id="bill-individual-detail-cpt-hcpcs"
            name="bill-individual-detail-cpt-hcpcs"
            maxLength={10}
            label="CPT/HCPCS"
            value={billIndividualDetails?.serviceCode ?? ""}
            onChangeRawValue={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                serviceCode: value,
              })
            }
            onBlur={() => dispatchGet()}
            helperText={serviceFeeSchedule?.longDescription ?? ""}
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
          <Select
            tabIndex={4}
            {...selectConfiguration(
              "bill-individual-detail-diagnosis-code",
              "bankAccountId",
              "Diag"
            )}
            options={[
              { displayName: "A", intValue: 1 },
              { displayName: "B", intValue: 2 },
              { displayName: "C", intValue: 3 },
              { displayName: "D", intValue: 4 },
            ]}
            value={billIndividualDetails?.diagnosisCodeId}
            onChange={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                diagnosisCodeId: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
          <Input
            tabIndex={5}
            {...inputConfiguration(
              "bill-individual-detail-charges",
              "name-charges",
              "Charges"
            )}
            maxNumericValue={999999999}
            type={"fixedCurrency"}
            prefix={""}
            align={"right"}
            value={billIndividualDetails?.amountBilled}
            labelFontType={"BOLD_CAPTION"}
            onChangeRawValue={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                amountBilled: value,
              })
            }
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
          <Input
            tabIndex={6}
            {...inputConfiguration(
              "bill-individual-detail-quantity",
              "name-quantity",
              "Qty"
            )}
            minNumericValue={0.5}
            maxNumericValue={1000}
            type={"number"}
            prefix={""}
            align={"right"}
            value={billIndividualDetails?.quantity ?? 1}
            labelFontType={"BOLD_CAPTION"}
            displayNumericArrows={true}
            onChangeRawValue={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                quantity: value,
              })
            }
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
          <Input
            tabIndex={7}
            {...inputConfiguration(
              "bill-individual-detail-allowed-amount",
              "name-allowed-amount",
              "Allowed"
            )}
            maxNumericValue={999999999}
            type={"fixedCurrency"}
            prefix={""}
            align={"right"}
            value={
              billIndividualDetails?.amountAllowed ??
              billIndividualDetails?.amountBilled
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
          <Input
            tabIndex={8}
            {...inputConfiguration(
              "bill-individual-detail-pay-amount",
              "name-pay-amount",
              "Pay Amount"
            )}
            maxNumericValue={999999999}
            type={"fixedCurrency"}
            prefix={""}
            align={"right"}
            value={payAmount}
            labelFontType={"BOLD_CAPTION"}
            readOnly
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
          <Input
            tabIndex={9}
            id="bill-individual-detail-eob"
            name="bill-individual-detail-eob"
            label="EOB"
            readOnly
            value={billIndividualDetails?.eOBCode ?? "00"}
          />
        </Col>
        <Col
          breakpoints={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}
          verticalAlignSelf={"center"}
        >
          <IconButton
            id={`plus-bill-individual-detail-action`}
            className="plus-minus-icon-container"
            sx={{ padding: "5px" }}
            disabled={false}
            onClick={() => {
              setPaymentBillDetailsAtom({
                ...paymentBillDetailsAtom,
                paymentBillDetails: [
                  ...(paymentBillDetailsAtom?.paymentBillDetails ?? []),
                  {
                    individualBillDetails: {
                      ...billIndividualDetails,
                      amountPaid: payAmount,
                    },

                    paymentServiceFeeSchedule: serviceFeeSchedule,
                  },
                ],
              });
              setBillIndividualDetails(billIndividualDetailsDefaultValues);
              setServiceFeeSchedule(null);
            }}
            true-element={"option-plus-icon"}
            color={"default"}
          >
            <AddOutlined className="plus-action-button" fontSize="small" />
          </IconButton>
        </Col>
      </Row>
      <DialogConfirmation
        id="no-service-code-found"
        open={showAlert}
        dialogDescriptionText={
          "The procedure code you entered could not be found.  You can continue to enter a manual payment or retry the code."
        }
        optionYesOverrideLabel={"OK"}
        onOptionYesEvent={(close) => {
          setShowAlert(close);
        }}
      />
    </>
  );
};

export default FormBillIndividualDetails;
