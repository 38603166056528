import { FC, useEffect, useState } from "react";
import { BaseTable2Properties } from "../../../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../../../../../TrueUI";
import { TableWrapperProceduresServicesSuppliesProps } from "../TypesPaymentBillDetails";
import { IconButton } from "@mui/material";
import { Remove } from "@mui/icons-material";
import { toUpperCaseKeys } from "../../../../../../../../utilities/objectFunctions";
import { useRecoilState } from "recoil";
import { ClaimFinancialPaymentBillDetailsAtom } from "../../ClaimFinancialAtoms";
import { FormattingDate } from "../../../../../../../../utilities/dateFunctions";
import { currencyFormat } from "../../../../../../../../utilities/currencyFunctions";
import { getColumnIndexesByColumnNames } from "../../../../../../../BaseGrid/SupportFunctions/baseGridFunctions";

const TableWrapperProceduresServicesSupplies: FC<
  TableWrapperProceduresServicesSuppliesProps
> = ({ paymentId }) => {
  const [paymentBillDetailsAtom, setPaymentBillDetailsAtom] = useRecoilState(
    ClaimFinancialPaymentBillDetailsAtom
  );
  const [totals, setTotals] = useState<{
    totalCharge: number | null;
    totalPaid: number | null;
  }>({
    totalCharge: null,
    totalPaid: null,
  });

  const [paymentEobDetailIdToDelete, setPaymentEobDetailIdToDelete] = useState<
    number | null
  >(null);
  const getUrl = `api/PaymentEOB/GetPaymentEOBDetailsForPaymentBillDetailsModalTable?paymentId=${paymentId}`;
  const tableName = "table-procedures-services-supplies";

  const addRowToTable = () => {
    const atomRowData = paymentBillDetailsAtom?.paymentBillDetails?.[0];
    const diagnosisCodes = { A: 1, B: 2, C: 3, D: 4 };
    const diagnosisCodeCharacter = Object.keys(diagnosisCodes).find(
      (diagnosisCodeKey) =>
        diagnosisCodes[diagnosisCodeKey] ===
        atomRowData?.individualBillDetails?.diagnosisCodeId
    );
    const newRowData = {
      AmountAllowed: currencyFormat(
        atomRowData?.individualBillDetails?.amountAllowed
      ),
      AmountBilled: currencyFormat(
        atomRowData?.individualBillDetails?.amountBilled
      ),
      AmountPaid: currencyFormat(
        atomRowData?.individualBillDetails?.amountPaid
      ),
      DiagnosisCode: diagnosisCodeCharacter,
      EOBCode: atomRowData?.paymentServiceFeeSchedule?.eobCode,
      PaymentId: 0,
      Quantity: atomRowData?.individualBillDetails?.quantity,
      ServiceCode: atomRowData?.paymentServiceFeeSchedule?.serviceCode,
      ServiceFromDate: FormattingDate(
        atomRowData?.individualBillDetails?.serviceFromDate
      ),
      ServiceToDate: FormattingDate(
        atomRowData?.individualBillDetails?.serviceToDate
      ),
      ShortDescription:
        atomRowData?.paymentServiceFeeSchedule?.shortDescription,
    };
    const newRow = (rowPattern: any) => {
      return { ...rowPattern, ...toUpperCaseKeys(newRowData) };
    };
    tableInstanceMethods?.methods?.addRow(newRow);
  };

  useEffect(() => {
    if ((paymentBillDetailsAtom?.paymentBillDetails?.length ?? 0) > 0) {
      addRowToTable();
    }
  }, [paymentBillDetailsAtom?.paymentBillDetails]);

  useEffect(() => {
    if (
      (paymentBillDetailsAtom?.paymentBillDetails?.length ?? 0) > 0 &&
      totals?.totalCharge !== null &&
      totals?.totalCharge !== null
    ) {
      console.log({ paymentBillDetailsAtom });
      setPaymentBillDetailsAtom({
        ...paymentBillDetailsAtom,
        ...totals,
      });
      setTotals({
        totalCharge: null,
        totalPaid: null,
      });
    }
  }, [totals]);

  useEffect(() => {
    if (paymentEobDetailIdToDelete !== null) {
      setPaymentBillDetailsAtom({
        ...paymentBillDetailsAtom,
        paymentBillDetailsToDelete: [
          ...(paymentBillDetailsAtom?.paymentBillDetailsToDelete ?? []),
          paymentEobDetailIdToDelete,
        ],
      });
      setPaymentEobDetailIdToDelete(null);
    }
  }, [paymentEobDetailIdToDelete]);

  const RemoveItemLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          tableInstanceMethods?.methods?.deleteRow({
            rowKey: rowData?._row_key ?? "",
            hydratedData: {
              ...rowData,
              ...toUpperCaseKeys(rowData),
            },
            deleteType: "hidden",
          });
          setPaymentEobDetailIdToDelete(parseInt(rowData?.PaymentId ?? "0"));
        }}
      >
        <Remove fontSize={"medium"} />
      </IconButton>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: getUrl,
    columnOptions: [
      { fieldName: "ServiceFromDate", width: 8 },
      { fieldName: "ServiceToDate", width: 8 },
      { fieldName: "DiagnosisCode", width: 8 },
      { fieldName: "ServiceCode", width: 23 },
      { fieldName: "AmountBilled", width: 11, align: "right" },
      { fieldName: "AmountAllowed", width: 11, align: "right" },
      { fieldName: "Quantity", width: 7 },
      { fieldName: "AmountPaid", width: 14 },
      { fieldName: "EOBCode", width: 7 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    toolbarOptions: {
      hideToolbar: true,
    },
    advancedOptions: {
      disableOrderBy: true,
      paginate: false,
      tableStyle: {
        height: "100%",
      },
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          RemoveItemLink?.(actionOptions.row) ?? null,
      },
    },
    events: {
      onDataChange: (data, columns) => {
        getTotals(data, columns);
      },
    },
  };

  const getTotals = (data: any, columns: any) => {
    const paidIndex = getColumnIndexesByColumnNames(
      ["AmountPaid"],
      columns
    )?.[0];
    const billedIndex = getColumnIndexesByColumnNames(
      ["AmountBilled"],
      columns
    )?.[0];
    const quantityIndex = getColumnIndexesByColumnNames(
      ["Quantity"],
      columns
    )?.[0];

    const getTotalCharge = (amountBilled: string, quantity: string) => {
      return (
        parseFloat(amountBilled?.replaceAll(",", "") ?? "0") *
        parseFloat(quantity?.replaceAll(",", "") ?? "0")
      );
    };

    const totalCharge = data.reduce(
      (accumulator, row) =>
        accumulator + getTotalCharge(row[billedIndex], row[quantityIndex]),
      0
    );

    const totalPaid = data.reduce(
      (accumulator, row) =>
        accumulator + parseFloat(row?.[paidIndex]?.replaceAll(",", "") ?? "0"),
      0
    );

    setTotals({
      totalCharge,
      totalPaid,
    });
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const tableInstanceMethods = tableMethods[tableName];

  return (
    <>
      <BaseTable2 name={tableName} />
    </>
  );
};

export default TableWrapperProceduresServicesSupplies;
