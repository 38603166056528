import { RecoilValue, useRecoilCallback } from "recoil";
import { RowKey } from "../BaseGridProperties";
import {
  GridCellManagerInstanceAtomFamily,
  BaseGridCellInstanceProperties,
  scopedBaseGridCellManager,
  defaultGridCellManagerInstanceAtomState,
} from "../BaseGridAtoms";
import { useEffect } from "react";

export function useCellManagerInstance(
  _uiid: string,
  initialRowKey?: string
): {
  createCellManagerAtomInstance: (rowKey: string) => void;
  instanceCellManager: {
    (rowKey: string): RecoilValue<BaseGridCellInstanceProperties>;
    <T extends keyof BaseGridCellInstanceProperties>(
      rowKey: string,
      param: T
    ): RecoilValue<BaseGridCellInstanceProperties[T] | null>;
  };
  getCellManagerInstance: (rowkey: RowKey) => BaseGridCellInstanceProperties;
  getCellManagerInstances: (
    rowkeys: RowKey[]
  ) => BaseGridCellInstanceProperties[];
  updateCellManagerInstance: {
    (rowkey: RowKey, updatedInstance: any): void;
    (rowkeys: RowKey[], updatedInstance: any): void;
  };
  updateCellManagerInstanceUniquely: {
    (unqiueInstances: {
      rowKey: RowKey;
      instance: BaseGridCellInstanceProperties;
    }): void;
    (
      unqiueInstances: {
        rowKey: RowKey;
        instance: BaseGridCellInstanceProperties;
      }[]
    ): void;
  };
} {
  const createCellManagerAtomInstance = useRecoilCallback(
    ({ snapshot, set }) =>
      (initialRowKey: string) => {
        const rowKey = initialRowKey ?? "NO_ROWKEY_FOUND";

        const existingInstance = snapshot.getLoadable(
          GridCellManagerInstanceAtomFamily(rowKey)
        ).contents as BaseGridCellInstanceProperties;

        if (!existingInstance || existingInstance.rowKey === null) {
          const newInstance = {
            ...defaultGridCellManagerInstanceAtomState,
            rowKey: rowKey,
          };

          set(GridCellManagerInstanceAtomFamily(rowKey), newInstance);
        }
      },
    []
  );

  const getInstances = useRecoilCallback(
    ({ snapshot }) =>
      (rowkeys: RowKey | RowKey[]) => {
        const keys = Array.isArray(rowkeys) ? rowkeys : [rowkeys];

        const instances = keys.map((key) => {
          return snapshot.getLoadable(GridCellManagerInstanceAtomFamily(key))
            .contents as BaseGridCellInstanceProperties;
        });

        return instances;
      },
    []
  );

  const updateInstances = useRecoilCallback(
    ({ set }) =>
      (
        instances: BaseGridCellInstanceProperties[],
        updatedInstance: BaseGridCellInstanceProperties
      ) => {
        instances.forEach((instance) => {
          const mergedInstance = {
            ...instance,
            ...updatedInstance,
            rowKey: instance.rowKey,
          };

          set(
            GridCellManagerInstanceAtomFamily(
              instance?.rowKey ?? "NO_ROWKEY_FOUND"
            ),
            mergedInstance
          );
        });
      },
    []
  );

  const updateInstancesUniquely = useRecoilCallback(
    ({ set }) =>
      (
        uniqueInstances: {
          rowKey: RowKey;
          instance: BaseGridCellInstanceProperties;
        }[]
      ) => {
        uniqueInstances.forEach((uniqueInstance) => {
          const rowKey = uniqueInstance.rowKey ?? "NO_ROWKEY_FOUND";

          set(GridCellManagerInstanceAtomFamily(rowKey), {
            ...defaultGridCellManagerInstanceAtomState,
            ...uniqueInstance.instance,
            rowKey: rowKey,
          });
        });
      },
    []
  );

  useEffect(() => {
    if (initialRowKey) {
      createCellManagerAtomInstance(initialRowKey);
    }
  }, [initialRowKey]);

  function instanceCellManager(
    rowKey: string
  ): RecoilValue<BaseGridCellInstanceProperties>;
  function instanceCellManager<T extends keyof BaseGridCellInstanceProperties>(
    rowKey: string,
    param: T
  ): RecoilValue<BaseGridCellInstanceProperties[T]>;
  function instanceCellManager(
    rowKey: string,
    propertyName?: keyof BaseGridCellInstanceProperties
  ) {
    return scopedBaseGridCellManager({
      rowKey: rowKey,
      propertyName: propertyName,
    }) as any;
  }

  function getCellManagerInstance(
    rowkey: RowKey
  ): BaseGridCellInstanceProperties {
    const [foundInstance] = getInstances(rowkey) ?? null;
    return foundInstance;
  }

  function getCellManagerInstances(
    rowkeys: RowKey[]
  ): BaseGridCellInstanceProperties[] {
    return getInstances(rowkeys);
  }

  function updateCellManagerInstance(
    rowkey: RowKey,
    updatedInstance: BaseGridCellInstanceProperties
  );
  function updateCellManagerInstance(
    rowkeys: RowKey[],
    updatedInstance: BaseGridCellInstanceProperties
  );
  function updateCellManagerInstance(
    rowkeys: RowKey | RowKey[],
    updatedInstance: BaseGridCellInstanceProperties
  ) {
    const instances = getInstances(rowkeys);
    updateInstances(instances, updatedInstance);
  }

  function updateCellManagerInstanceUniquely(uniqueInstances: {
    rowKey: RowKey;
    instance: BaseGridCellInstanceProperties;
  }): void;
  function updateCellManagerInstanceUniquely(
    uniqueInstances: {
      rowKey: RowKey;
      instance: BaseGridCellInstanceProperties;
    }[]
  ): void;
  function updateCellManagerInstanceUniquely(
    uniqueInstances:
      | { rowKey: RowKey; instance: BaseGridCellInstanceProperties }
      | { rowKey: RowKey; instance: BaseGridCellInstanceProperties }[]
  ) {
    const instances = Array.isArray(uniqueInstances)
      ? uniqueInstances
      : [uniqueInstances];

    updateInstancesUniquely(instances);
  }

  return {
    createCellManagerAtomInstance,
    instanceCellManager,
    getCellManagerInstance,
    getCellManagerInstances,
    updateCellManagerInstance,
    updateCellManagerInstanceUniquely,
  };
}
