import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../../../dtos/select-options";
import {
  Col,
  InputMemo,
  Modal,
  Row,
  Select,
  SelectWithIcon,
} from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { selectConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import PolicyStatusHistoryTable from "./PolicyStatusHistoryTable";
import { useApiGet, useApiPost } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import {
  defaultFormData,
  getClearAssignmentOptionIfNeeded,
  getCurrentAssigneeId,
  getTeamForPolicy,
  PolicyChangeStatusModalProps,
  PolicyStatusFormData,
} from "./PolicyStatusUtils";
import { PolicyStatusConfigurationDto } from "../../../../dtos/policy-status-configuration-dto";
import { usePolicyQuoteTriggerComponent } from "../hooks/usePolicyQuoteTriggerComponent";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { updateAuditStatusValueInPolicyQuote } from "../updatesPolicyQuoteFunctions";
import { AssigneeTypeEnum } from "../../../../dtos/assignee-type-enum";
import { TEAM_ICON_NAME } from "../../../../utilities/staticDataFunctions";
import { PolicyUpdateStatusDto } from "../../../../dtos/policy-update-status-dto";

const PolicyChangeStatusModal: FC<PolicyChangeStatusModalProps> = ({
  tabKey,
  modalConfiguration,
  closeModal,
  onSaveSuccessful,
  auditStatusOptions = [],
  assignmentsGroups = [],
}) => {
  const assigneeId = getCurrentAssigneeId(
    assignmentsGroups,
    modalConfiguration?.policyId
  );

  const [assigneeOptions, setAssigneeOptions] = useState<{
    users: Partial<SelectOptions>[] | null;
    teams: Partial<SelectOptions>[] | null;
  }>({ users: null, teams: null });

  const [formData, setFormData] = useState<PolicyStatusFormData>({
    ...defaultFormData,
    insuredId: modalConfiguration?.insuredId,
  });
  const [statusOptions, setStatusOptions] =
    useState<Partial<SelectOptions>[]>(auditStatusOptions);
  const [errorDetails, setErrorDetails] = useState<any>();

  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(atomKey)
  );
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  const { responseGet, dispatchGet } = useApiGet<PolicyStatusConfigurationDto>(
    "api/Policy/GetPolicyStatusConfiguration"
  );

  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<PolicyUpdateStatusDto | null>(
      modalConfiguration.isAudit
        ? "api/Audit/UpdateAuditStatus"
        : "api/Policy/UpdatePolicyStatus",
      { ...formData }
    );

  const onCancelEvent = () => {
    setFormData(defaultFormData);
    closeModal();
  };

  useEffect(() => {
    if (!modalConfiguration.isAudit) dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      const policyUpdated = modalConfiguration.isAudit
        ? null
        : responsePost.axiosResponse?.data;
      onSaveSuccessful?.(policyUpdated);
      setFormData(defaultFormData);
      closeModal();
      setComponentTriggers(["assignmentComponent"]);
      if (modalConfiguration.isAudit) {
        const atomValue = getAtom();
        const newAtomValue = updateAuditStatusValueInPolicyQuote(atomValue, {
          intValue: formData.status,
          displayName: formData.description ?? "",
        } as SelectOptions);
        setAtom(newAtomValue);
        setPolicyQuoteTriggers(["endorsementHeaderComponent"]);
      }
    } else {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setStatusOptions(responseGet.responseData?.statusOptions ?? []);

      setAssigneeOptions({
        users:
          responseGet.responseData?.addAssignmentSelectOptions?.users ?? [],
        teams: getTeamForPolicy(
          responseGet.responseData?.addAssignmentSelectOptions?.teams ?? []
        ),
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (assigneeOptions?.users !== null && assigneeOptions?.teams !== null) {
      const selectedAssignee = [
        ...(assigneeOptions?.users ?? []),
        ...(assigneeOptions?.teams ?? []),
      ]?.find((option) => option?.intValue === assigneeId);
      setFormData({
        ...formData,
        assigneeId: assigneeId,
        assigneeType:
          selectedAssignee?.iconName === TEAM_ICON_NAME
            ? AssigneeTypeEnum.TEAM
            : AssigneeTypeEnum.USER,
      });
    }
  }, [assigneeOptions]);

  useEffect(() => {
    setFormData({
      ...formData,
      policyId: modalConfiguration.policyId ?? formData.policyId,
      quoteId: modalConfiguration.quoteId ?? formData.quoteId,
      status: modalConfiguration.currentStatus ?? formData.status,
      description:
        statusOptions.find(
          (s) =>
            s.intValue === (modalConfiguration.currentStatus ?? formData.status)
        )?.displayName ?? "",
    });
  }, [modalConfiguration]);

  const assigneeSelected = (assigneeId) => {
    const selectedAssignee = [
      ...(assigneeOptions?.users ?? []),
      ...(assigneeOptions?.teams ?? []),
    ]?.find((option) => option?.intValue === assigneeId);
    setFormData({
      ...formData,
      newAssigneeId: assigneeId,
      assigneeType:
        selectedAssignee?.iconName === TEAM_ICON_NAME
          ? AssigneeTypeEnum.TEAM
          : AssigneeTypeEnum.USER,
    });
  };
  return (
    <Modal
      id={"policy_change_status"}
      title={"Change Status"}
      size={"md"}
      open={modalConfiguration.isOpen}
      cancelButtonWithConfirmation
      cancelEvent={onCancelEvent}
      showCancelTextButton
      showCloseButton
      cancelButtonConfirmationText={
        "Are you sure you want to discard the changes?"
      }
      saveEvent={dispatchPost}
    >
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          <Select
            {...selectConfiguration(
              "id-policy-status",
              "policy-status",
              "New Status"
            )}
            options={statusOptions}
            labelFontType="BOLD_CAPTION"
            value={formData.status}
            errorMessage={errorDetails?.status}
            onChange={(value) =>
              setFormData({
                ...formData,
                status: value,
                description:
                  statusOptions.find((s) => s.intValue === value)
                    ?.displayName ?? "",
              })
            }
          />
        </Col>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          <SelectWithIcon
            id="policy-assigned-user-or-team"
            label="New Assignee"
            name="insuredAssignedUserOrTeam"
            options={[
              ...(assigneeOptions?.users ?? []),
              ...(assigneeOptions?.teams ?? []),
              ...getClearAssignmentOptionIfNeeded(assigneeId),
            ]}
            value={formData?.assigneeId}
            onChange={(e) => assigneeSelected(e)}
            firstOptionAsDefault={false}
            optionsContainerPosition={"bottom"}
            labelFontType={"BOLD_CAPTION"}
            optionsMaxHeight="380px"
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 12 }}>
          <InputMemo
            id="id-policy-status-comments"
            name="policy-status-comments"
            label="Comments"
            value={formData?.comments}
            onChangeRawValue={(value) =>
              setFormData({ ...formData, comments: value })
            }
            errorMessage={errorDetails?.comments}
            rows={4}
            resize={true}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 12 }}>
          <PolicyStatusHistoryTable
            policyId={modalConfiguration.policyId ?? 0}
            isAudit={modalConfiguration.isAudit}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default PolicyChangeStatusModal;
