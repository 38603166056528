import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import {
  formatNegativeNumbers,
  unFormatNegativeLocalString,
} from "../../../../utilities/stringFunctions";
import {
  ComputeForCellParameters,
  ConditionForCellResponse,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";

const getValidatedValue = (value: string, readonly: boolean) => {
  if (!readonly && value !== "") {
    return value;
  }

  return value !== ""
    ? formatNegativeNumbers(unFormatNegativeLocalString(value))
    : "";
};

export const endorsementOnInitCompute = (
  options: ComputeForCellParameters<any>,
  readonly: boolean
) => {
  return {
    value: getValidatedValue(options.currentValue, readonly),
    typeId:
      !readonly && options.currentValue !== ""
        ? BaseTableInputType.NUMBER
        : BaseTableInputType.READONLY,
  } as ConditionForCellResponse;
};

export const endorsementOnChangeCompute = (
  options: ComputeForCellParameters<any>
) => {
  return {
    value: options.currentValue,
    typeId: BaseTableInputType.NUMBER,
    triggerComputeFieldNames: ["Total"],
  } as ConditionForCellResponse;
};

export const totalDuePaymentOnChangeCompute = (
  options: ComputeForCellParameters<any>
) => {
  const parsedInvoiceAmount =
    options.row?.InvoiceAmount != ""
      ? unFormatNegativeLocalString(options.row?.InvoiceAmount)
      : 0;
  const parsedEndorsement =
    options.row?.Endorsement != ""
      ? unFormatNegativeLocalString(options.row?.Endorsement)
      : 0;
  return {
    value: (parsedInvoiceAmount + parsedEndorsement).toLocaleString(),
  } as ConditionForCellResponse;
};
