import { FC, useEffect, useState } from "react";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { Button, ExportJSONButton, SplitButton } from "../../../TrueUI";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../InsuredAtoms";
import { PolicyQuoteBottomButtonsProps } from "./PolicyQuoteTypes";
import {
  getJSONDataWithoutConfigurations,
  policyInformationReset,
  sectionsWithContinueAndSaveOnly,
  sectionsWithSaveOnly,
} from "./PolicyQuoteUtils";
import {
  updateInsuredAtom,
  updatePolicyQuoteInformation,
  updatePolicyQuoteInformationMultiTarget,
} from "../updatesPolicyQuoteFunctions";
import { usePolicyQuoteTriggerComponent } from "../hooks/usePolicyQuoteTriggerComponent";
import {
  INSURED_ATOM_KEY,
  INSURED_BODY_SECTION,
  POLICY_ID,
  QUOTE_ID,
} from "../../../../utilities/queryStringsHash";
import { useApiPost, usePermissions } from "../../../../hooks";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import {
  addQueryStrings,
  getQueryStringsURL,
} from "../../../../utilities/URLUtilities_OBSOLETE";
import { useNavigate } from "react-router";
import { SubSideNavItemDestinationsEnum } from "../../../../dtos/sub-side-nav-item-destinations-enum";
import {
  getTargetNameAndErrorsBySection,
  validateExposuresAndPremium,
} from "./PolicyQuoteValidationUtils";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import style from "./PolicyQuote.module.css";
import { PolicyQuoteStatusEnum } from "../../../../dtos/policy-quote-status-enum";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import UnbindModal from "./UnbindModal";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { PolicySaveOptionEnum } from "../../../../dtos/policy-save-option-enum";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";

const PolicyQuoteBottomButtons: FC<PolicyQuoteBottomButtonsProps> = ({
  insuredId,
  policySection,
  quoteStatus,
  setActiveSection,
  saveEvent,
  tabKey,
}) => {
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const navigate = useNavigate();
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps>();
  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<PolicyBlob>(
      `api/QuotePolicy/SaveQuoteJSON?insuredId=${insuredId}&section=${policySection}`,
      getAtom()?.policyQuoteInformation?.policyQuote
    );
  const {
    responsePost: responseRefreshInsuredJSON,
    dispatchPost: dispatchRefreshInsuredJSON,
  } = useApiPost<PolicyBlob>(
    "api/QuotePolicy/GetJSONWithInsuredInformationUpdated",
    getAtom()?.policyQuoteInformation?.policyQuote
  );
  const [unbindModalOpen, setUnbindModalOpen] = useState<boolean>(false);
  const permissions = usePermissions([PermissionsEnums.UNBIND_QUOTE]);
  const [saveAction, setSaveAction] = useState<number | null>(null);

  const defaultDialogConfiguration = {
    onCloseEvent: (close) =>
      setDialogConfiguration({ ...dialogConfiguration, open: close }),
    onOptionYesEvent: (close) =>
      setDialogConfiguration({ ...dialogConfiguration, open: close }),
  };

  const saveJSONToDB = (saveOption: number) => {
    setSaveAction(saveOption);
    const atomValue = getAtom();
    const isReadOnly = atomValue?.policyQuoteInformation?.readOnly;
    if (isReadOnly && saveOption === PolicySaveOptionEnum.CONTINUE) {
      updateActiveSection(atomValue);
    }
    if (
      !isReadOnly &&
      (policySection === QuotePolicySectionEnum.INFORMATION ||
        policySection === QuotePolicySectionEnum.BIND_INSTRUCTIONS)
    ) {
      dispatchPost();
    }
    if (
      !isReadOnly &&
      policySection === QuotePolicySectionEnum.EXPOSURE_AND_PREMIUM
    ) {
      const dialogValues = validateExposuresAndPremium(
        atomValue,
        defaultDialogConfiguration
      );
      if (dialogValues === null) {
        dispatchPost();
      } else setDialogConfiguration(dialogValues);
    }
  };

  const splitButtonOptions = [
    {
      key: 1,
      option: "CONTINUE",
      dropdownText: "Continue",
      action: () => saveJSONToDB(PolicySaveOptionEnum.CONTINUE),
    },
    {
      key: 2,
      option: "Save Only",
      dropdownText: "Save Only",
      action: () => saveJSONToDB(PolicySaveOptionEnum.SAVE_ONLY),
    },
  ];

  const getExportJSONData = () => {
    const atomValue = getAtom();
    const jsonData = getJSONDataWithoutConfigurations(
      atomValue?.policyQuoteInformation
    );
    return JSON.stringify(jsonData, null, 2);
  };

  const returnToHistoryTable = () => {
    const atomValue = getAtom();
    const newAtomValue = updateInsuredAtom(
      atomValue ?? {},
      policyInformationReset
    );
    setAtom(newAtomValue);
    setPolicyQuoteTriggers(["policyQuoteHeaderComponent"]);

    const historyHashes = getQueryStringsURL([
      {
        nameOfHash: INSURED_BODY_SECTION,
        valueOfHash: SubSideNavItemDestinationsEnum.HISTORY,
      },
    ]);
    navigate(historyHashes);
  };

  const cancelEvent = () => {
    {
      setDialogConfiguration({
        dialogDescriptionText: "Are you sure you want to cancel?",
        onCloseEvent: (close) =>
          setDialogConfiguration({ ...dialogConfiguration, open: close }),
        onOptionNoEvent: (close) =>
          setDialogConfiguration({ ...dialogConfiguration, open: close }),
        onOptionYesEvent: (close) => {
          returnToHistoryTable();
          setDialogConfiguration({ ...dialogConfiguration, open: close });
        },
        open: true,
      });
    }
  };

  const updateActiveSection = (
    atomValue: GlobalInsuredAtomProperties | null
  ) => {
    const newActiveSection =
      (atomValue?.policyQuoteInformation?.activeSection ?? 1) + 1;

    setActiveSection?.(newActiveSection);
    addQueryStrings([
      {
        nameOfHash: POLICY_ID,
        valueOfHash: atomValue?.policyQuoteInformation?.policyId ?? 0,
      },
      {
        nameOfHash: QUOTE_ID,
        valueOfHash: atomValue?.policyQuoteInformation?.quoteId ?? 0,
      },
    ]);
  };

  const updateJSONInAtomAndURL = () => {
    const atomValue = getAtom();

    const policyJSON =
      responsePost.axiosResponse?.data ??
      atomValue?.policyQuoteInformation?.policyQuote;
    const validationErrors = validatorErrorResponse?.errorDetails ?? null;
    const { errorTargetName, errorDetails } = getTargetNameAndErrorsBySection(
      validationErrors,
      policySection
    );
    const newAtomValue = updatePolicyQuoteInformationMultiTarget(
      atomValue,
      ["policyId", "quoteId", "policyQuote", errorTargetName],
      [
        policyJSON?.policyID,
        policyJSON?.quote?.quoteID,
        policyJSON,
        errorDetails,
      ]
    );
    setAtom(newAtomValue);
    setPolicyQuoteTriggers(["validationError"]);

    if (
      validationErrors === null &&
      saveAction === PolicySaveOptionEnum.CONTINUE
    ) {
      updateActiveSection(newAtomValue);
    }
    setSaveAction(null);
  };

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful ||
      !isEmptyValue(validatorErrorResponse)
    ) {
      updateJSONInAtomAndURL();
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyComplete(responseRefreshInsuredJSON)) {
      const atomValue = getAtom();
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        responseRefreshInsuredJSON.axiosResponse?.data
      );
      setAtom(newAtomValue);
      setDialogConfiguration({
        dialogDescriptionText: "The policy information has been refreshed",
        optionYesOverrideLabel: "OK",
        onCloseEvent: (close) =>
          setDialogConfiguration({ ...dialogConfiguration, open: close }),
        onOptionYesEvent: (close) =>
          setDialogConfiguration({ ...dialogConfiguration, open: close }),
        open: true,
      });
    }
  }, [responseRefreshInsuredJSON]);

  const getSaveButton = () => {
    if (sectionsWithContinueAndSaveOnly.includes(policySection)) {
      return (
        <SplitButton
          items={splitButtonOptions}
          disabled={quoteStatus?.value === PolicyQuoteStatusEnum.BOUND}
        />
      );
    } else if (sectionsWithSaveOnly.includes(policySection)) {
      return (
        <Button
          id="id-policy-save-only-button"
          name="namePolicySaveOnlyButton"
          size="medium"
          onClick={() => {
            saveEvent?.();
          }}
          isDisabled={
            quoteStatus?.value === PolicyQuoteStatusEnum.BOUND &&
            policySection !== QuotePolicySectionEnum.UNDERWRITING_NOTES &&
            policySection !== QuotePolicySectionEnum.LOSS_HISTORY &&
            policySection !== QuotePolicySectionEnum.ENDORSEMENT_FORMS
          }
        >
          SAVE ONLY
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <div
        id="policy-quote-bottom-buttons-container"
        className={style.policy_quote_bottom_buttons_container}
      >
        <div
          id="policy-quote-bottom-buttons-left-column"
          className={style.policy_quote_bottom_buttons_left_column}
        >
          <div
            id="policy-quote-export-json-container"
            className={style.policy_quote_bottom_buttons_right_margin}
          >
            <ExportJSONButton
              name="policy-quote-export-json"
              fileName="policy-json"
              getDataOnClick={getExportJSONData}
            />
          </div>
          {quoteStatus?.value === PolicyQuoteStatusEnum.BOUND && (
            <div
              id="refresh-insured-information-container"
              className={style.policy_quote_bottom_buttons_right_margin}
            >
              <Button
                id="refresh-insured-information-button-id"
                name="refresh-insured-information-button"
                variantStyle="outlined"
                onClick={() => dispatchRefreshInsuredJSON()}
              >
                REFRESH INSURED INFO
              </Button>
            </div>
          )}
          {quoteStatus?.value === PolicyQuoteStatusEnum.BOUND &&
            permissions.hasPermission && (
              <div id="policy-quote-re-check-for-changes-container">
                <Button
                  id="policy-quote-unbind-button-id"
                  name="policy-quote-unbind-button"
                  variantStyle="outlined"
                  onClick={() => setUnbindModalOpen(true)}
                >
                  UNBIND POLICY
                </Button>
              </div>
            )}
        </div>
        <div
          id="policy-quote-bottom-buttons-right-column"
          className={style.policy_quote_bottom_buttons_right_column}
        >
          <div
            id="policy-quote-split-continue-button-container"
            className={style.policy_quote_bottom_buttons_right_margin}
          >
            {getSaveButton()}
          </div>
          <div id="policy-quote-cancel-button-container">
            <Button
              id="id-policy-cancel-button"
              name="namePolicyCancelButton"
              variantStyle="outlined"
              size="medium"
              onClick={cancelEvent}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <DialogConfirmation
        name="policy-quote-dialog"
        id="policy-quote-dialog-confirmation"
        {...dialogConfiguration}
      />
      <UnbindModal
        tabKey={tabKey}
        modalOpen={unbindModalOpen}
        closeEvent={() => setUnbindModalOpen(false)}
      />
    </>
  );
};

export default PolicyQuoteBottomButtons;
