import { FC } from "react";
import {
  Button,
  Col,
  Input,
  InputDate,
  Row,
  Select,
  Switch,
  Upload,
} from "../../../../TrueUI";
import { Tooltip } from "@mui/material";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import style from "../RateTableStyle.module.css";
import { SelectOptions } from "../../../../../dtos/select-options";
import { getStates } from "../../../../../utilities/staticDataFunctions";
import { ClassCodesRatesImport } from "../../../../../dtos/class-codes-rates-import";
import { ImportPageProps } from "./ClassCodesRatesImportWrapper";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

type ClassCodesRatesImportFormProps = {
  pageInformation?: ImportPageProps;
  programsList?: SelectOptions[];
  setPageInformation?: (info: ImportPageProps) => void;
  onWCRATEImport?: () => void;
  onExcelImport?: () => void;
};

const ClassCodesRatesImportForm: FC<ClassCodesRatesImportFormProps> = ({
  pageInformation,
  setPageInformation,
  onWCRATEImport,
  onExcelImport,
  programsList,
}) => {
  const importFormInfo = pageInformation?.formInformation;
  const errorDetails = pageInformation?.errorDetails;

  const StatesList: Partial<SelectOptions>[] = getStates().map((state) => ({
    displayName: state.abbreviation,
    stringValue: state.abbreviation,
  }));

  const updateFileValues = (files: { [key: string]: number }, response) => {
    const keys = Object.keys(files ?? []);
    const newFileIds = keys.map((key) => files?.[key]);
    const lastItem = newFileIds?.length > 0 ? newFileIds?.length - 1 : 0;
    setPageInformation?.({
      ...pageInformation,
      files: files,
      buttonProps: {
        isExcelDisabled: response?.[0]?.fileName.includes(".txt") ?? true,
        isWCRATEDisabled: response?.[0]?.fileName.includes(".xls") ?? true,
      },
      formInformation: {
        ...pageInformation?.formInformation,
        fileId: newFileIds?.[lastItem],
      },
      importInformation: null,
    });
  };

  const today = new Date();
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  ).getTime();

  const setImportFormInfo = (formInformationChanges: ClassCodesRatesImport) => {
    setPageInformation?.({
      ...pageInformation,
      formInformation: formInformationChanges,
      importInformation: null,
    });
  };

  const setClear = (bool: boolean) => {
    setPageInformation?.({
      ...pageInformation,
      clear: bool,
    });
  };

  return (
    <div className={style?.container_form}>
      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col breakpoints={{ xs: 12, md: 6, lg: 3 }}>
          <Select
            id="program-id"
            name="program-name"
            label="Program"
            labelPosition="top"
            labelFontType="BOLD_CAPTION"
            value={importFormInfo?.programId}
            options={programsList ?? []}
            onChange={(e) => {
              setImportFormInfo({
                ...importFormInfo,
                programId: e,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 1 }}>
          <Select
            id="state-id"
            name="state-name"
            label="State"
            labelPosition="top"
            labelFontType="BOLD_CAPTION"
            value={importFormInfo?.state}
            options={StatesList}
            onChange={(e) => {
              setImportFormInfo({
                ...importFormInfo,
                state: e,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 3 }}>
          <InputDate
            id="effective-date"
            name="effective-date"
            label="Effective Date"
            value={importFormInfo?.effectiveDate}
            errorMessage={errorDetails?.effectiveDate}
            minNumericValue={firstDayOfCurrentMonth}
            onChangeRawValue={(e) => {
              setImportFormInfo({
                ...importFormInfo,
                effectiveDate: e,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 1 }}>
          <Input
            id={"LCM"}
            name="LCM"
            label="LCM"
            labelPosition="top"
            value={importFormInfo?.lCM?.toString() ?? "0"}
            type={"fixedCurrency"}
            decimalScale={4}
            minNumericValue={0.0005}
            maxNumericValue={99.9999}
            prefix=""
            errorMessage={errorDetails?.lCM}
            onChangeRawValue={(value) => {
              setImportFormInfo({
                ...importFormInfo,
                lCM: value,
              });
            }}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 4 }}>
          <Switch
            control={"switch"}
            label="Expire All Current (including those without new rates)?"
            labelFontType="BOLD_CAPTION"
            labelPlacement="top"
            inputWidth="100%"
            labelAlignment="left"
            className={style?.expired_switch}
            isChecked={importFormInfo?.isExpired}
            onChangeIsChecked={(value) => {
              setImportFormInfo({
                ...importFormInfo,
                isExpired: value,
              });
            }}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col breakpoints={{ xs: 12, md: 6, lg: 7 }}>
          <Upload
            name="upload-files"
            files={pageInformation?.files}
            onUploadFileList={updateFileValues}
            clearFiles={pageInformation?.clear}
            resetClearFiles={setClear}
            maxFileCount={1}
            allowedExtensions={[".txt", ".xlsx"]}
          />
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <Button
            fullWidth
            onClick={onWCRATEImport}
            isDisabled={pageInformation?.buttonProps?.isWCRATEDisabled}
          >
            IMPORT WCRATE
          </Button>
        </Col>
        <Col breakpoints={{ xs: 12, md: 6, lg: 2 }}>
          <Button
            fullWidth
            onClick={onExcelImport}
            isDisabled={pageInformation?.buttonProps?.isExcelDisabled}
          >
            IMPORT EXCEL
          </Button>
        </Col>
        <Col
          horizontalAlign="flex-start"
          breakpoints={{ xs: 12, md: 6, lg: 1 }}
        >
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: "500px",
                },
              },
            }}
            title={
              <div className={style.tooltip_title}>
                <span className={style.tooltip_line}>
                  Allowable file type = .xlsx
                </span>
                <span className={style.tooltip_line}>
                  Column headers should be removed. Line 1 is the first class
                  code
                </span>
                <span className={style.tooltip_line}>
                  Columns: Class Code, Base Rate, ELR, D-ratio, Hazard,
                  Industry, Minimum Premium
                </span>
                <span className={style.tooltip_line}>
                  Column values: String, Decimal, Decimal, Decimal, String,
                  Integer, Decimal
                </span>
              </div>
            }
            className={style.tooltip_import_help}
            placement={"left-start"}
            arrow
          >
            <HelpOutlineIcon className={style.help_icon} />
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};
export default ClassCodesRatesImportForm;
