import { FC, useEffect, useState } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import AuditDocumentPackets from "./AuditDocumentPackets";
import AuditDocumentSelectedPackets from "./AuditDocumentSelectedPackets";
import AuditDocumentsIndividual from "./AuditDocumentsIndividual";
import { useApiGet } from "../../../../hooks";
import { ProgramPacketPage } from "../../../../dtos/program-packet-page";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";
import { AuditDocumentsUIProps } from "../AuditForm/AuditTypes";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import style from "../AuditForm/Audit.module.css";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import AuditDocumentsCommands from "./AuditDocumentsCommands";
import { ProgramPacketDto } from "../../../../dtos/program-packet-dto";

const defaultAuditDocumentsUI = {
  allDocuments: null,
  selectedPackets: [],
  selectedPacketDocuments: [],
  selectedDocuments: [],
} as AuditDocumentsUIProps;

const AuditDocuments: FC<EndorsementGeneralProps> = (props) => {
  const atomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const [panes, setPanes] = useState<Array<any>>([
    { size: "25%", min: "15%", max: "30%" },
    { min: "40%", max: "70%" },
    { size: "30%", min: "20%", max: "40%" },
  ]);
  const getPolicyId = () => {
    const atomValue = getAtom();
    return atomValue?.policyQuoteInformation?.policyId;
  };
  const getQuoteId = () => {
    const atomValue = getAtom();
    return atomValue?.policyQuoteInformation?.quoteId;
  };
  const getEffectiveDate = () =>
    FormattingDate(
      getAtom()?.policyQuoteInformation?.policyQuote?.effectiveDate
    );
  const { responseGet, dispatchGet } = useApiGet<ProgramPacketPage>(
    `api/QuotePolicy/GetProgramPacketList?policyId=${getPolicyId()}&quoteId=${getQuoteId()}&effectiveDate=${getEffectiveDate()}&formType=${
      PolicyFormTypeEnum.FINAL_AUDIT_FORM
    }`
  );
  const [auditDocumentsUI, setAuditDocumentsUI] =
    useState<AuditDocumentsUIProps>(defaultAuditDocumentsUI);

  const onChangeSplitterPanes = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  const getSelectedPacketsUpdatedByPacketChanged = (
    programPacket: ProgramPacketDto,
    checked: boolean
  ) => {
    if (checked === false) {
      return auditDocumentsUI.selectedPackets.filter(
        (selectedPacket) => selectedPacket.packetId !== programPacket.packetId
      );
    } else {
      return [...auditDocumentsUI.selectedPackets, programPacket];
    }
  };

  const getSelectedPacketDocumentsUpdatedByPacketChange = (
    selectedPackets: ProgramPacketDto[]
  ) => {
    const selectedPacketIds = selectedPackets.flatMap(
      (selectedPacket) => selectedPacket.packetId
    );
    const packetDocumentsFiltered =
      auditDocumentsUI.allDocuments?.packetDocuments.filter((packetDocument) =>
        selectedPacketIds.includes(packetDocument.packetID)
      ) ?? [];

    const onlyDocuments = packetDocumentsFiltered.flatMap(
      (packetDocument) => packetDocument.programPolicyDocument
    );

    const noDuplicatedDocumentsMapped = new Map(
      onlyDocuments.map((programDocument) => [
        programDocument.programDocId,
        programDocument,
      ])
    );

    return [...noDuplicatedDocumentsMapped.values()];
  };

  const selectedIndividualDocumentsUpdatedByPacketChange = (
    selectedPacketDocumentsUpdated: ProgramPolicyDocumentDto[],
    checked: boolean
  ) => {
    if (checked === true) {
      return auditDocumentsUI.selectedDocuments.filter((selectedDocument) =>
        selectedPacketDocumentsUpdated.every(
          (selectedPacketDocument) =>
            selectedPacketDocument.programDocId !==
            selectedDocument.programDocId
        )
      );
    } else {
      return auditDocumentsUI.selectedDocuments;
    }
  };

  const onChangePacketSelected = (
    programPacketId: ProgramPacketDto,
    checked: boolean
  ) => {
    const selectedPacketsUpdated = getSelectedPacketsUpdatedByPacketChanged(
      programPacketId,
      checked
    );

    const selectedPacketDocumentsUpdated =
      getSelectedPacketDocumentsUpdatedByPacketChange(selectedPacketsUpdated);

    const selectedDocumentsUpdated =
      selectedIndividualDocumentsUpdatedByPacketChange(
        selectedPacketDocumentsUpdated,
        checked
      );

    setAuditDocumentsUI({
      ...auditDocumentsUI,
      selectedPackets: selectedPacketsUpdated,
      selectedPacketDocuments: selectedPacketDocumentsUpdated,
      selectedDocuments: selectedDocumentsUpdated,
    });
  };

  const onChangeIndividualDocumentSelected = (
    programPolicyDocument: ProgramPolicyDocumentDto,
    checked: boolean
  ) => {
    if (checked === false) {
      const selectedDocumentsWithoutChangedDocument =
        auditDocumentsUI.selectedDocuments.filter(
          (selectedDocument) =>
            selectedDocument.programDocId !== programPolicyDocument.programDocId
        );

      setAuditDocumentsUI({
        ...auditDocumentsUI,
        selectedDocuments: selectedDocumentsWithoutChangedDocument,
      });
    } else {
      const selectedDocumentsWithChangedDocument = [
        ...auditDocumentsUI.selectedDocuments,
        programPolicyDocument,
      ];

      setAuditDocumentsUI({
        ...auditDocumentsUI,
        selectedDocuments: selectedDocumentsWithChangedDocument,
      });
    }
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseGet) &&
      responseGet.axiosResponse?.data !== undefined
    ) {
      setAuditDocumentsUI({
        ...auditDocumentsUI,
        allDocuments: responseGet.axiosResponse.data,
      });
    }
  }, [responseGet]);

  return auditDocumentsUI.allDocuments !== null ? (
    <div
      id="audit-documents-container-id"
      className={style.audit_documents_container}
    >
      <Splitter
        className={style.audit_documents_splitter}
        panes={panes}
        onChange={onChangeSplitterPanes}
      >
        <AuditDocumentPackets
          programPackets={auditDocumentsUI.allDocuments?.packets ?? []}
          selectedPackets={auditDocumentsUI.selectedPackets}
          onChangePacketSelected={onChangePacketSelected}
        />
        <AuditDocumentSelectedPackets
          tabKey={props.tabKey}
          selectedPacketDocuments={auditDocumentsUI.selectedPacketDocuments}
        />
        <AuditDocumentsIndividual
          tabKey={props.tabKey}
          allProgramPolicyDocuments={
            auditDocumentsUI.allDocuments?.policyDocuments ?? []
          }
          selectedPacketDocuments={auditDocumentsUI.selectedPacketDocuments}
          selectedDocuments={auditDocumentsUI.selectedDocuments}
          onChangeIndividualDocumentSelected={
            onChangeIndividualDocumentSelected
          }
        />
      </Splitter>
      <AuditDocumentsCommands
        tabKey={props.tabKey}
        selectedPackets={auditDocumentsUI.selectedPackets}
        selectedPacketDocuments={auditDocumentsUI.selectedPacketDocuments}
        selectedDocuments={auditDocumentsUI.selectedDocuments}
      />
    </div>
  ) : null;
};

export default AuditDocuments;
