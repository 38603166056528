import { FC, useEffect, useState } from "react";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import { spreadDetailsContainersInformationWithTarget } from "../spreadClaimTabsFunctions";
import { useRecoilValue } from "recoil";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { Collapse } from "../../../../../TrueUI";
import Link from "../../../../../TrueUI/Typography/Link";
import ClaimsCustomFields from "./ClaimsCustomFields";

type ClaimCustomFieldProps = {
  insuredIdAtomKey: string;
  claimId?: number;
  customFieldsData?: any;
  errorDetails?: any;
  hasClaimsManagementPermissions: boolean;
};
const ClaimCustomFieldsContainer: FC<ClaimCustomFieldProps> = ({
  customFieldsData,
  insuredIdAtomKey,
  errorDetails,
  hasClaimsManagementPermissions,
}) => {
  const [customFieldsUI, setCustomFieldsUI] = useState(
    customFieldsData ?? null
  );

  const { setAtom, getAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const listenerDetailsComponent = useRecoilValue(
    TriggerComponentUpdateAtom("customFieldComponent")
  );

  const readOnly = conditionHasValue(customFieldsUI?.isExpanded)
    ? !customFieldsUI?.isExpanded
    : true;

  const setCustomFieldsData = (updatedDetailsData: any) => {
    const atomValue = getAtom();

    const newAtomValue = spreadDetailsContainersInformationWithTarget(
      atomValue,
      "customFieldsContainerData",
      updatedDetailsData
    );

    setAtom(newAtomValue);
    setCustomFieldsUI(updatedDetailsData);
  };

  const updateData = (fieldName: string, value: any) => {
    setCustomFieldsData({
      ...customFieldsUI,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    setCustomFieldsUI(customFieldsData ?? null);
  }, [customFieldsData]);

  useEffect(() => {
    if (listenerDetailsComponent !== null) {
      const updatedAtom = getAtom();
      setCustomFieldsUI({
        ...updatedAtom?.claimTabsInformation?.claimDetailsTab
          ?.detailsContainersInformation?.customFieldsContainerData,
      });
    }
  }, [listenerDetailsComponent]);

  useEffect(() => {
    if (conditionHasValue(errorDetails)) updateData("isExpanded", true);
  }, [errorDetails]);

  return (
    <Collapse
      id="claim-custom-fields-collapse"
      collapsedHeight={"auto"}
      hasContentOnReadOnly
      title={
        <Link
          linkFontType={"BOLD_TITLE"}
          displayValue={"Custom Fields"}
          name="claimCustomFieldsCollapseTitle"
        ></Link>
      }
      expanded={!readOnly}
      onChange={(value) => {
        updateData("isExpanded", value);
        setComponentTriggers(["buttonComponent"]);
      }}
      content={
        <ClaimsCustomFields
          claimCustomFieldsData={customFieldsUI}
          setClaimCustomFieldsData={setCustomFieldsData}
          readOnly={!(hasClaimsManagementPermissions && !readOnly)}
          errorDetails={errorDetails}
        />
      }
    />
  );
};
export default ClaimCustomFieldsContainer;
