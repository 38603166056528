import { BaseTableColumn } from "../../../../../../dtos/base-table-column";
import { PolicyWaiverDto } from "../../../../../../dtos/policy-waiver-dto";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../../../utilities/conditionalSupportFunctions";
import {
  customRound,
  unFormatLocalString,
} from "../../../../../../utilities/stringFunctions";
import {
  ComputeForCellParameters,
  ConditionForCellResponse,
} from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { getDataIndexByColumnName } from "../../../../../TrueUI/Tables/tableFunctions";
import { GlobalInsuredAtomProperties } from "../../../../InsuredAtoms";
import { SpecificWaiverFormUIProps } from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import { getStateByStateCodeAndDates } from "../../../PolicyQuoteForm/PolicyQuoteUtils";

export const stateHasValidPayrollExposures = (
  atomValue: GlobalInsuredAtomProperties | null,
  specificWaiverFormUI?: SpecificWaiverFormUIProps
) => {
  const currentState = getStateByStateCodeAndDates(
    specificWaiverFormUI?.stateCode ?? "",
    specificWaiverFormUI?.effectiveDate ?? new Date(),
    specificWaiverFormUI?.expirationDate ?? new Date(),
    atomValue
  );

  const exposureWithPayrollBasis = currentState?.exposures?.find(
    (exposure) => exposure.rateBasis === "Payroll"
  );

  return exposureWithPayrollBasis !== undefined;
};

export const getSpecificWaiverTableData = (
  atomValue: GlobalInsuredAtomProperties | null,
  specificWaiverFormUI?: SpecificWaiverFormUIProps
) => {
  const currentState = getStateByStateCodeAndDates(
    specificWaiverFormUI?.stateCode ?? "",
    specificWaiverFormUI?.effectiveDate ?? new Date(),
    specificWaiverFormUI?.expirationDate ?? new Date(),
    atomValue
  );
  return (
    currentState?.exposures
      ?.map((exposure) =>
        exposure.rateBasis === "Payroll"
          ? [
              exposure.classCodeID?.toString(),
              `${exposure.classCode}${exposure.classSuffix ?? ""}`,
              exposure?.classSuffix ?? "",
              exposure.modRate,
              customRound(
                exposure.exposureAmount?.toString() ?? "",
                0
              ).toLocaleString(),
              "0",
              "0",
            ]
          : []
      )
      .filter((ele) => ele.length > 0) ?? []
  );
};

const getRowValueByIndex = (row: string[], index: number) =>
  isEmptyValue(row[index]) ? 0 : unFormatLocalString(row[index].toString());

export const updateTotalsByTableChanged = (
  data: string[][],
  columns: BaseTableColumn[],
  specificWaiverUI?: PolicyWaiverDto | null,
  setSpecificWaiverUI?: React.Dispatch<
    React.SetStateAction<PolicyWaiverDto | null | undefined>
  >
) => {
  if (conditionHasValue(specificWaiverUI)) {
    const rateIndex = getDataIndexByColumnName(columns, "Rate");
    const classCodeIdIndex = getDataIndexByColumnName(columns, "ClassCodeID");
    const suffixIndex = getDataIndexByColumnName(columns, "Suffix");
    const waiverPayrollIndex = getDataIndexByColumnName(
      columns,
      "WaiverPayroll"
    );
    const premiumChargeIndex = getDataIndexByColumnName(
      columns,
      "PremiumCharge"
    );

    const totalWaiverPayroll = data.reduce(
      (acc, row) => (acc += getRowValueByIndex(row, waiverPayrollIndex)),
      0
    );
    const waiverCharge = data.reduce(
      (acc, row) => (acc += getRowValueByIndex(row, premiumChargeIndex)),
      0
    );
    const totalWaiverCharge =
      waiverCharge < (specificWaiverUI?.minimumValue ?? 0)
        ? specificWaiverUI?.minimumValue ?? 0
        : waiverCharge > (specificWaiverUI?.maximumValue ?? 0)
        ? specificWaiverUI?.maximumValue ?? 0
        : waiverCharge;
    const waiverPayrollList = data.map((row) => ({
      classCodeID: row[classCodeIdIndex],
      suffix: row[suffixIndex],
      rate: getRowValueByIndex(row, rateIndex),
      waiverPayroll1: getRowValueByIndex(row, waiverPayrollIndex),
      premiumCharge: getRowValueByIndex(row, premiumChargeIndex),
    }));

    setSpecificWaiverMultipleTargets(
      ["totalPayroll", "totalCharge", "waiverPayrollList"],
      [totalWaiverPayroll, totalWaiverCharge, waiverPayrollList],
      specificWaiverUI,
      setSpecificWaiverUI
    );
  }
};

export const onWaiverPayrollChange = (
  options: ComputeForCellParameters<any>
) => {
  const waiverPayroll = parseFloat(options.currentValue ?? 0);
  const policyPayroll = parseFloat(
    (options.row?.PolicyPayroll ?? 0).replaceAll(",", "")
  );
  return waiverPayroll > policyPayroll
    ? ({
        refreshCell: true,
        value: policyPayroll,
        triggerComputeFieldNames: ["PremiumCharge"],
      } as ConditionForCellResponse)
    : ({
        triggerComputeFieldNames: ["PremiumCharge"],
      } as ConditionForCellResponse);
};

export const updatePremiumCharge = (
  options: ComputeForCellParameters<any>,
  specificWaiverFormUI?: SpecificWaiverFormUIProps
) => {
  const waiverPayroll =
    (options?.row?.WaiverPayroll === ""
      ? 0
      : parseFloat(options?.row?.WaiverPayroll ?? 0)) / 100;
  const rate = parseFloat(options?.row?.Rate ?? 0);
  const baseRate = waiverPayroll * rate;
  const basePremiumCharge = baseRate * (specificWaiverFormUI?.rate ?? 1);
  return {
    value: customRound(basePremiumCharge.toString(), 2).toLocaleString(),
  };
};

export const setSpecificWaiverTarget = (
  targetProperty: string,
  targetValue: any,
  specificWaiverUI?: PolicyWaiverDto | null,
  setSpecificWaiverUI?: React.Dispatch<
    React.SetStateAction<PolicyWaiverDto | null | undefined>
  >
) => {
  setSpecificWaiverUI?.({
    ...specificWaiverUI,
    [targetProperty]: targetValue,
  });
};

export const setSpecificWaiverMultipleTargets = (
  targetProperties: string[],
  targetValues: any[],
  specificWaiverUI?: PolicyWaiverDto | null,
  setSpecificWaiverUI?: React.Dispatch<
    React.SetStateAction<PolicyWaiverDto | null | undefined>
  >
) => {
  const newSpecificWaiverValues = targetProperties.map(
    (targetProperty, index) => {
      return { [targetProperty]: targetValues[index] };
    }
  );
  const targetPropertiesJoined = Object.assign({}, ...newSpecificWaiverValues);
  setSpecificWaiverUI?.({
    ...specificWaiverUI,
    ...targetPropertiesJoined,
  });
};

export const getTotalWaiverPayroll = (
  specificWaiverUI?: PolicyWaiverDto | null
) =>
  customRound(
    specificWaiverUI?.totalPayroll?.toString() ?? "0",
    2
  ).toLocaleString();

export const getTotalWaiverCharge = (
  specificWaiverUI?: PolicyWaiverDto | null,
  rounding?: number | null
) =>
  customRound(
    specificWaiverUI?.totalCharge?.toString() ?? "0",
    rounding ?? 0
  ).toLocaleString();
