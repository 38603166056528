import { FC, useEffect, useState } from "react";
import { Button, Col, Input, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import StyleBox from "../../../TrueUI/StyleBox/StyleBox";
import { getColorWithOpacityHexaDecimal } from "../../../../media/themeConstants";
import { inputConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import { usePermissions } from "../../../../hooks";
import { buttonStyles } from "./DocumentStyles";
import { DocumentFileOptionsProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  getAllTemplateNamesToPrint,
  getDefaultFileName,
} from "./DocumentsUtils";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import { ActivityCategoryEnums } from "../../../../dtos/activity-category-enums";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { getJSONDataWithoutConfigurations } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { isJsonString } from "../../../../utilities/stringFunctions";

const columnProps = (
  columns: number,
  equalWidth: boolean,
  displayFlex: boolean
) => ({
  equalWidth: equalWidth,
  displayFlex: displayFlex,
  breakpoints: {
    xs: columns,
    sm: columns,
    md: columns,
    lg: columns,
    xl: columns,
  },
});

const DocumentFileOptions: FC<DocumentFileOptionsProps> = ({
  insuredId,
  tabKey,
  selectedPackets,
  selectedDocuments,
  programPackets,
  programDocuments,
  policyDocuments,
  customJSONToPrint,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const hasPermission = usePermissions([1, 2, 3]); // TODO: @elara Update until Antonio's PR will be ready);
  const [fileName, setFileName] = useState<string>("");
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);

  useEffect(() => {
    const insuredName =
      getAtom()?.policyQuoteInformation?.policyQuote?.insured?.insuredName ??
      "";
    const defaultFileName = getDefaultFileName(
      insuredName,
      selectedPackets,
      selectedDocuments,
      programPackets,
      policyDocuments
    );
    setFileName(defaultFileName);
  }, [selectedPackets, selectedDocuments]);

  const evaluatePrintingConfigurations = (
    policyMergeFields?: PolicyBlob | null
  ) => {
    const { hasInvalidTemplateNames, allTemplateWithDocSourceNames } =
      getAllTemplateNamesToPrint(
        selectedPackets,
        selectedDocuments,
        programDocuments,
        policyDocuments,
        policyMergeFields?.endorsementForms ?? []
      );

    if (hasInvalidTemplateNames) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "No print template(s) found.",
      });

      return { isFullyConfigured: false, allTemplateWithDocSourceNames };
    }

    if (
      allTemplateWithDocSourceNames.find(
        (templateWithDocSource) =>
          templateWithDocSource.mergeFieldSource === FormTypeEnum.CUSTOM_FORM
      ) !== undefined &&
      customJSONToPrint === ""
    ) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          "There are documents selected requesting the usage of the custom JSON for printing but there is no custom JSON set up for the current policy period.",
      });

      return { isFullyConfigured: false, allTemplateWithDocSourceNames };
    }

    return { isFullyConfigured: true, allTemplateWithDocSourceNames };
  };

  const printDocuments = () => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    const { isFullyConfigured, allTemplateWithDocSourceNames } =
      evaluatePrintingConfigurations(policyMergeFields);

    if (isFullyConfigured) {
      sendMergeFormRequest(
        {
          policyMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          customMergeFields: isJsonString(customJSONToPrint)
            ? JSON.parse(customJSONToPrint)
            : null,
          templateNameWithSpecificSourceMergeFieldsList:
            allTemplateWithDocSourceNames,
          customFileName: fileName,
        },
        "download",
        true
      );
    }
  };

  const printAndSaveDocuments = () => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    const { isFullyConfigured, allTemplateWithDocSourceNames } =
      evaluatePrintingConfigurations(policyMergeFields);

    if (isFullyConfigured) {
      sendMergeFormRequest(
        {
          policyMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          customMergeFields: isJsonString(customJSONToPrint)
            ? JSON.parse(customJSONToPrint)
            : null,
          templateNameWithSpecificSourceMergeFieldsList:
            allTemplateWithDocSourceNames,
          customFileName: fileName,
          configurationToSaveFile: {
            saveFileToInsured: true,
            fileCategory: "Policy Documents",
            insuredId,
            claimId: null,
            policyId: policyMergeFields?.policyID ?? -1,
            activityLogCategory:
              ActivityCategoryEnums.POLICY_POLICY_DOC_PACKET_PRINTED,
            activityLogDescription: !isEmptyValue(
              policyMergeFields?.policyNumber
            )
              ? `The policy documents for policy number ${policyMergeFields?.policyNumber} were generated`
              : `The policy documents for policy ID ${policyMergeFields?.policyID} were generated`,
          },
        },
        "download",
        true
      );
    }
  };

  const mailDocument = () => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    const { isFullyConfigured, allTemplateWithDocSourceNames } =
      evaluatePrintingConfigurations(policyMergeFields);

    if (isFullyConfigured) {
      sendMergeFormRequest(
        {
          policyMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          customMergeFields: isJsonString(customJSONToPrint)
            ? JSON.parse(customJSONToPrint)
            : null,
          templateNameWithSpecificSourceMergeFieldsList:
            allTemplateWithDocSourceNames,
          customFileName: fileName,
        },
        "mail",
        true
      );
    }
  };

  return (
    <StyleBox
      margin={0}
      padding={0}
      width={"100%"}
      showBorder
      overrideBorderColor={getColorWithOpacityHexaDecimal(
        theme.BORDER_COLOR,
        50
      )}
    >
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        numberOfColumns={24}
      >
        <Col {...columnProps(17, true, false)} verticalAlignSelf="center">
          <Input
            {...inputConfiguration("file-name", "fileName", "File Name", 100)}
            value={fileName}
            onChangeRawValue={setFileName}
            labelFontType={"BOLD_CAPTION"}
            labelPosition="start"
            variant="filled"
            readOnly={!hasPermission}
            fileNameValidation
          />
        </Col>
        <Col {...columnProps(7, false, true)}>
          <Button
            id="policy-doc-email"
            name="email"
            onClick={mailDocument}
            sx={buttonStyles()}
          >
            EMAIL
          </Button>
          <Button
            id="policy-doc-print-save"
            name="printSave"
            onClick={printAndSaveDocuments}
            sx={buttonStyles()}
          >
            SAVE AND PRINT
          </Button>
          <Button
            id="policy-doc-print"
            name="print"
            onClick={printDocuments}
            sx={buttonStyles()}
          >
            PRINT
          </Button>
        </Col>
      </Row>
      <DialogConfirmation
        {...dialogConfiguration}
        id="id-dialog-confirmation-document-file-options"
        optionYesOverrideLabel="OK"
        onOptionYesEvent={() => setDialogConfiguration(null)}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </StyleBox>
  );
};

export default DocumentFileOptions;
