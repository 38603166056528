import { FC } from "react";
import { Input } from "../../../../../../TrueUI";
import { useRecoilState } from "recoil";
import { ClaimFinancialPaymentBillDetailsAtom } from "../ClaimFinancialAtoms";
import style from "./BillDetails.module.css";

const FooterPaymentBillDetails: FC = () => {
  const [paymentBillDetailsAtom] = useRecoilState(
    ClaimFinancialPaymentBillDetailsAtom
  );
  return (
    <div className={style.billing_details_totals_footer_container}>
      <div className={style.billing_details_totals_footer_item}>
        <Input
          id="payment-billing-details-total-charges"
          name="payment-billing-details-total-charges"
          label="Total Charges"
          maxNumericValue={999999999999}
          type={"fixedCurrency"}
          prefix={""}
          align={"right"}
          value={paymentBillDetailsAtom?.totalCharge}
          labelFontType={"BOLD_TITLE"}
          readOnly
          labelPosition={"start"}
        />
      </div>
      <div className={style.billing_details_totals_footer_item}>
        <Input
          id="payment-billing-details-total-paid"
          name="payment-billing-details-total-paid"
          label="Total Paid"
          maxNumericValue={999999999999}
          type={"fixedCurrency"}
          prefix={""}
          align={"right"}
          value={paymentBillDetailsAtom?.totalPaid}
          labelFontType={"BOLD_TITLE"}
          readOnly
          labelPosition={"start"}
        />
      </div>
      <div className={style.billing_details_totals_footer_space}></div>
    </div>
  );
};
export default FooterPaymentBillDetails;
