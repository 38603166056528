import { Box, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { DiaryNoteDto } from "../../../../dtos/diary-note-dto";
import {
  ConversationWrapper,
  Input,
  MultiSelect,
  Select,
  SplitButton,
  TabPanel,
  Tabs,
} from "../../../TrueUI";
import { FilterAlt } from "@mui/icons-material";
import { ContextProps } from "../InsuredDrawerConstants";
import { DiaryConfigurationDto } from "../../../../dtos/diary-configuration-dto";
import { getComparator } from "../../../../utilities/orderFunctions";
import {
  changeNoneOrEmptyToAll,
  getValueByOptionName,
  optionOrderTypes,
} from "../../../../utilities/selectFunctions";
import { DIARY_TABS } from "../../../../utilities/queryStringsHash";
import { ContextEnums } from "../../../../dtos/context-enums";
import { useApiPost, useFormRequest } from "../../../../hooks";
import { MergeFieldsAndTemplateNameDto } from "../../../../dtos/merge-fields-and-template-name-dto";
import { AllDiaryNoteJSON } from "../../../../dtos/all-diary-note-json";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { SelectOptions } from "../../../../dtos/select-options";
import { removeASCIIFromText } from "../../../../utilities/stringFunctions";
import { ItemProperties } from "../../../TrueUI/Buttons/SplitButton";

type FilterTabsNotesProps = {
  context: ContextProps;
  expanded: boolean;
  diaryNotes: DiaryNoteDto[];
  diaryConfiguration: DiaryConfigurationDto;
  setRefreshConversation: (refresh: boolean) => void;
};

type FilterProps = {
  tabIndex: number;
  typeFilter: number;
  policyFilter: number;
  categoryFilter: Partial<SelectOptions>[];
  orderFilter: number;
  search: null | string;
};

const initialFilters: FilterProps = {
  tabIndex: 0,
  typeFilter: -1,
  policyFilter: -1,
  categoryFilter: [],
  orderFilter: 1,
  search: null,
};

const FilterTabsNotes: FC<FilterTabsNotesProps> = ({
  context,
  expanded,
  diaryNotes,
  diaryConfiguration,
  setRefreshConversation,
}) => {
  const [filters, setFilter] = useState<FilterProps>(initialFilters);
  const [notesToShow, setNotesToShow] = useState<DiaryNoteDto[]>(diaryNotes);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { sendMergeFormRequest } = useFormRequest();
  const urlGetPrintAllNotes =
    context.type === ContextEnums.INSURED
      ? `api/DiaryNote/GetAllDiaryNotesToPrintByInsuredId?insuredId=${context.insuredId}&policyFilter=${filters.policyFilter}`
      : `api/DiaryNote/GetAllDiaryNotesToPrintByClaimNumber?claimNumber=${context.claimNumber}`;
  const {
    responsePost: responsePrintAllNotes,
    dispatchPost: dispatchPrintAllNotes,
  } = useApiPost<MergeFieldsAndTemplateNameDto<AllDiaryNoteJSON>>(
    urlGetPrintAllNotes,
    notesToShow
  );

  const categoryOptions = changeNoneOrEmptyToAll(
    "",
    diaryConfiguration?.diaryCategoryOptions
  );

  const policyOptions = changeNoneOrEmptyToAll(
    "None",
    diaryConfiguration?.diaryPolicyOptions
  );

  const typeToTabsOptions = diaryConfiguration?.diaryTypeOptions.filter(
    (type) => type?.displayName != ""
  );

  const exportActionOptions: ItemProperties[] = [
    {
      option: "EXCEL",
      action: () => alert("Future Implementation."),
    },
    {
      option: "PDF",
      action: () => dispatchPrintAllNotes(),
    },
  ];

  const validateContent = (content: string) => {
    return content
      .replaceAll(/<\/?[^>]+(>|$)/gi, "")
      .toLocaleLowerCase()
      .search(`${filters?.search?.toLowerCase().trim()}`);
  };

  useEffect(() => {
    const notes = [...diaryNotes]
      .filter(
        (note) => filters.typeFilter === -1 || note.type === filters.typeFilter
      )
      .filter(
        (note) =>
          filters.policyFilter === -1 || note.policy === filters.policyFilter
      )
      .filter((note) => {
        const noFilter =
          filters.categoryFilter.every((x) => x.intValue !== -1) === false ||
          filters.categoryFilter.length === 0;
        const matchesCategory =
          filters.categoryFilter.every(
            (x) =>
              x.intValue !==
              getValueByOptionName(note.category, categoryOptions)
          ) === false;

        return noFilter || matchesCategory;
      })
      .filter((note) => {
        if (
          filters.search === null ||
          filters.search === undefined ||
          filters?.search?.length < 3
        ) {
          return true;
        }
        const result = validateContent(note.content ?? "");

        const resultReplies = (note?.childrenNotes ?? [])
          .map((reply: DiaryNoteDto) => validateContent(reply?.content ?? ""))
          .filter((r) => r !== -1);

        return result !== -1 || resultReplies.length > 0 ? true : false;
      });

    const filterType = filters.orderFilter === 1 ? "newest" : "oldest";
    notes.sort(getComparator(filterType) as any);
    notes.forEach((note) => {
      note.childrenNotes = note?.childrenNotes?.sort(
        getComparator(filterType) as any
      );
      note.content = removeASCIIFromText(note.content);
    });
    setNotesToShow(notes);
  }, [filters, diaryNotes]);

  const typeFilterChange = (e) => {
    // The -1 is to remove the index of the All tab
    // and get the correct index of the type
    const indexTab = e.selected - 1;
    const type = typeToTabsOptions[indexTab]?.intValue ?? -1;
    setFilter({ ...filters, typeFilter: type, tabIndex: e.selected });
  };

  const orderFilterChange = (value) => {
    setFilter({ ...filters, orderFilter: value });
  };

  const categoryFilterChange = (values) => {
    setFilter({ ...filters, categoryFilter: values });
  };

  const policyFilterChange = (value) => {
    setFilter({ ...filters, policyFilter: value });
  };

  const searchFilterChange = (value) => {
    setFilter({ ...filters, search: value });
  };

  const printAllNotes = (
    templateNameAndAllDiaryNoteJSON?: MergeFieldsAndTemplateNameDto<AllDiaryNoteJSON>
  ) => {
    if (
      templateNameAndAllDiaryNoteJSON !== undefined &&
      templateNameAndAllDiaryNoteJSON !== null &&
      !isEmptyValue(templateNameAndAllDiaryNoteJSON.templateName)
    ) {
      const mergeFields = templateNameAndAllDiaryNoteJSON.mergeFields;
      const claimNumber = !isEmptyValue(mergeFields.claimNumber)
        ? `${mergeFields.claimNumber} `
        : "";
      const customFileName =
        context.type === ContextEnums.INSURED
          ? `${mergeFields.insuredName} Notes`
          : `${mergeFields.insuredName} ${claimNumber}Notes`;

      sendMergeFormRequest({
        formType: FormTypeEnum.ALL_DIARY_NOTE_FORM,
        allDiaryNoteMergeFields: templateNameAndAllDiaryNoteJSON.mergeFields,
        templateNameWithExtensionList: [
          templateNameAndAllDiaryNoteJSON.templateName,
        ],
        customFileName,
      });
    }
    if (isEmptyValue(templateNameAndAllDiaryNoteJSON?.templateName)) {
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    if (isAPITotallyComplete(responsePrintAllNotes))
      printAllNotes(responsePrintAllNotes.axiosResponse?.data);
  }, [responsePrintAllNotes]);

  return (
    <>
      <Box className={"filters-container"}>
        <Box>
          <Tabs
            name={DIARY_TABS}
            selected={filters.tabIndex}
            onSelect={typeFilterChange}
            themeOverride={"material"}
          >
            {/* If you remove this Tab, please review the typeFilterChange function */}
            <TabPanel title={"ALL"}></TabPanel>
            {typeToTabsOptions.map((type, index) => (
              <TabPanel
                title={type.displayName}
                key={type.intValue ?? 0 + index}
              ></TabPanel>
            ))}
          </Tabs>
        </Box>
        <Box className={"selects-container"}>
          <Box className={"first-select"}>
            <IconButton onClick={() => alert("Waiting info...")}>
              <FilterAlt fontSize="small" />
            </IconButton>
            <Select
              id="filter-tab-notes-sort"
              name="filterTabNotesSort"
              label="Sort:"
              labelPosition="start"
              options={optionOrderTypes}
              value={filters.orderFilter}
              onChange={orderFilterChange}
              inputWidth={"200px"}
              labelFontType={"TITLE"}
            />
          </Box>
          <MultiSelect
            id="tab-notes-category"
            name="tabNotesCategory"
            label="Category:"
            labelPosition="start"
            labelFontType="TITLE"
            inputWidth="300px"
            optionsMaxHeight="350px"
            options={categoryOptions}
            values={filters?.categoryFilter}
            onChange={categoryFilterChange}
            showClearAll
          />
          {context.type === ContextEnums.INSURED && (
            <Select
              id="tab-notes-policy"
              name="tabNotesPolicy"
              label="Policy:"
              labelPosition="start"
              options={policyOptions}
              value={filters.policyFilter}
              onChange={policyFilterChange}
              inputWidth={"150px"}
              optionsMaxHeight="150px"
              labelFontType={"TITLE"}
            />
          )}
        </Box>
      </Box>
      <Box className="note_controls_container">
        <Box className="note_controls_column">
          <Input
            className="search-notes-input"
            name="name-note-search"
            id="id-note-search"
            type="search"
            placeholder="search note text"
            value={filters.search ?? ""}
            inputWidth={"25%"}
            variant="standard"
            onChangeRawValue={searchFilterChange}
          />
        </Box>
        <Box className="note_controls_column note_right_align">
          <SplitButton items={exportActionOptions} />
        </Box>
      </Box>
      <ConversationWrapper
        expanded={expanded}
        diaryConfiguration={diaryConfiguration}
        notes={notesToShow}
        context={context}
        setRefreshConversation={setRefreshConversation}
      />
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </>
  );
};

export default FilterTabsNotes;
