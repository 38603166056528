import { PaymentDto } from "../../../../../../../dtos/payment-dto";
import { PaymentStatusEnum } from "../../../../../../../dtos/payment-status-enum";
import { PermissionsEnums } from "../../../../../../../dtos/permissions-enums";

export const GetShouldDisplayPaymentBillDetailsButton = (
  payment,
  modalConfig
) => {
  const displayByRequiredPaymentDataFilled =
    (payment?.paymentId ?? 0) === 0 ||
    payment?.paymentStatus === PaymentStatusEnum.PENDING ||
    payment?.paymentStatus === PaymentStatusEnum.PENDING_APPROVAL;

  return (
    displayByRequiredPaymentDataFilled &&
    modalConfig?.permissionToAddPayment === true &&
    modalConfig?.programClaimEOBEntry === true
  );
};

export const GetShouldDisablePaymentBillDetailsButton = (
  payment: PaymentDto | Partial<PaymentDto> | null
) => {
  const payeeSelectedValidation =
    (payment?.payToClaimant ?? false) === false &&
    (payment?.payeeId ?? 0) === 0;

  return (
    (payment?.invoiceDate ?? 0) === 0 ||
    (payment?.paymentDate ?? 0) === 0 ||
    (payment?.reserveTypeId ?? 0) === 0 ||
    (payment?.paymentCategoryId ?? 0) === 0 ||
    payeeSelectedValidation === true
  );
};

export const addPaymentPermissions = [
  PermissionsEnums.CLAIMS_ADJUSTER,
  PermissionsEnums.CLAIMS_MANAGER,
  PermissionsEnums.CLAIMS_ADMIN,
];
