import { FC, useEffect, useState } from "react";
import { Col, Font, Loading, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { PolicyQuoteEndorsementFormsProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { getConfigurationForColumn } from "../PolicyCalculator/PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import { ProgramPolicyEndorsementBlob } from "../../../../dtos/program-policy-endorsement-blob";
import "../EndorsementForm/Endorsement.module.css";
import Link from "../../../TrueUI/Typography/Link";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { useFormRequest } from "../../../../hooks";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { getJSONDataWithoutConfigurations } from "../PolicyQuoteForm/PolicyQuoteUtils";

const RequiredForms: FC<PolicyQuoteEndorsementFormsProps> = ({
  forms,
  tabKey,
  fileName,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const [localForms, setLocalForms] = useState<any>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { sendMergeFormRequest } = useFormRequest();
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));

  const printIndividualDocument = (document: ProgramEndorsementBlob) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (
      !isEmptyValue(policyMergeFields) &&
      !isEmptyValue(document.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.POLICY_FORM,
        policyMergeFields: getJSONDataWithoutConfigurations(
          getAtom()?.policyQuoteInformation
        ),
        templateNameWithExtensionList: [document.templateName ?? ""],
        customFileName: `${
          fileName ?? policyMergeFields?.insured?.insuredName ?? ""
        } ${document.formName ?? ""}`,
      });
    }
    if (isEmptyValue(document.templateName)) {
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    setLocalForms(forms);
  }, [forms]);

  return localForms ? (
    <Row {...rowWithNoMarginNorGutter} rowDirection={"column"}>
      <Col
        {...colWithNoMarginNorGutter}
        {...getConfigurationForColumn(12, "flex-start", "center")}
        displayFlex={false}
        className="endorsement-policy-documents-title"
      >
        <Col {...getConfigurationForColumn(12, "flex-start", "center")}>
          <Font>ENDORSEMENT POLICY DOCUMENTS</Font>
        </Col>
      </Col>
      {localForms &&
        Object.keys(localForms)
          .sort()
          .map((key) => (
            <div
              className="endorsement-policy-documents-row"
              key={`required-policy-box-${key}`}
            >
              <Col
                key={`required-forms-col-${key}`}
                verticalGutter="0px"
                horizontalGutter="15px"
                {...getConfigurationForColumn(12, "flex-start", "center")}
                displayFlex={false}
              >
                {localForms?.[key]?.map(
                  (form: ProgramPolicyEndorsementBlob) => (
                    <div
                      className="endorsement-documents-forms-row"
                      key={`required-forms-box-${form.programPolicyEndorsementDocId}`}
                    >
                      <Row {...rowWithNoMarginNorGutter} numberOfColumns={24}>
                        <Col
                          {...colWithNoMarginNorGutter}
                          {...getConfigurationForColumn(
                            5,
                            "flex-start",
                            "center"
                          )}
                        >
                          <Link
                            linkFontType="BODY"
                            displayValue={`${form.programDocName}`}
                            onClick={() => printIndividualDocument(form)}
                          />
                        </Col>
                      </Row>
                    </div>
                  )
                )}
              </Col>
            </div>
          ))}
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </Row>
  ) : (
    <Loading isLoading={true} />
  );
};

export default RequiredForms;
