import { FC, useEffect, useState } from "react";
import { IncidentMergeFieldsAndTemplateNameDto } from "../../dtos/incident-merge-fields-and-template-name-dto";
import { useApiGet, useFormRequest } from "../../hooks";
import { isEmptyValue } from "../../utilities/conditionalSupportFunctions";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../TrueUI/Dialogs/DialogConfirmation";
import { FormTypeEnum } from "../../dtos/form-type-enum";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";

type PrintIncidentProps = {
  dispatchPrintFROI?: boolean;
  setDispatchPrintFROI?: (value: boolean) => void;
  incidentId: number;
  stateCode: string;
  claimantName: string;
};

const PrintIncident: FC<PrintIncidentProps> = ({
  dispatchPrintFROI = false,
  incidentId,
  stateCode,
  claimantName,
  setDispatchPrintFROI,
}) => {
  const { responseGet: responseGetIncidentJSON, dispatchGet } =
    useApiGet<IncidentMergeFieldsAndTemplateNameDto>(
      `api/Incident/GetIncidentJSON?incidentId=${incidentId}&stateCode=${stateCode}`
    );

  const { sendMergeFormRequest } = useFormRequest();
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);

  const printFROI = (
    templateNameAndIncidentJSON?: IncidentMergeFieldsAndTemplateNameDto
  ) => {
    if (
      templateNameAndIncidentJSON !== undefined &&
      templateNameAndIncidentJSON !== null &&
      !isEmptyValue(templateNameAndIncidentJSON.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.INCIDENT_FORM,
        incidentMergeFields: templateNameAndIncidentJSON.incidentMergeFields,
        templateNameWithExtensionList: [
          templateNameAndIncidentJSON.templateName,
        ],
        customFileName: `${
          templateNameAndIncidentJSON.incidentMergeFields.incidentID ?? ""
        } ${claimantName ?? ""} FROI`,
      });
    }
    if (isEmptyValue(templateNameAndIncidentJSON?.templateName)) {
      setDialogConfiguration({
        dialogDescriptionText: "No print template found.",
        open: true,
        optionYesOverrideLabel: "OK",
        onOptionYesEvent: () => setDialogConfiguration(null),
      });
    }
    setDispatchPrintFROI?.(false);
  };

  useEffect(() => {
    if (dispatchPrintFROI === true) {
      dispatchGet();
    }
  }, [dispatchPrintFROI]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetIncidentJSON)) {
      printFROI(responseGetIncidentJSON.axiosResponse?.data);
    }
  }, [responseGetIncidentJSON]);

  return (
    <DialogConfirmation
      id="id-dialog-confirmation-claim-landing"
      name="dialog-confirmation-claim-landing"
      {...dialogConfiguration}
      onCloseEvent={() => setDialogConfiguration(null)}
    />
  );
};
export default PrintIncident;
