import { Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Col, Font, Row } from "../../../TrueUI";
import { ssnMask } from "../../../../utilities/stringFunctions";
import { ClaimLandingClaimantDto } from "../../../../dtos/claim-landing-claimant-dto";
import InformationContainer from "../../../TrueUI/Containers/InformationContainer";
import { getClaimDetailsTabURLWithClaimNumber } from "../../../../utilities/queryStringsHashFunctions";
import Link from "../../../TrueUI/Typography/Link";
import { ClaimDetailsTabEnums } from "../../../../dtos/claim-details-tab-enums";
import { useApiGet, usePermissions } from "../../../../hooks";
// import { IncidentMergeFieldsAndTemplateNameDto } from "../../../../dtos/incident-merge-fields-and-template-name-dto";
import { ClaimMergeFieldsAndTemplateNameDto } from "../../../../dtos/claim-merge-fields-and-template-name-dto";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { useNavigate } from "react-router";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import PrintIncident from "../../../Incident/PrintIncident";

type ClaimLandingClaimantProps = {
  claimLandingClaimantData?: ClaimLandingClaimantDto;
  insuredId: number;
};

const ClaimLandingClaimant: FC<ClaimLandingClaimantProps> = ({
  claimLandingClaimantData,
  insuredId,
}) => {
  const navigate = useNavigate();

  const hasClaimsManagementPermissions = usePermissions([
    PermissionsEnums.TRUE_ADMIN,
    PermissionsEnums.CLAIMS_ADJUSTER,
    PermissionsEnums.CLAIMS_MANAGER,
    PermissionsEnums.CLAIMS_ADMIN,
  ])?.hasPermission;

  const { sendMergeFormRequest } = useFormRequest();
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const [dispatchPrintFROI, setDispatchPrintFROI] = useState<boolean>(false);

  const {
    responseGet: responseGetSummaryToPrint,
    dispatchGet: dispatchGetSummaryToPrint,
  } = useApiGet<ClaimMergeFieldsAndTemplateNameDto>(
    `api/Claims/GetSummaryToPrint?claimId=${
      claimLandingClaimantData?.claimId ?? 0
    }`
  );

  const printSummary = (
    templateNameAndClaimJSON?: ClaimMergeFieldsAndTemplateNameDto
  ) => {
    if (
      templateNameAndClaimJSON !== undefined &&
      templateNameAndClaimJSON !== null &&
      !isEmptyValue(templateNameAndClaimJSON.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.CLAIM_FORM,
        claimMergeFields: templateNameAndClaimJSON.claimMergeFields,
        templateNameWithExtensionList: [templateNameAndClaimJSON.templateName],
        customFileName: `${templateNameAndClaimJSON.claimMergeFields.claimNumber} ${claimLandingClaimantData?.fullName} Summary`,
      });
    }
    if (isEmptyValue(templateNameAndClaimJSON?.templateName)) {
      setDialogConfiguration({
        dialogDescriptionText: "No print template found.",
        open: true,
        optionYesOverrideLabel: "OK",
        onOptionYesEvent: () => setDialogConfiguration(null),
      });
    }
  };

  const mailToFromLink = (email?: string) => {
    if (isEmptyValue(email)) {
      setDialogConfiguration({
        dialogDescriptionText: "No Email found.",
        open: true,
        optionYesOverrideLabel: "OK",
        onOptionYesEvent: () => setDialogConfiguration(null),
      });
    } else {
      const mailLink = document.createElement("a");
      const constructedHref = `mailto:${email}?subject=RE: Claim Number ${claimLandingClaimantData?.claimNumber}`;
      mailLink.href = constructedHref;
      document.body.appendChild(mailLink);
      mailLink.click();
      document.body.removeChild(mailLink);
    }
  };

  useEffect(() => {
    if (responseGetSummaryToPrint.requestInstanceSuccessful) {
      printSummary(responseGetSummaryToPrint.axiosResponse?.data);
    }
  }, [responseGetSummaryToPrint]);

  return (
    <InformationContainer
      title={
        <Link
          displayValue={"Claimant"}
          linkFontType={"TITLE"}
          name="claimClaimantTitleLink"
          onClick={() =>
            navigate(
              `/insured/${insuredId}${getClaimDetailsTabURLWithClaimNumber(
                ClaimDetailsTabEnums.DETAILS,
                claimLandingClaimantData?.claimNumber
              )}`
            )
          }
        />
      }
    >
      <Row
        verticalMargin="0px"
        horizontalMargin="0px"
        verticalGutter="0px"
        horizontalGutter="5px"
        verticalAlign="flex-start"
        horizontalAlign="flex-start"
        numberOfColumns={15}
      >
        <Col
          breakpoints={{ md: 12, lg: 12, xl: 12 }}
          horizontalGutter="0px"
          horizontalAlign="flex-start"
          verticalAlign="flex-start"
        >
          <Row
            allowWrap
            horizontalAlign="flex-start"
            verticalAlign="flex-start"
            verticalMargin="0px"
            horizontalMargin="0px"
            verticalGutter="0px"
            horizontalGutter="0px"
            numberOfColumns={13}
          >
            <Col
              breakpoints={{ md: 6, lg: 6, xl: 6 }}
              horizontalGutter="0px"
              horizontalAlign="flex-start"
            >
              <Stack spacing={"5px"} textAlign={"left"}>
                <Font
                  whiteSpace="normal"
                  textAlign="start"
                  name="claimantNameLabel"
                >{`Name - ${claimLandingClaimantData?.fullName ?? ""}`}</Font>
                <Font
                  whiteSpace="normal"
                  textAlign="start"
                  name="claimantDOBLabel"
                >{`DOB - ${claimLandingClaimantData?.dateOfBirth ?? ""}`}</Font>
                <Font
                  whiteSpace="normal"
                  textAlign="start"
                  name="claimantSSNLabel"
                >{`SSN - ${ssnMask(
                  claimLandingClaimantData?.ssn ?? ""
                )}`}</Font>
              </Stack>
            </Col>
            <Col
              breakpoints={{ md: 7, lg: 7, xl: 7 }}
              horizontalGutter="0px"
              horizontalAlign="flex-start"
            >
              <Stack textAlign={"left"} spacing={"5px"}>
                <Font
                  whiteSpace="normal"
                  textAlign="start"
                  name="claimantOccupationLabel"
                >{`Occupation - ${
                  claimLandingClaimantData?.occupation ?? ""
                }`}</Font>
                <Font
                  whiteSpace="normal"
                  textAlign="start"
                  name="claimantClassCodeLabel"
                >
                  {`Class Code - ${claimLandingClaimantData?.classCode ?? ""}`}
                </Font>
                <Font
                  whiteSpace="normal"
                  textAlign="start"
                  name="claimantStatusHireDateLabel"
                >
                  {`Status & Hire Date - ${
                    claimLandingClaimantData?.statusAndHireDate ?? ""
                  }`}
                </Font>
              </Stack>
            </Col>
          </Row>
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }} horizontalAlign="flex-start">
          <Stack textAlign={"left"} spacing={"5px"}>
            <Link
              linkFontType={"SMALL_TITLE"}
              displayValue={"+ Print FROI"}
              onClick={() => {
                if (claimLandingClaimantData?.incidentId)
                  setDispatchPrintFROI(true);
              }}
              name="claimantPrintFroiLink"
              underline="always"
            />
            <Link
              linkFontType={"SMALL_TITLE"}
              displayValue={"+ Print Summary"}
              onClick={() => dispatchGetSummaryToPrint()}
              name="claimantPrintSummaryLink"
              underline="always"
            />
            <Link
              linkFontType={"SMALL_TITLE"}
              displayValue={"+ Email Insured"}
              disabled={!hasClaimsManagementPermissions}
              name="claimantEmailInsuredLink"
              onClick={() =>
                mailToFromLink(claimLandingClaimantData?.insuredEmail)
              }
              underline="always"
            />
            <Link
              linkFontType={"SMALL_TITLE"}
              displayValue={"+ Email Claimant"}
              disabled={!hasClaimsManagementPermissions}
              name="claimantEmailClaimantLink"
              onClick={() =>
                mailToFromLink(claimLandingClaimantData?.claimantEmail)
              }
              underline="always"
            />
          </Stack>
        </Col>
      </Row>
      <DialogConfirmation
        id="id-dialog-confirmation-claim-landing"
        name="dialog-confirmation-claim-landing"
        {...dialogConfiguration}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
      <PrintIncident
        dispatchPrintFROI={dispatchPrintFROI}
        setDispatchPrintFROI={setDispatchPrintFROI}
        incidentId={claimLandingClaimantData?.incidentId ?? 0}
        stateCode={claimLandingClaimantData?.jurisdictionState ?? ""}
        claimantName={claimLandingClaimantData?.fullName ?? ""}
      />
    </InformationContainer>
  );
};

export default ClaimLandingClaimant;
