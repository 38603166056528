import { FC, useEffect, useState } from "react";
import {
  EndorsementStateGeneralProps,
  ExposureAndPremiumFooterAndHeaderUIProps,
} from "../EndorsementForm/EndorsementTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useRecoilValue } from "recoil";
import { TriggerPolicyQuoteUpdateAtom } from "../hooks/usePolicyQuoteTriggerComponent";
import {
  getPayrollAndPremiumPercentage,
  getTotalPayrollSurchargesAndFeesAndPremiumForExposureHeader,
} from "../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import { Font, FontBold, Ticker } from "../../../TrueUI";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import style from "../EndorsementForm/Endorsement.module.css";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { getPaymentPremiumLabel } from "./ExposureAndPremiumUtils";
import { isCurrentStateTheGoverningState } from "../PolicyCalculator/PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import Link from "../../../TrueUI/Typography/Link";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";

const ExposurePremiumStateHeader: FC<EndorsementStateGeneralProps> = ({
  tabKey,
  stateName,
  stateCode,
  effectiveDate,
  expirationDate,
  policyFormType = PolicyFormTypeEnum.ENDORSEMENT_FORM,
  readOnly,
  deleteState,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const isAudit = policyFormType === PolicyFormTypeEnum.FINAL_AUDIT_FORM;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const [exposureHeaderUI, setExposureHeaderUI] =
    useState<ExposureAndPremiumFooterAndHeaderUIProps>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const listenerStateHeaderComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("endorsementExposureTableHeaderComponent")
  );

  const hasExceedsThreshold = () =>
    (exposureHeaderUI?.payrollPercentage ?? 0) >= 10 ||
    (exposureHeaderUI?.premiumPercentage ?? 0) >= 10;

  useEffect(() => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);
    const currentStates = policyQuote?.quote?.states ?? [];
    const previousStates = isAudit
      ? policyQuote?.auditConfiguration?.oldPolicyJSON?.quote?.states ?? []
      : policyQuote?.endorsementConfiguration?.oldPolicyJSON?.quote?.states ??
        [];

    const endorsedCalculations =
      getTotalPayrollSurchargesAndFeesAndPremiumForExposureHeader(
        stateCode ?? "",
        effectiveDate,
        expirationDate,
        currentStates
      );

    const previousCalculations =
      getTotalPayrollSurchargesAndFeesAndPremiumForExposureHeader(
        stateCode ?? "",
        effectiveDate,
        expirationDate,
        previousStates
      );

    const { payrollPercentage, premiumPercentage } =
      getPayrollAndPremiumPercentage(
        endorsedCalculations,
        previousCalculations
      );

    const { isGoverningState, classCodeWithHighestPremium } =
      isCurrentStateTheGoverningState(
        stateCode,
        effectiveDate,
        expirationDate,
        currentStates
      );

    setExposureHeaderUI({
      endorsedPayroll: endorsedCalculations.totalPayroll,
      previousPayroll: previousCalculations.totalPayroll,
      endorsedPremium: endorsedCalculations.totalPremium,
      previousPremium: previousCalculations.totalPremium,
      payrollPercentage: payrollPercentage,
      premiumPercentage: premiumPercentage,
      isGoverningState,
      classCodeWithHighestPremium,
      isExpanded: endorsedCalculations.isExpanded,
    });
  }, [listenerStateHeaderComponent]);

  return (
    <>
      <div
        className={
          exposureHeaderUI?.isExpanded
            ? style.exposure_table_header_container_expanded
            : style.exposure_table_header_container_collapsed
        }
      >
        <div
          className={
            exposureHeaderUI?.isExpanded
              ? style.exposure_header_description_expanded
              : style.footer_header_description_container
          }
        >
          <Link
            linkFontType={
              exposureHeaderUI?.isGoverningState
                ? "BOLD_HEADING_6"
                : "HEADING_6"
            }
            primaryFontColor
            displayValue={stateName}
            containerWidth="auto"
            underline="hover"
          />
          {exposureHeaderUI?.isGoverningState && (
            <div
              className={style.footer_header_calculation_label_container}
              style={{ paddingLeft: "10px" }}
            >
              <Font>{`Gov. CC: ${exposureHeaderUI?.classCodeWithHighestPremium}`}</Font>
            </div>
          )}
        </div>
        {exposureHeaderUI?.isExpanded === true ? (
          <div className={style.footer_header_calculation_label_container}>
            <Font>
              {`${FormattingDate(effectiveDate)} - ${FormattingDate(
                expirationDate
              )}`}
            </Font>
          </div>
        ) : (
          <>
            <div className={style.footer_header_calculation_label_container}>
              <FontBold>{getPaymentPremiumLabel(isAudit, "PRE-PAY")}</FontBold>
            </div>
            <div className={style.footer_header_calculation_container}>
              <FontBold
                name={`exposure-header-previous-payroll-${stateCode}-${effectiveDate}-${expirationDate}`}
              >
                {exposureHeaderUI?.previousPayroll.toLocaleString() ?? ""}
              </FontBold>
            </div>
            <div
              className={`${style.footer_header_calculation_label_container} ${style.footer_header_margin_left}`}
            >
              <FontBold>{getPaymentPremiumLabel(isAudit, "END-PAY")}</FontBold>
            </div>
            <div className={style.footer_header_calculation_container}>
              <Ticker
                id={`id-exposure-header-endorsed-payroll-${stateCode}-${effectiveDate}-${expirationDate}`}
                name={`exposure-header-endorsed-payroll-${stateCode}-${effectiveDate}-${expirationDate}`}
                value={exposureHeaderUI?.endorsedPayroll ?? -1}
                originalValue={exposureHeaderUI?.previousPayroll ?? -1}
                showArrowOnly
                addIconSpace={hasExceedsThreshold()}
              />
            </div>
            <div
              className={`${style.footer_header_calculation_label_container} ${style.footer_header_margin_left}`}
            >
              <FontBold>{getPaymentPremiumLabel(isAudit, "PRE-PRE")}</FontBold>
            </div>
            <div className={style.footer_header_calculation_container}>
              <FontBold
                name={`exposure-header-previous-premium-${stateCode}-${effectiveDate}-${expirationDate}`}
              >
                {exposureHeaderUI?.previousPremium.toLocaleString() ?? ""}
              </FontBold>
            </div>
            <div
              className={`${style.footer_header_calculation_label_container} ${style.footer_header_margin_left}`}
            >
              <FontBold>{getPaymentPremiumLabel(isAudit, "END-PRE")}</FontBold>
            </div>
            <div className={style.footer_header_calculation_container}>
              <Ticker
                id={`id-exposure-header-endorsed-premium-${stateCode}-${effectiveDate}-${expirationDate}`}
                name={`exposure-header-endorsed-premium-${stateCode}-${effectiveDate}-${expirationDate}`}
                value={exposureHeaderUI?.endorsedPremium ?? -1}
                originalValue={exposureHeaderUI?.previousPremium ?? -1}
                addIconSpace={hasExceedsThreshold()}
              />
            </div>
          </>
        )}
      </div>
      <div className={style.header_delete_state_container}>
        <IconButton
          aria-label="save"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setIsDialogOpen(true);
          }}
          true-element={"option-delete-icon"}
          title="Delete"
          disabled={readOnly}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
      <DialogConfirmation
        id="id_state_delete_confirmation"
        name="state_delete_confirmation"
        dialogDescriptionText="Are you sure you want to delete this state?"
        open={isDialogOpen}
        onOptionYesEvent={() => {
          deleteState(stateCode, effectiveDate, expirationDate);
          setIsDialogOpen(false);
        }}
        onOptionNoEvent={() => setIsDialogOpen(false)}
        onCloseEvent={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default ExposurePremiumStateHeader;
