import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  RefreshInsuredHeaderAtom,
} from "../../InsuredAtoms";
import StyleBox from "../../../TrueUI/StyleBox/StyleBox";
import PremiumBreakdown from "./PremiumBreakdown";
import {
  PolicyQuoteInsuredAndActiveSetterProps,
  PolicyBindInstructionUIProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  calculatedBilledDepositAmount,
  constants,
  getAtomUpdatedByEndorsementForms,
  getBindInstructionsStatesByPolicyQuote,
  getNewAtomValueByBindInstructions,
} from "./BindInstructionUtils";
import Row, { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import PolicyQuoteBottomButtons from "../PolicyQuoteForm/PolicyQuoteBottomButtons";
import {
  INSURED_ATOM_KEY,
  INSURED_BODY_SECTION,
} from "../../../../utilities/queryStringsHash";
import { useApiGet, useApiPost, usePermissions } from "../../../../hooks";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { Button, Font } from "../../../TrueUI";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import { bindPolicyButtonStyle } from "./BindInstructionStyles";
import { validatePreviousSections } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import {
  updateInsuredAtom,
  updateInsuredAtomMultiTarget,
  updatePolicyQuoteInformation,
  updatePolicyQuoteInformationMultiTarget,
} from "../updatesPolicyQuoteFunctions";
import {
  getPolicyQuote,
  policyInformationReset,
} from "../PolicyQuoteForm/PolicyQuoteUtils";
import { PolicyQuoteStatusEnum } from "../../../../dtos/policy-quote-status-enum";
import { PolicyStatusEnums } from "../../../../dtos/policy-status-enums";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { getAtomUpdatedByBindPolicy } from "../PolicyQuoteInformation/PolicyInformation/InformationUtils";
import ModalBindPolicy from "./ModalBindPolicy";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { getQueryStringsURL } from "../../../../utilities/URLUtilities_OBSOLETE";
import { SubSideNavItemDestinationsEnum } from "../../../../dtos/sub-side-nav-item-destinations-enum";
import { evaluateRule } from "../PolicyValidation/ValidationEngineFunctions";
import { AlertProps } from "../../../TrueUI/Alerts/Alert";
import { AlertEnums } from "../../../../dtos/alert-enums";
import ProgramValidationForAlerts from "../PolicyValidation/ProgramValidationForAlerts";

const BindInstructionsMain: FC<PolicyQuoteInsuredAndActiveSetterProps> = ({
  insuredId,
  tabKey,
  quoteStatus,
  setActiveSection,
  readOnly,
}) => {
  const navigate = useNavigate();
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const hasPermission = usePermissions([6, 7]);
  const [infoComplete, setInfoComplete] = useState<boolean>(false);

  const [showBindPolicyModal, setShowBindPolicyModal] = useState(false);
  const onCloseBindPolicyModal = () => setShowBindPolicyModal(false);

  const [bindPolicyErrors, setBindPolicyErrors] = useState<string | null>(null);
  const [bindInstructionUI, setBindInstructionUI] =
    useState<PolicyBindInstructionUIProps>();
  const [areEndorsementsSaved, setAreEndorsementsSaved] =
    useState<boolean>(false);
  const [hasValidation, setHasValidation] = useState<boolean>(false);
  const listenerPaymentScheduleTable = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("paymentScheduleTableComponent")
  );
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const setRefreshInsuredHeader = useSetRecoilState(RefreshInsuredHeaderAtom);
  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();
  const [readyToBind, setReadyToBind] = useState<boolean>(false);
  const {
    responseGet: responseGetEndorsements,
    dispatchGet: dispatchGetEndorsements,
  } = useApiGet<ProgramEndorsementBlob[]>(
    "api/QuotePolicy/GetProgramEndorsement"
  );
  const {
    responsePost: responseBindPolicy,
    validatorErrorResponse: validatorErrorBindPolicy,
    dispatchPost: dispatchBindPolicy,
  } = useApiPost<PolicyBlob>(
    "api/QuotePolicy/SavePolicyQuoteFromBindPolicy",
    getAtom()?.policyQuoteInformation?.policyQuote
  );

  const returnToHistoryTable = () => {
    const atomValue = getAtom();
    const newAtomValue = updateInsuredAtom(
      atomValue ?? {},
      policyInformationReset
    );
    setAtom(newAtomValue);
    setPolicyQuoteTriggers(["policyQuoteHeaderComponent"]);

    const historyHashes = getQueryStringsURL([
      {
        nameOfHash: INSURED_BODY_SECTION,
        valueOfHash: SubSideNavItemDestinationsEnum.HISTORY,
      },
    ]);
    navigate(historyHashes);
  };

  const bindPolicyAction = () => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);

    const policyStatus = policyQuote?.configuration?.policyStatusList.find(
      (status) => status.intValue === PolicyStatusEnums.ISSUED
    );
    const policyQuoteStatus =
      policyQuote?.configuration?.policyQuoteStatusList.find(
        (status) => status.intValue === PolicyQuoteStatusEnum.BOUND
      );

    const newAtomValue = getAtomUpdatedByBindPolicy(
      atomValue,
      policyStatus,
      policyQuoteStatus
    );

    setAtom(newAtomValue);
    setBindPolicyErrors(null);
    setReadyToBind(true);
  };

  useEffect(() => {
    if (readyToBind === true) {
      dispatchBindPolicy();
      setReadyToBind(false);
    }
  }, [readyToBind]);

  const successBody = () => (
    <Row
      {...rowWithNoMarginNorGutter}
      rowDirection="column"
      verticalAlign="flex-start"
    >
      <Font textAlign="start" whiteSpace="normal">
        This policy has been bound successfully.
      </Font>
      <br />
      <Font textAlign="start" whiteSpace="normal">
        The quote is now in an issued status and can no longer be edited.
      </Font>
      <br />
      <Font textAlign="start" whiteSpace="normal">
        Click on the Continue button to close this modal and return to the quote
        to print documents or enter notes.
      </Font>
      <br />
      <Font textAlign="start" whiteSpace="normal">
        Click on the Close Quote button to close this modal and the quote
        completely.
      </Font>
    </Row>
  );

  const errorBody = () => (
    <Row
      {...rowWithNoMarginNorGutter}
      rowDirection="column"
      verticalAlign="flex-start"
    >
      <Font textAlign="start" whiteSpace="normal">
        AN ERROR HAS OCURRED!
      </Font>
      <Font textAlign="start" whiteSpace="normal">
        {bindPolicyErrors}
      </Font>
    </Row>
  );

  const getBanner = (validationResults: AlertProps[]) => {
    if (validationResults.length > 1) {
      const type = validationResults.some(
        (validation) => validation.type === AlertEnums.WARNING
      )
        ? AlertEnums.WARNING
        : AlertEnums.INFORMATION;
      const modalHeight = validationResults.reduce(
        (height, validation, index) => {
          if (index < 6)
            validation.message.length > 141 ? (height += 68) : (height += 45);
          return height;
        },
        0
      );
      const message =
        "More than one warning exists for this quote. Click here for more information";
      const body = () => (
        <ProgramValidationForAlerts
          validationResults={validationResults}
          insuredId={insuredId}
        />
      );

      return [
        {
          type,
          message,
          showCloseIcon: false,
          modalTitle: "Quote Validation Messages",
          modalContent: body(),
          modalHeight: modalHeight + 230,
        } as AlertProps,
      ];
    }
    return validationResults;
  };

  useEffect(() => {
    setHasValidation(false);
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
    const activeSection =
      atomValue?.policyQuoteInformation?.activeSection ??
      QuotePolicySectionEnum.INFORMATION;
    const isValid = validatePreviousSections(atomValue, activeSection);
    if (isValid) {
      const payPlan = policyJSON?.payPlan;
      const depositOverride = payPlan?.depositOverride === 1;

      const depositType = depositOverride
        ? bindInstructionUI?.depositTypeForDepositOverride ?? constants.PERCENT
        : payPlan?.depositType ?? "";

      const { billedDepositAmount, installmentPayment, nonEarningAmount } =
        calculatedBilledDepositAmount(
          payPlan?.depositAmount ?? 0,
          policyJSON?.totalPremium ?? 0,
          payPlan?.numberOfPayment ?? 0,
          payPlan?.depositType === constants.NON_EARNING,
          payPlan?.allEqual ?? false,
          depositType === constants.NON_EARNING
            ? constants.PERCENT
            : depositType
        );

      setBindInstructionUI({
        ...bindInstructionUI,
        effectiveDate: policyJSON?.effectiveDate ?? null,
        expirationDate: policyJSON?.expirationDate ?? null,
        firstPolicyDate: policyJSON?.firstPolicyDate ?? null,
        states: getBindInstructionsStatesByPolicyQuote(
          policyJSON?.quote?.states ?? []
        ),
        billedPremium: policyJSON?.totalPremium ?? null,
        billedFees: policyJSON?.totalFees ?? null,
        billedDepositAmount: billedDepositAmount ?? null,
        nonEarningAmount: nonEarningAmount ?? null,
        numberOfPayments: policyJSON?.payPlan?.numberOfPayment ?? null,
        installmentPaymentAmount: installmentPayment ?? null,
        billingMethod: policyJSON?.bindInstructions?.billingMethod ?? null,
        dueOnDay: policyJSON?.payPlan?.dueOnDay ?? null,
        payPlan: policyJSON?.payPlan ?? null,
        depositRate: policyJSON?.payPlan?.depositRate ?? null,
        depositTypeForDepositOverride:
          policyJSON?.bindInstructions?.depositTypeForDepositOverride ?? null,
        billByLocation: policyJSON?.bindInstructions?.billByLocation ?? null,
        paymentScheduleTable: {
          tableData: policyJSON?.bindInstructions?.scheduleTable ?? null,
        },
        paymentScheduleMultiTable: {
          tableData: policyJSON?.bindInstructions?.scheduleMultiTable ?? null,
          rowChildren:
            policyJSON?.bindInstructions?.multiTableRowChildren ?? null,
        },
      });
      dispatchGetEndorsements();
      const validations =
        policyJSON?.configuration?.programValidationList ?? [];
      const validationResults: AlertProps[] = [];
      validations.map((validation) => {
        if (evaluateRule(validation.validationQuery, policyJSON))
          validationResults.push({
            type: validation.validationType,
            message: validation.validationMessage,
            showCloseIcon: false,
          });
      });
      if (!isEmptyValue(validationResults)) {
        setHasValidation(true);
        const newAtomValue = updatePolicyQuoteInformation(
          atomValue,
          "policyValidations",
          getBanner(validationResults)
        );
        setAtom(newAtomValue);
        setComponentTriggers(["alertAtomComponent"]);
      }
    } else {
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "activeSection",
        QuotePolicySectionEnum.INFORMATION
      );
      setAtom(newAtomValue);
      setActiveSection?.(QuotePolicySectionEnum.INFORMATION);
    }

    return () => {
      const atomValue = getAtom();
      const updatedAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyValidations",
        []
      );
      setAtom(updatedAtomValue);
      setComponentTriggers(["alertAtomComponent"]);
    };
  }, []);

  useEffect(() => {
    if (
      responseGetEndorsements?.requestInstanceSuccessful &&
      !responseGetEndorsements?.isLoading
    ) {
      const atomValue = getAtom();
      const newAtomValueWithBindInstructions =
        getNewAtomValueByBindInstructions(atomValue, bindInstructionUI);

      if (!areEndorsementsSaved) {
        const newAtomValueByEndorsementForms = getAtomUpdatedByEndorsementForms(
          newAtomValueWithBindInstructions,
          responseGetEndorsements.axiosResponse?.data ?? []
        );

        setAtom(newAtomValueByEndorsementForms);
        setAreEndorsementsSaved(true);
      } else if (listenerPaymentScheduleTable !== null) {
        setAtom(newAtomValueWithBindInstructions);
        clearPolicyQuoteTriggers(["paymentScheduleTableComponent"]);
      }
    }
  }, [responseGetEndorsements, listenerPaymentScheduleTable]);

  useEffect(() => {
    if (!isEmptyValue(bindInstructionUI?.payPlan)) {
      setInfoComplete(true);
    }
  }, [bindInstructionUI?.payPlan]);

  useEffect(() => {
    if (
      responseBindPolicy.requestInstanceSuccessful &&
      !conditionHasValue(validatorErrorBindPolicy)
    ) {
      setBindPolicyErrors(null);
      const atomValue = getAtom();
      const policyBlob = responseBindPolicy.axiosResponse?.data ?? null;
      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyQuote", "readOnly"],
        [policyBlob, true]
      );
      const newInsuredAtomValue = updateInsuredAtomMultiTarget(
        newAtomValue,
        ["insuredCode", "insuredStatus"],
        [policyBlob?.insured?.insuredCode, policyBlob?.insured?.insuredStatus]
      );
      setAtom(newInsuredAtomValue);
      setShowBindPolicyModal(true);
      setPolicyQuoteTriggers(["policyQuoteReadOnlyComponent"]);
      setRefreshInsuredHeader({
        insuredCode: policyBlob?.insured?.insuredCode,
        insuredStatus: policyBlob?.insured?.insuredStatus,
      });
      if (
        (atomValue?.policyQuoteInformation?.policyQuote?.program
          ?.portalUserInviteOnBind ?? false) === true
      ) {
        // TODO - Send portal invitation email for the primary contact.
      }
    }
    if (conditionHasValue(validatorErrorBindPolicy)) {
      const errorMsg =
        validatorErrorBindPolicy?.message ??
        validatorErrorBindPolicy?.title ??
        "";
      setBindPolicyErrors(errorMsg);
      setShowBindPolicyModal(true);
      // TODO - Send email to support@experiencetrue.com that a bind failed, support is ready.
    }
  }, [responseBindPolicy]);

  return (
    <div
      id="bind-instructions-main-container"
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      <div style={{ height: "100%", overflow: "hidden" }}>
        <StyleBox
          className="styled-container-bind-instructions"
          width={"100%"}
          margin={0}
          padding={0}
          display="block"
          showBorder={true}
          heightFillAvailable={true}
        >
          {bindInstructionUI !== undefined ? (
            <Row
              {...rowWithNoMarginNorGutter}
              id="id-premium-breakdown-container"
              verticalGutter="10px"
              rowDirection="column"
              rowWidth="90%"
            >
              <PremiumBreakdown
                tabKey={tabKey}
                insuredId={insuredId}
                bindInstructionUI={bindInstructionUI}
                setBindInstructionUI={setBindInstructionUI}
                readOnly={readOnly}
              />
              {hasPermission && infoComplete ? (
                <Button
                  size="large"
                  sx={bindPolicyButtonStyle(theme)}
                  onClick={() => bindPolicyAction()}
                  isDisabled={readOnly || hasValidation}
                >
                  bind policy
                </Button>
              ) : null}
            </Row>
          ) : null}
        </StyleBox>
      </div>
      <ModalBindPolicy
        showModal={showBindPolicyModal}
        onClose={onCloseBindPolicyModal}
        onCloseQuote={returnToHistoryTable}
        hasError={!isEmptyValue(bindPolicyErrors)}
        modalBody={
          !isEmptyValue(bindPolicyErrors) ? errorBody() : successBody()
        }
      />
      <PolicyQuoteBottomButtons
        tabKey={tabKey}
        insuredId={insuredId}
        policySection={QuotePolicySectionEnum.BIND_INSTRUCTIONS}
        quoteStatus={quoteStatus}
        setActiveSection={setActiveSection}
      />
    </div>
  );
};

export default BindInstructionsMain;
