import { FC } from "react";
import { FontBold } from "../../../../../../TrueUI";
import style from "./BillDetails.module.css";
import { BillDetailsWrapperProps } from "./TypesPaymentBillDetails";
import FormDiagnosis from "./DiagnosisComponents/FormDiagnosis";
import FormBillIndividualDetails from "./BillIndividualDetails/FormBillIndividualDetails";
import TableWrapperProceduresServicesSupplies from "./BillIndividualDetails/TableWrapperProceduresServicesSupplies";
import FooterPaymentBillDetails from "./FooterPaymentBillDetails";

const BillDetailsWrapper: FC<BillDetailsWrapperProps> = ({
  claimId,
  payment,
}) => {
  return (
    <div className={style.billing_details_main_container}>
      <div>
        <div className={style.billing_details_wrapper_row}>
          <FontBold fontType={"BOLD_TITLE"}>DIAGNOSIS</FontBold>
          <FormDiagnosis />
        </div>

        <div className={style.billing_details_wrapper_row}>
          <FontBold fontType={"BOLD_TITLE"}>
            PROCEDURES, SERVICES, OR SUPPLIES
          </FontBold>
          <FormBillIndividualDetails
            payToClaimant={payment?.payToClaimant ?? false}
            payeeId={payment?.payeeId ?? 0}
            claimId={claimId ?? 0}
            paymentId={payment?.paymentId ?? 0}
          />
          <br />
          <TableWrapperProceduresServicesSupplies
            paymentId={payment?.paymentId ?? 0}
          />
        </div>
        <FooterPaymentBillDetails />
      </div>
    </div>
  );
};

export default BillDetailsWrapper;
