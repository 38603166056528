import {
  Mp3FileIcon,
  DiscFileIcon,
  VideoFileIcon,
  JPGFileIcon,
  ZipFileIcon,
  TXTFileIcon,
  DocFileIcon,
  PDFFileIcon,
  MailFileIcon,
  PHPFileIcon,
  PPTFileIcon,
  XLSFileIcon,
  WordFileIcon,
} from "../Icons/CustomIcons";

export const getIconByExt = (fileName: string) => {
  const fileWithExt = fileName.split(".");
  const lastIndexForSplittedFileName = fileWithExt.length - 1;
  const fileExt = fileWithExt?.[lastIndexForSplittedFileName];
  switch (fileExt) {
    case "aac":
    case "asf":
    case "cda":
    case "mid":
    case "midi":
    case "wav":
    case "wma":
    case "mp3":
      return <Mp3FileIcon />;
    case "accdb":
    case "mda":
    case "mdb":
    case "mde":
    case "one":
    case "pub":
    case "vsd":
    case "vst":
      return <DiscFileIcon />;
    case "asx":
    case "avi":
    case "mov":
    case "mp2":
    case "mpa":
    case "mpe":
    case "mpeg":
    case "mpg":
    case "wmv":
      return <VideoFileIcon />;
    case "bmp":
    case "dib":
    case "eps":
    case "gif":
    case "jfif":
    case "jpe":
    case "png":
    case "psd":
    case "tif":
    case "tiff":
    case "jpeg":
    case "jpg":
      return <JPGFileIcon />;
    case "cab":
    case "tar":
    case "zip":
      return <ZipFileIcon />;
    case "csv":
    case "log":
    case "txt":
      return <TXTFileIcon />;
    case "docx":
    case "dot":
    case "dotx":
    case "doc":
      return <DocFileIcon />;
    case "edn":
    case "fdp":
    case "pdf":
      return <PDFFileIcon />;
    case "eml":
    case "msg":
      return <MailFileIcon />;
    case "htm":
    case "html":
    case "tml":
    case "xml":
      return <PHPFileIcon />;
    case "pot":
    case "potx":
    case "pps":
    case "pptx":
    case "ppt":
      return <PPTFileIcon />;
    case "xlt":
    case "xltx":
    case "xlsx":
    case "xls":
      return <XLSFileIcon />;
    case "rtf":
    default:
      return <WordFileIcon />;
  }
};
