import { FC, useEffect, useState } from "react";
import {
  addDays,
  FormattingDate,
  GettingDateWithoutTime,
  StringToDate,
  subtractDays,
} from "../../../utilities/dateFunctions";
import ScheduledTransactionModal, {
  ScheduledTransactionModalConfig,
} from "../../Insured/Billing/Scheduled/TransactionModal/ScheduledTransactionModal";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import { SelectOptions } from "../../../dtos/select-options";
import { FromEnumToArray } from "../../../utilities/enumFunctions";
import { PayAccountTypeEnum } from "../../../dtos/pay-account-type-enum";
import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { addAllOption } from "../../../utilities/selectFunctions";
import BaseGridInputDateFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridInputDateFilter";
import IconButton from "../../TrueUI/Buttons/IconButton";
import OpenIcon from "@mui/icons-material/Launch";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

type ScheduledTransactionsGridProps = {
  programId: number;
};

const ScheduledTransactionsGrid: FC<ScheduledTransactionsGridProps> = ({
  programId,
}) => {
  const today = GettingDateWithoutTime(new Date());
  const defaultFromDate = FormattingDate(subtractDays(today, 1), "YYYY-MM-DD");
  const defaultToDate = FormattingDate(addDays(today, 6), "YYYY-MM-DD");
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [modalConfiguration, setModalConfiguration] =
    useState<ScheduledTransactionModalConfig>({
      isOpen: false,
      isOpenAchPaymentModal: false,
      isOpenDialogConfirmation: false,
    });

  const name = "billing_scheduled_transactions_grid";
  const getURL = `api/BillingTransaction/GetBillingScheduledTransactionsGrid?programId=${programId}&fromDate=${fromDate}&toDate=${toDate}`;

  const typeOptions: Partial<SelectOptions>[] = FromEnumToArray(
    PayAccountTypeEnum
  ).map((type) => ({
    displayName: type.key,
    stringValue: type.value.toString(),
  }));

  const onEditTransaction = (rowData: any) => {
    setModalConfiguration({
      isOpen: true,
      isOpenAchPaymentModal: false,
      isOpenDialogConfirmation: false,
      transactionId: rowData.TransactionId,
      insuredId: parseInt(rowData.InsuredId),
      rowKey: rowData._row_key,
      rowData: rowData,
    });
  };

  const actionsForRows = (rowData: any) => {
    return (
      <IconButton
        aria-label="expand row"
        onClick={() => onEditTransaction(rowData.row)}
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    );
  };

  const tableConfiguration: BaseGridProperties = {
    name,
    getURL,
    useManuallyReloadParameters: true,
    columnOptions: [
      { fieldName: "ScheduledDate", width: 15 },
      { fieldName: "AccountType", width: 20 },
      { fieldName: "InsuredName", width: 30 },
      { fieldName: "TransactionAmount", width: 15 },
      { fieldName: "CreateByAt", width: 20 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    toolbarOptions: {
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
      showAddButton: false,
    },
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => actionsForRows(actionOptions),
      },
    },
    events: {
      addEventOverrideCallback: () => alert("Waiting for instructions"),
    },
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          resetOnManuallyReload: true,
          id: "accountType",
          columnNames: ["AccountType"],
          labelText: "Type",
          defaultValue: "all",
          optionsOverride: addAllOption(typeOptions),
          options,
        }),
      (options) =>
        BaseGridInputDateFilter({
          usingExternalFiltering: true,
          id: "fromDate",
          label: "Show Records From:",
          inputWidth: "336px",
          defaultValue: StringToDate(fromDate),
          options,
          onChange: (value) => {
            const formattedNewDate = FormattingDate(value, "YYYY-MM-DD");
            setFromDate(formattedNewDate);
          },
        }),
      (options) =>
        BaseGridInputDateFilter({
          usingExternalFiltering: true,
          id: "toDate",
          label: "To:",
          inputWidth: "210px",
          defaultValue: StringToDate(toDate),
          options,
          onChange: (value) => {
            const formattedNewDate = FormattingDate(value, "YYYY-MM-DD");
            setToDate(formattedNewDate);
          },
        }),
    ],
  };

  const { manuallyReloadParameters } = useBaseGrid(tableConfiguration);

  const onSaveSuccessful = () => {
    manuallyReloadParameters();
  };

  const onDeleteSuccessful = () => {
    if (conditionHasValue(modalConfiguration.transactionId)) {
      manuallyReloadParameters();
    }
  };

  useEffect(() => {
    manuallyReloadParameters();
  }, [fromDate, toDate]);

  return (
    <div id={"notes_grid_container"} style={{ height: "100%" }}>
      <BaseGrid name={name} />
      <ScheduledTransactionModal
        configuration={modalConfiguration}
        closeModal={(close) =>
          setModalConfiguration({
            ...modalConfiguration,
            isOpen: close,
            transactionId: null,
            insuredId: null,
          })
        }
        onSaveSuccessful={onSaveSuccessful}
        onDeleteSuccessful={onDeleteSuccessful}
      />
    </div>
  );
};

export default ScheduledTransactionsGrid;
