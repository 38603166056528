import { FC, useEffect, useState } from "react";
import { Col, Font, Input, InputDate, Modal, Row } from "../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import FontError from "../../../../TrueUI/Typography/FontError";
import style from "./PolicyKeyFieldsModalStyles.module.css";
import {
  FormattingDate,
  areDatesEquals,
  isDateAfterOrEqualDate,
  isDateBetweenDatesIncludingRanges,
} from "../../../../../utilities/dateFunctions";
import {
  PolicyKeyFieldsFormData,
  PolicyKeyFieldsModalProps,
  getPolicyTerm,
} from "./PolicyKeyFieldsUtil";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { endorsementEnginePolicyKeyFields } from "../EndorsementEngineUtils";
import { updatePolicyQuoteInformation } from "../../updatesPolicyQuoteFunctions";
import { usePolicyQuoteTriggerComponent } from "../../hooks/usePolicyQuoteTriggerComponent";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../../utilities/conditionalSupportFunctions";

const REQUIRED_FIELD = "This field is required";

const PolicyKeyFieldsModal: FC<PolicyKeyFieldsModalProps> = ({
  tabKey,
  modalConfiguration,
  readonly,
  closeModal,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  const defaultFormData = () => {
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote ?? {};
    return {
      policyNumber: policyJSON?.policyNumber ?? "",
      effectiveDate: policyJSON?.effectiveDate ?? null,
      expirationDate: policyJSON?.expirationDate ?? null,
      policyTerm:
        getPolicyTerm(
          policyJSON?.effectiveDate ?? new Date(),
          modalConfiguration?.policyTermList ?? [],
          policyJSON?.expirationDate
        )?.description ?? "",
    };
  };

  const [errorDetails, setErrorDetails] = useState<any>(null);

  const [formData, setFormData] =
    useState<PolicyKeyFieldsFormData>(defaultFormData);

  const isPolicyNumberChanged = () =>
    defaultFormData().policyNumber !== formData.policyNumber;

  const isEffectiveDateChanged = () =>
    !areDatesEquals(defaultFormData().effectiveDate, formData.effectiveDate);

  const isExpirationDateChanged = () =>
    !areDatesEquals(defaultFormData().expirationDate, formData.expirationDate);

  const someError = (obj: any) =>
    !isEmptyValue(obj) && Object.values(obj).some((val) => val !== null);

  const setFormDataValue = (target: string, value: string | Date | null) => {
    setErrorDetails({
      ...errorDetails,
      [target]: conditionHasValue(value) ? null : [REQUIRED_FIELD],
    });
    setFormData({
      ...formData,
      [target]: value,
    });
  };

  const verifyValidEffectiveDate = (date: Date) => {
    if (
      conditionHasValue(date) &&
      !isDateBetweenDatesIncludingRanges(
        date,
        modalConfiguration?.policyPeriod?.firstDate ?? new Date(),
        modalConfiguration?.policyPeriod?.lastDate ?? new Date()
      )
    ) {
      setErrorDetails({
        ...errorDetails,
        effectiveDate: ["Date not allowed per policy period"],
      });
    }
    if (
      conditionHasValue(date) &&
      isDateAfterOrEqualDate(date, formData.expirationDate)
    ) {
      setErrorDetails({
        ...errorDetails,
        effectiveDate: ["Date must not exceed the expiration date"],
      });
    }
  };

  const saveModalEvent = () => {
    if (!someError(errorDetails)) {
      const atomValue = getAtom();
      const policyJSON = atomValue?.policyQuoteInformation?.policyQuote ?? {};
      const updatedPolicyJSON = endorsementEnginePolicyKeyFields(
        policyJSON,
        formData,
        isPolicyNumberChanged(),
        isEffectiveDateChanged(),
        isExpirationDateChanged()
      );

      const updatedAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        updatedPolicyJSON
      );
      setAtom(updatedAtomValue);
      closeModalEvent();
    }
  };

  const closeModalEvent = () => {
    closeModal();
    setErrorDetails(null);
    setPolicyQuoteTriggers(["endorsementPolicyKeyFieldsSave"]);
  };

  const cancelModalEvent = () => {
    closeModalEvent();
    setFormData(defaultFormData);
  };

  useEffect(() => {
    const newPolicyTerm = getPolicyTerm(
      formData.effectiveDate ?? new Date(),
      modalConfiguration?.policyTermList ?? [],
      formData.expirationDate
    );
    setFormData({ ...formData, policyTerm: newPolicyTerm?.description ?? "" });
  }, [formData.effectiveDate, formData.expirationDate]);

  return (
    <Modal
      size="sm"
      id={"policy_key_fields_change"}
      title={"Policy Key Fields Change"}
      open={modalConfiguration.isOpen}
      cancelButtonWithConfirmation
      cancelEvent={cancelModalEvent}
      saveOverrideLabel="OK"
      showCancelTextButton
      showCloseButton
      cancelButtonConfirmationText={
        "Are you sure you want to discard the changes?"
      }
      saveEvent={saveModalEvent}
    >
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 12 }}>
          <Font whiteSpace="normal" textAlign="start">
            Make changes to the policy number and/or policy dates here. They
            will be reported as a Key Fields endorsement (06) and not a policy
            replacement (14)
          </Font>
        </Col>
      </Row>
      <Row
        {...rowWithNoMarginNorGutter}
        rowDirection="column"
        verticalGutter="10px"
      >
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            id="policy-number"
            name="policy-number"
            readOnly={
              readonly === true
                ? readonly
                : !modalConfiguration.isPolicyNumberCheck
            }
            className={
              !modalConfiguration.isPolicyNumberCheck
                ? style.true_input_disabled
                : ""
            }
            label="Policy Number"
            labelFontType="BOLD_CAPTION"
            labelPosition="top"
            value={formData.policyNumber}
            onChangeRawValue={(value) =>
              setFormDataValue("policyNumber", value)
            }
            errorMessage={errorDetails?.policyNumber}
          />
        </Col>
        <Col>
          <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
            <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
              <InputDate
                tabIndex={30}
                id="id-effective-date"
                name="name-effective-date"
                key={"effectiveDate"}
                label="Effective Date"
                value={formData.effectiveDate}
                onChangeRawValue={(value) => {
                  setFormDataValue("effectiveDate", value);
                  verifyValidEffectiveDate(value);
                }}
                readOnly={
                  readonly === true
                    ? readonly
                    : !modalConfiguration.isPolicyEffectiveDateCheck
                }
                className={
                  !modalConfiguration.isPolicyEffectiveDateCheck
                    ? style.true_input_disabled
                    : ""
                }
                minNumericValue={new Date(
                  modalConfiguration?.policyPeriod?.firstDate ?? ""
                ).getTime()}
                maxNumericValue={new Date(
                  modalConfiguration?.policyPeriod?.lastDate ?? ""
                ).getTime()}
                errorMessage={errorDetails?.effectiveDate}
              />
            </Col>
            <Col
              breakpoints={{ md: 4, lg: 4, xl: 4 }}
              horizontalAlign="flex-start"
              verticalAlignSelf="center"
            >
              {isEffectiveDateChanged() && (
                <FontError
                  className={style.policy_previous_value}
                >{`Previous value: ${FormattingDate(
                  defaultFormData().effectiveDate
                )}`}</FontError>
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
            <Col breakpoints={{ md: 5, lg: 5, xl: 5 }}>
              <InputDate
                tabIndex={31}
                id="id-expiration-date"
                name="name-expiration-date"
                key={"expirationDate"}
                label="Expiration Date"
                value={formData.expirationDate}
                onChangeRawValue={(value) =>
                  setFormDataValue("expirationDate", value)
                }
                readOnly={
                  readonly === true
                    ? readonly
                    : !modalConfiguration.isPolicyExpirationDateCheck
                }
                className={
                  !modalConfiguration.isPolicyExpirationDateCheck
                    ? style.true_input_disabled
                    : ""
                }
                errorMessage={errorDetails?.expirationDate}
              />
            </Col>
            <Col
              breakpoints={{ md: 7, lg: 7, xl: 7 }}
              verticalAlignSelf="center"
            >
              <Input
                id="id-policy-term"
                name="policyTerm"
                label="Policy Term"
                readOnly
                className="policy_info_disabled"
                value={formData.policyTerm}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default PolicyKeyFieldsModal;
