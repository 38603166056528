import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../../TrueUI";
import { ModalBillingItemProps } from "./TypesBillingItem";
import FormBillingItemWrapper from "./FormBillingItemWrapper";
import { BillingItemDto } from "../../../../../../dtos/billing-item-dto";
import { isDateAfterOrEqualDate } from "../../../../../../utilities/dateFunctions";
import { PermissionsEnums } from "../../../../../../dtos/permissions-enums";
import { useApiPost, usePermissions } from "../../../../../../hooks";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";
import { StatusEnums } from "../../../../../../dtos/status-enums";

const ModalBillingItem: FC<ModalBillingItemProps> = ({
  modalConfigBillingItem,
  setModalConfigBillingItem,
  currentPolicy,
}) => {
  const addOrEditPermissions = [PermissionsEnums.BILLING_ADMIN];
  const allPermissions = [
    PermissionsEnums.BILLING_PAYMENT,
    PermissionsEnums.BILLING_USER,
    PermissionsEnums.BILLING_ADMIN,
  ];
  const { hasPermission } = usePermissions(addOrEditPermissions);
  const { hasPermission: hasAllPermissions } = usePermissions(allPermissions);
  const [billingItem, setBillingItem] =
    useState<Partial<BillingItemDto> | null>(null);
  const [errorDetails, setErrorDetails] = useState<any>(null);

  const { dispatchPost, responsePost, validatorErrorResponse } =
    useApiPost<BillingItemDto>("api/Activity/SaveBillingItem", {
      ...billingItem,
      insuredId: modalConfigBillingItem?.insuredId,
      transactionId: billingItem?.transactionId ?? 0,
    });

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setModalConfigBillingItem({
        ...modalConfigBillingItem,
        refreshBillingActivityTable: true,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  const saveBillingItem = () => {
    dispatchPost();
  };

  const deleteBillingItem = () => {
    setBillingItem({
      ...billingItem,
      transactionStatus: StatusEnums.DELETED,
    });
  };

  useEffect(() => {
    if (billingItem?.transactionStatus === StatusEnums.DELETED) {
      dispatchPost();
    }
  }, [billingItem?.transactionStatus]);

  useEffect(() => {
    if (modalConfigBillingItem?.showModalBillingItem === false) {
      setBillingItem(null);
      setErrorDetails(null);
    }
  }, [modalConfigBillingItem?.showModalBillingItem]);

  const closeModalResetInvoiceItem = (isOpen?: boolean) => {
    setModalConfigBillingItem({
      ...modalConfigBillingItem,
      showModalBillingItem: isOpen ?? false,
    });
  };

  const checkIfSaveDisabled = () => {
    if (modalConfigBillingItem?.hasPermission === false) {
      return true;
    }
    if ((billingItem?.paymentId ?? null) !== null) {
      return true;
    }
    return false;
  };

  const checkDeleteIsDisabled = () => {
    const isEditEntry = modalConfigBillingItem?.activityType === "edit_entry";
    const isCurrentDateAfterOrEqual = isDateAfterOrEqualDate(
      new Date(),
      billingItem?.transactionDate ?? new Date()
    );
    if (isEditEntry && isCurrentDateAfterOrEqual === false) {
      return !hasAllPermissions;
    }

    if (hasPermission === false) {
      return true;
    }
    const isNewEntry = modalConfigBillingItem?.activityType === "new_entry";
    if ((billingItem?.paymentId ?? null) !== null) {
      return true;
    }

    if (modalConfigBillingItem?.hasPermission === false) {
      return false;
    }

    return isNewEntry || conditionHasValue(billingItem?.paymentId);
  };
  return (
    <Modal
      id="billing_item_id"
      title="Billing Item"
      open={modalConfigBillingItem?.showModalBillingItem ?? false}
      cancelEvent={() => closeModalResetInvoiceItem()}
      closeEvent={(isOpen) => closeModalResetInvoiceItem(isOpen)}
      deleteEvent={() => deleteBillingItem()}
      deleteDisabled={checkDeleteIsDisabled()}
      saveEvent={() => saveBillingItem()}
      cancelButtonWithConfirmation
      showCloseButton
      showCancelTextButton
      cancelButtonConfirmationText={
        "Are you sure you want to cancel and lose your work?"
      }
      deleteButtonWithConfirmation
      deleteButtonConfirmationText={
        "Are you sure you want to delete this item?"
      }
      saveDisabled={checkIfSaveDisabled()}
    >
      <FormBillingItemWrapper
        insuredId={modalConfigBillingItem?.insuredId ?? -1}
        transactionId={modalConfigBillingItem?.transactionId ?? -1}
        currentPolicy={currentPolicy ?? -1}
        billingItem={billingItem}
        setBillingItem={setBillingItem}
        errorDetails={errorDetails}
        activityType={modalConfigBillingItem?.activityType}
      />
    </Modal>
  );
};

export default ModalBillingItem;
