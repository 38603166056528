import { FC, useEffect, useState } from "react";
import { Button, Col, Input, Row, Switch } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import StyleBox from "../../../TrueUI/StyleBox/StyleBox";
import { inputConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import { usePermissions } from "../../../../hooks";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  getAllTemplateNamesToPrint,
  getDefaultFileName,
} from "./EndorsementDocumentsUtils";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import {
  getJSONDataWithoutConfigurations,
  getPolicyQuote,
} from "../PolicyQuoteForm/PolicyQuoteUtils";
import { ProgramPolicyEndorsementBlob } from "../../../../dtos/program-policy-endorsement-blob";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import { ActivityCategoryEnums } from "../../../../dtos/activity-category-enums";

const columnProps = (
  columns: number,
  equalWidth: boolean,
  displayFlex: boolean
) => ({
  equalWidth: equalWidth,
  displayFlex: displayFlex,
  breakpoints: {
    xs: columns,
    sm: columns,
    md: columns,
    lg: columns,
    xl: columns,
  },
});

export type EndorsementDocumentFileOptionsProps = {
  insuredId: number;
  tabKey: string;
  fileName: string;
  setFileName: (name: string) => void;
  policyDocuments: ProgramPolicyDocumentDto[];
  endorsementDocuments?: ProgramPolicyEndorsementBlob[];
  selectedDocuments?: ProgramEndorsementBlob[];
};

const EndorsementDocumentFileOptions: FC<
  EndorsementDocumentFileOptionsProps
> = ({
  insuredId,
  tabKey,
  fileName,
  setFileName,
  selectedDocuments,
  endorsementDocuments,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const hasPermission = usePermissions([1, 2, 3]);
  const [saveFile, setSaveFile] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);
    const insuredName = policyQuote?.insured?.insuredName ?? "";
    const defaultFileName = getDefaultFileName(insuredName, selectedDocuments);
    setFileName(defaultFileName);
  }, [selectedDocuments]);

  const printDocuments = () => {
    const policyMergeFields = getJSONDataWithoutConfigurations(
      getAtom()?.policyQuoteInformation
    );
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      const { hasInvalidTemplateNames, allTemplateNames } =
        getAllTemplateNamesToPrint(selectedDocuments, endorsementDocuments);

      if (hasInvalidTemplateNames) {
        setDialogOpen(true);
      } else {
        sendMergeFormRequest({
          formType: FormTypeEnum.POLICY_FORM,
          policyMergeFields: policyMergeFields,
          templateNameWithExtensionList: allTemplateNames,
          customFileName: fileName,
        });
      }
    }
  };

  const printAndSaveDocuments = () => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      const { hasInvalidTemplateNames, allTemplateNames } =
        getAllTemplateNamesToPrint(selectedDocuments, endorsementDocuments);

      if (hasInvalidTemplateNames) {
        setDialogOpen(true);
      } else {
        sendMergeFormRequest({
          formType: FormTypeEnum.POLICY_FORM,
          policyMergeFields: getJSONDataWithoutConfigurations(
            getAtom()?.policyQuoteInformation
          ),
          templateNameWithExtensionList: allTemplateNames,
          customFileName: fileName,
          configurationToSaveFile: {
            saveFileToInsured: true,
            fileCategory: "Policy Documents",
            insuredId,
            claimId: null,
            policyId: policyMergeFields.policyID ?? -1,
            activityLogCategory:
              ActivityCategoryEnums.POLICY_POLICY_DOC_PACKET_PRINTED,
            activityLogDescription: !isEmptyValue(
              policyMergeFields.policyNumber
            )
              ? `The policy documents for policy number ${policyMergeFields.policyNumber} were generated`
              : `The policy documents for policy ID ${policyMergeFields.policyID} were generated`,
          },
        });
      }
    }
  };

  const mailDocument = () => {
    const policyMergeFields = getJSONDataWithoutConfigurations(
      getAtom()?.policyQuoteInformation
    );
    if (conditionHasValue(policyMergeFields)) {
      const { hasInvalidTemplateNames, allTemplateNames } =
        getAllTemplateNamesToPrint(selectedDocuments, endorsementDocuments);

      if (hasInvalidTemplateNames) {
        setDialogOpen(true);
      } else {
        sendMergeFormRequest(
          {
            formType: FormTypeEnum.POLICY_FORM,
            policyMergeFields: policyMergeFields,
            templateNameWithExtensionList: allTemplateNames,
            customFileName: fileName,
          },
          "mail"
        );
      }
    }
  };

  return (
    <StyleBox margin={0} padding={0} width={"100%"} showBorder>
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        numberOfColumns={24}
      >
        <Col {...columnProps(3, true, false)} verticalAlignSelf="center">
          <Switch
            {...inputConfiguration("save-file", "saveFile", "Save File")}
            control="checkbox"
            isChecked={saveFile}
            onChangeIsChecked={setSaveFile}
          />
        </Col>
        <Col {...columnProps(17, true, false)} verticalAlignSelf="center">
          <Input
            {...inputConfiguration("file-name", "fileName", "File Name", 100)}
            value={fileName}
            onChangeRawValue={setFileName}
            labelFontType={"BOLD_CAPTION"}
            labelPosition="start"
            variant="filled"
            readOnly={!hasPermission}
            fileNameValidation
          />
        </Col>
        <Col {...columnProps(4, false, true)}>
          <Button id="policy-doc-email" name="email" onClick={mailDocument}>
            EMAIL
          </Button>
          <Button
            id="policy-doc-print"
            name="print"
            onClick={saveFile ? printAndSaveDocuments : printDocuments}
          >
            PRINT
          </Button>
        </Col>
      </Row>
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template(s) found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </StyleBox>
  );
};

export default EndorsementDocumentFileOptions;
