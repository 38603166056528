import { FC, useEffect, useState } from "react";
import { ContextProps } from "../InsuredDrawerConstants";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import BaseTable2 from "../../../TrueUI/Tables/BaseTable2/BaseTable2";
import { Button, Collapse } from "../../../TrueUI";
import Box from "@mui/material/Box";
import { useApiGet, usePermissions } from "../../../../hooks";
import { TasksConfigurationDto } from "../../../../dtos/tasks-configuration-dto";
import { tasksStyle, tasksTableStyle } from "./TaskDrawerStyles";
import { TaskDto } from "../../../../dtos/task-dto";
import {
  getDateObject,
  isValidDateMoment,
} from "../../../../utilities/dateFunctions";
import BaseTableSearchFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSearchFilter";
import BaseTableDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import BaseTableSortFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableSortFilter";
import { SelectOptions } from "../../../../dtos/select-options";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { ContextEnums } from "../../../../dtos/context-enums";
import { addAllOption } from "../../../../utilities/selectFunctions";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import TaskFormContainer from "./TaskFormContainer";
import { removeQueryStrings } from "../../../../utilities/URLUtilities_OBSOLETE";
import {
  DRAWER_TASK_ID,
  INSURED_ATOM_KEY,
} from "../../../../utilities/queryStringsHash";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { useRecoilState } from "recoil";
import {
  TASK_ATOM_KEY,
  TaskAtomFamily,
  TaskIdFromAssignedCardTasksTable,
} from "./TaskAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { getColumnIndexesByColumnNames } from "../../../BaseGrid/SupportFunctions/baseGridFunctions";
import { hydrateData2 } from "../../../BaseGrid/SupportFunctions/OLD_baseGridFunctions";

type TaskWrapperProps = {
  context: ContextProps;
};

const TaskWrapper: FC<TaskWrapperProps> = ({ context }) => {
  const formStateAtomKey = `${TASK_ATOM_KEY} ${context.tabKey}`;
  const [formState, setFormState] = useRecoilState(
    TaskAtomFamily(formStateAtomKey)
  );
  const [taskIdAtom, setTaskIdAtom] = useRecoilState(
    TaskIdFromAssignedCardTasksTable
  );
  const idFamilyAtom = `${INSURED_ATOM_KEY} ${context.tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(idFamilyAtom));
  const atomContext = getAtom()?.contextDrawer;

  const [configurationState, setConfigurationState] =
    useState<TasksConfigurationDto | null>(null);
  const [refreshTable, setRefreshTable] = useState(false);

  const hasPermission = usePermissions([2, 13, 14, 15]);

  const url =
    context.type === ContextEnums.CLAIMS
      ? `api/Task/GetTasksByClaimNumber?claimNumber=${context.claimNumber}`
      : `api/Task/GetTasksByInsuredId?insuredId=${context.insuredId}`;
  const { responseGet, dispatchGet } = useApiGet<TasksConfigurationDto>(
    `api/task/getTasksConfiguration?claimNumber=${context.claimNumber}`
  );
  useEffect(() => {
    if (context?.type !== atomContext) {
      setFormState({
        editData: null,
        isAddOrEditExpanded: false,
      });
    }
  }, [atomContext]);

  const handleEditMode = (hydratedData: any) => {
    const data: TaskDto = {
      taskId: parseInt(hydratedData.TaskId),
      insuredId: parseInt(hydratedData.InsuredId),
      claimId: hydratedData.ClaimId,
      ownerUserId: parseInt(hydratedData.OwnerUserId),
      assignedUserId: parseInt(hydratedData.AssignedUserId),
      category: parseInt(hydratedData.Category),
      taskStatus: hydratedData.TaskStatus,
      priority: parseInt(hydratedData.Priority),
      subject: hydratedData.Subject,
      comments: hydratedData.Comments ?? "",
      dateDue: isValidDateMoment(hydratedData.DateDue)
        ? getDateObject(hydratedData.DateDue)
        : undefined,
      dateStart: isValidDateMoment(hydratedData.DateStart)
        ? getDateObject(hydratedData.DateStart)
        : undefined,
      dateScheduled: isValidDateMoment(hydratedData.DateScheduled)
        ? getDateObject(hydratedData.DateScheduled)
        : undefined,
      estTime: hydratedData.EstTime,
      actualTime: hydratedData.ActualTime,
      claimNumber: context.claimNumber,
      taskAttachmentsIds: [],
    };
    setFormState({ editData: data, isAddOrEditExpanded: true });
  };

  const ownerOptions: Partial<SelectOptions>[] = addAllOption(
    configurationState?.ownerList ?? []
  );

  const taskPriorityOptions: Partial<SelectOptions>[] = addAllOption(
    configurationState?.priorityList ?? []
  );

  const taskStatusOptions: Partial<SelectOptions>[] = addAllOption(
    configurationState?.taskStatusList ?? []
  );

  const categoryOptions: Partial<SelectOptions>[] =
    context.type === ContextEnums.CLAIMS
      ? addAllOption(configurationState?.categories.claimCategoryOptions ?? [])
      : addAllOption(
          configurationState?.categories?.insuredCategoryOptions ?? []
        );

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => (
    <IconButton
      aria-label="expand row"
      size="small"
      onClick={() => {
        handleEditMode(actionOptions.row);
        setTaskIdAtom(null);
      }}
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );

  const tableName = "TasksTable";
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: url,
    columnOptions: [
      { fieldName: "OwnerUserName", width: 15 },
      { fieldName: "AssignedUserName", width: 15 },
      { fieldName: "Subject", width: 28 },
      { fieldName: "Category", width: 15 },
      { fieldName: "Priority", width: 10 },
      { fieldName: "DateDue", width: 10 },
      { fieldName: "TaskStatus", width: 7 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showImportButton: false,
      showSortFilter: false,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableSearchFilter(
          actionOptions,
          [
            "OwnerUserId",
            "AssignedUserId",
            "Subject",
            "Priority",
            "DateDue",
            "TaskStatus",
          ],
          ["task text"]
        ),
      (actionOptions) =>
        BaseTableSortFilter({
          filterOptions: actionOptions,
          columnsNames: ["DateDue"],
          labelText: "Sort:",
          defaultValue: "asc",
          id: "policy-order-filter",
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "owner-filter",
          filterOptions: actionOptions,
          optionsOverride: ownerOptions,
          columnsNames: ["OwnerUserId"],
          labelText: "Owner:",
          defaultValue: "all",
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "priority-filter",
          filterOptions: actionOptions,
          optionsOverride: taskPriorityOptions,
          columnsNames: ["Priority"],
          labelText: "Priority:",
          validatingAgainstDisplayValue: true,
          defaultValue: "all",
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "status-filter",
          filterOptions: actionOptions,
          optionsOverride: taskStatusOptions,
          columnsNames: ["TaskStatus"],
          labelText: "Status:",
          defaultValue: "all",
          validatingAgainstDisplayValue: true,
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "category-filter",
          filterOptions: actionOptions,
          optionsOverride: categoryOptions,
          columnsNames: ["Category"],
          labelText: "Category:",
          defaultValue: "all",
          validatingAgainstDisplayValue: true,
        }),
    ],
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
    events: {
      onDataChange: (data, columns) => {
        if (taskIdAtom !== null) {
          setTaskFromTableData(data, columns);
        }
      },
    },
  };

  const setTaskFromTableData = (data: any, columns: any[]): void => {
    const taskIdIndex = getColumnIndexesByColumnNames(["TaskId"], columns)?.[0];
    const taskRow = data.filter(
      (row) => (row?.[taskIdIndex] ?? -1) == (taskIdAtom ?? 0)
    );
    handleEditMode(hydrateData2(columns, taskRow)?.[0]);
  };

  const toggleCollapsable = () => {
    if (
      conditionHasValue(formState.editData) &&
      formState.isAddOrEditExpanded
    ) {
      removeQueryStrings([DRAWER_TASK_ID]);
    }
    setFormState({
      ...formState,
      isAddOrEditExpanded: !formState.isAddOrEditExpanded,
    });
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setConfigurationState(responseGet?.axiosResponse?.data ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    if (refreshTable) {
      getTableMethods.methods.refreshTable();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  const { tableMethods } = useBaseTable(tableConfiguration);
  const getTableMethods = tableMethods[tableName];

  return (
    <Box id={"tasks_wrapper"} sx={tasksStyle()}>
      {hasPermission && (
        <Collapse
          expanded={formState.isAddOrEditExpanded}
          content={
            formState.isAddOrEditExpanded ? (
              <TaskFormContainer
                context={context}
                taskConfiguration={configurationState}
                setRefreshTable={() => setRefreshTable(true)}
              />
            ) : null
          }
          title={
            <Button
              id="add_task_link"
              variantStyle="text"
              onClick={toggleCollapsable}
            >
              {formState.editData?.taskId === undefined ||
              formState.editData?.taskId === 0
                ? "ADD TASK"
                : "EDIT TASK"}
            </Button>
          }
          onChange={toggleCollapsable}
          hasBottomMargin={false}
        />
      )}
      <Box sx={tasksTableStyle()}>
        {configurationState !== null && <BaseTable2 name={tableName} />}
      </Box>
    </Box>
  );
};

export default TaskWrapper;
