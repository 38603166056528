import { FC } from "react";
import { Input } from "../..";
import { BaseTableAlternativeDisplayValues } from "../../../../dtos/base-table-alternative-display-values";
import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";

interface BaseTableLinkCellProperties2 extends BaseTableCellSelectProperties {
  alternateDisplayValues: BaseTableAlternativeDisplayValues[];
}

const LinkCell2: FC<BaseTableLinkCellProperties2> = ({
  cellKey,
  value,
  isEdit,
  alternateDisplayValues,
  errorMessage,
  column,
  // rowIndex,
}) => {
  const alternatives = alternateDisplayValues.find((x) => x.guid === value);
  var displayValue = "";
  var url = "";
  var targetType = "";

  if (alternatives?.displayValuesOrFieldNames?.length ?? 0 >= 2) {
    //has display value and url
    displayValue = alternatives?.displayValuesOrFieldNames[0] ?? "";
    url = alternatives?.displayValuesOrFieldNames[1] ?? "";
    targetType = alternatives?.displayValuesOrFieldNames[2] ?? "";
  }
  if (alternatives?.displayValuesOrFieldNames.length === 1) {
    //display value is the url
    displayValue = url = alternatives?.displayValuesOrFieldNames[0];
  }

  // const updateDataValue = (e) => {
  //   onChange({
  //     dataItem: data,
  //     field: propName,
  //     value: e.target.value,
  //   });
  // };
  const formatURL = (_url) => {
    if (_url.charAt(0) === "/" || _url.charAt(0) === "#") return _url;
    const cleanURL = _url.replace("http://", "").replace("https://", "");
    return "https://" + cleanURL;
  };

  const redirectLink = () => {
    // if it's inside true app it automatically uses react router, still opens a new tab
    // if it's another url opens a new tab, NOTE: the link must be complete (ex: https://www.google.com/)
    window.open(formatURL(url), targetType !== "" ? targetType : "_blank");
  };

  return (
    <div onClick={redirectLink} style={{ width: "100%" }}>
      <Input
        id={`id-${column.fieldName}-${cellKey}`}
        name={`name-${column.fieldName}-${cellKey}`}
        className="inputLinkCell"
        key={cellKey}
        readOnly={!isEdit}
        type={"text"}
        onChange={() => {
          // updateDataValue(e.target.value);
        }}
        value={displayValue}
        errorMessage={errorMessage}
        isInputIdent={false}
      />
    </div>
  );
};

export default LinkCell2;
//NOTE: edit mode needs work to edit both the text value displayed and the link
