import { FC, useState } from "react";
import { Box, Link } from "@mui/material";
import { Col, Font, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { getConfigurationForColumn } from "../PolicyCalculator/PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import { documentsGroupRowStyles } from "./DocumentStyles";
import { PolicyQuoteDocumentPacketProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { usePermissions } from "../../../../hooks";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import {
  ERROR_TEMPLATE_NAME,
  getSourceJSONFromDocSourceForIndividualPrinting,
} from "./DocumentsUtils";
import { DocSourceEnum } from "../../../../dtos/doc-source-enum";

const DocumentPacket: FC<PolicyQuoteDocumentPacketProps> = ({
  tabKey,
  programDocuments,
  customJSONToPrint,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const hasPermission = usePermissions([1, 2, 3]); // TODO: @elara Update until Antonio's PR will be ready);

  const evaluatePrintingConfigurations = (
    document: ProgramPolicyDocumentDto,
    templateNames: string[]
  ) => {
    if (templateNames.includes(ERROR_TEMPLATE_NAME)) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "No print template found.",
      });

      return { isFullyConfigured: false };
    }

    if (
      document.docSource === DocSourceEnum.CUSTOM_JSON &&
      customJSONToPrint === ""
    ) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          "There is no custom JSON set up for the current policy period.",
      });

      return { isFullyConfigured: false };
    }

    return { isFullyConfigured: true };
  };

  const printIndividualDocument = (document: ProgramPolicyDocumentDto) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    const templateNames =
      document.fileId !== null
        ? [document.templateName ?? ERROR_TEMPLATE_NAME]
        : policyMergeFields?.endorsementForms?.map((form) => {
            return form.templateName ?? ERROR_TEMPLATE_NAME;
          }) ?? [];
    const { isFullyConfigured } = evaluatePrintingConfigurations(
      document,
      templateNames
    );

    if (isFullyConfigured) {
      sendMergeFormRequest({
        ...getSourceJSONFromDocSourceForIndividualPrinting(
          document,
          customJSONToPrint,
          getAtom()
        ),
        templateNameWithExtensionList: templateNames,
        customFileName: `${policyMergeFields?.insured?.insuredName ?? ""} ${
          document.docName ?? ""
        }`,
      });
    }
  };

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection={"column"}>
      <Col
        verticalGutter="10px"
        {...getConfigurationForColumn(12, "flex-start", "center")}
      >
        <Font>PACKET POLICY DOCUMENTS</Font>
      </Col>
      {programDocuments.map((document, idx) => (
        <Box sx={documentsGroupRowStyles()} key={`documents-packet-${idx}`}>
          <Col
            horizontalGutter="20px"
            {...getConfigurationForColumn(12, "flex-start", "center")}
          >
            <Link
              href="#"
              underline={hasPermission ? "hover" : "none"}
              onClick={(e: any) => {
                hasPermission
                  ? printIndividualDocument(document.programPolicyDocument)
                  : e.preventDefault();
              }}
              sx={{ cursor: hasPermission ? "pointer" : "not-allowed" }}
            >
              {document.programPolicyDocument.docName}
            </Link>
          </Col>
        </Box>
      ))}
      <DialogConfirmation
        {...dialogConfiguration}
        id="id-dialog-confirmation-document-packet"
        optionYesOverrideLabel="OK"
        onOptionYesEvent={() => setDialogConfiguration(null)}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </Row>
  );
};

export default DocumentPacket;
