import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { IncidentStatusAndAssigneesDto } from "../../../../../dtos/incident-status-and-assignees-dto";
import { IncidentStatusEnums } from "../../../../../dtos/incident-status-enums";
import { SubSideNavItemDestinationsEnum } from "../../../../../dtos/sub-side-nav-item-destinations-enum";
import { SystemNumberDto } from "../../../../../dtos/system-number-dto";
import { useApiGet, useApiPost } from "../../../../../hooks";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { getQueryStringsURL } from "../../../../../utilities/URLUtilities_OBSOLETE";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { FormattingDate } from "../../../../../utilities/dateFunctions";
import { formattingKey } from "../../../../../utilities/enumFunctions";
import {
  INSURED_PARENT_ITEM,
  INSURED_CHILD_ITEM,
  INSURED_BODY_SECTION,
  CLAIM_NUMBER,
  INSURED_ATOM_KEY,
  OPEN_CLAIM_INSIDE_INSURED,
} from "../../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { IncidentPropsForContextMenu } from "../Incident";
import {
  IncidentInteractiveDialog,
  ReasonComponent,
} from "./IncidentInteractiveDialog";
import { IncidentTableConfiguration } from "./IncidentTableConfiguration";
import BaseGrid from "../../../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../../../BaseGrid/Hooks/useBaseGrid";
import CreateClaimModalContent from "./CreateClaimModalContent/CreateClaimModalContent";
import PrintIncident from "../../../../Incident/PrintIncident";

const tableName = "incident_table";

type IncidentTableProps = {
  insuredId: number;
  tabKey: string;
};

const IncidentTable: FC<IncidentTableProps> = ({ insuredId, tabKey }) => {
  const insuredIdKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const navigate = useNavigate();

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdKey)
  );
  const claimIncidentAtom = getAtom();

  const [incidentContextMenuValues, setIncidentContextMenuValues] =
    useState<IncidentPropsForContextMenu>();

  const [
    incidentStatusAndAssigneesOptions,
    setIncidentStatusAndAssigneesOptions,
  ] = useState<IncidentStatusAndAssigneesDto>();

  const { responseGet: responseOptionsGet, dispatchGet: dispatchOptionsGet } =
    useApiGet<IncidentStatusAndAssigneesDto>(
      "api/Incident/GetIncidentStatusAndAssigneesAsOptions"
    );

  const { responsePost: responseStatusPost, dispatchPost: dispatchStatusPost } =
    useApiPost(
      `api/Incident/UpdateIncidentStatusByIncidentId?incidentId=${incidentContextMenuValues?.incidentId}` +
        `&incidentStatus=${incidentContextMenuValues?.incidentStatus}&reason=${incidentContextMenuValues?.reason}`
    );

  const {
    responseGet: responseNewClaimProcessGet,
    dispatchGet: dispatchNewClaimProcessGet,
  } = useApiGet<SystemNumberDto>(
    `api/Claims/NewClaimProcess?incidentId=${incidentContextMenuValues?.incidentId}` +
      `&assigneeId=${incidentContextMenuValues?.assigneeId}`
  );

  const [incidentTableFilterSelected, setIncidentTableFilterSelected] =
    useState<number>(IncidentStatusEnums.SUBMITTED);

  const setCorrectContextData = (data) => {
    setIncidentContextMenuValues({
      ...incidentContextMenuValues,
      ...data,
    });
  };

  const tableConfiguration = () => {
    const config = {
      ...IncidentTableConfiguration(
        tableName,
        setCorrectContextData,
        incidentTableFilterSelected,
        setIncidentTableFilterSelected,
        incidentStatusAndAssigneesOptions?.incidentStatuses,
        false,
        navigate
      ),
    };
    return {
      ...config,
      getURL: `api/incident/GetAllIncidentsByInsuredIdPage?insuredId=${insuredId}`,
    };
  };

  const { deleteRow, updateRowAndReload } = useBaseGrid(tableConfiguration());

  const validateAssigneeId = () => {
    return incidentStatusAndAssigneesOptions !== undefined &&
      incidentStatusAndAssigneesOptions?.assignees.length > 0 &&
      incidentStatusAndAssigneesOptions?.assignees[0].intValue !== null
      ? incidentStatusAndAssigneesOptions?.assignees[0].intValue
      : undefined;
  };

  const assigneeOnChange = (select) => {
    setIncidentContextMenuValues({
      ...incidentContextMenuValues,
      assigneeId: select?.value === undefined ? select : select?.value,
    });
  };

  const assigneeValueSelected =
    incidentStatusAndAssigneesOptions !== undefined &&
    incidentStatusAndAssigneesOptions?.assignees.length > 0
      ? incidentStatusAndAssigneesOptions?.assignees[0].intValue
      : "";

  const reasonOnChange = (value) =>
    setIncidentContextMenuValues({
      ...incidentContextMenuValues,
      reason: value,
    });

  const updateTable = () => {
    if (conditionHasValue(incidentContextMenuValues?.row)) {
      if (
        incidentContextMenuValues?.incidentStatus != IncidentStatusEnums.DELETED
      ) {
        const updatedRow = {
          rowKey:
            incidentContextMenuValues?.row?._row_key_column ??
            incidentContextMenuValues?.row?._row_key_column,
          hydratedData: {
            ...incidentContextMenuValues?.row,
            DateOfLoss: FormattingDate(
              incidentContextMenuValues?.row?.DateOfLoss
            ),
            IncidentStatus: formattingKey(
              IncidentStatusEnums[
                incidentContextMenuValues?.incidentStatus ?? 0
              ]
            ),
            Reason: incidentContextMenuValues?.reason,
          },
        };
        updateRowAndReload(updatedRow);
      } else {
        // tableInstanceMethods?.methods?.
        deleteRow({
          rowKey:
            incidentContextMenuValues?.row?.rowKey ??
            incidentContextMenuValues?.row?._row_key,
          hydratedData: incidentContextMenuValues?.row,
          deleteType: "hidden",
        });
      }
    }
  };

  useEffect(() => {
    dispatchOptionsGet();
    setAtom({
      ...getAtom(),
      // Re-set the claimIncidentInformation object because there is
      // no an incident selected
      claimIncidentInformation: null,
    });
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseOptionsGet)) {
      setIncidentStatusAndAssigneesOptions(
        responseOptionsGet.axiosResponse?.data
      );
      setIncidentContextMenuValues({
        ...incidentContextMenuValues,
        readyToRender: true,
      });
    }
  }, [responseOptionsGet]);

  useEffect(() => {
    if (incidentContextMenuValues?.runCreateClaimProcess)
      setIncidentContextMenuValues({
        ...incidentContextMenuValues,
        assigneeId: validateAssigneeId(),
      });
  }, [incidentContextMenuValues?.runCreateClaimProcess]);

  useEffect(() => {
    if (incidentContextMenuValues?.dispatchConfirmation) {
      setIncidentContextMenuValues({
        ...incidentContextMenuValues,
        dispatchConfirmation: false,
      });
      if (incidentContextMenuValues?.runCreateClaimProcess) {
        dispatchNewClaimProcessGet();
      } else {
        dispatchStatusPost();
      }
    }
  }, [incidentContextMenuValues?.dispatchConfirmation]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseStatusPost)) {
      updateTable();
    }
  }, [responseStatusPost]);

  useEffect(() => {
    if (responseNewClaimProcessGet.requestInstanceSuccessful) {
      setIncidentContextMenuValues({
        ...incidentContextMenuValues,
        assigneeId: undefined,
      });
      const newURL = getQueryStringsURL([
        {
          nameOfHash: INSURED_PARENT_ITEM,
          valueOfHash: 4,
        },
        {
          nameOfHash: INSURED_CHILD_ITEM,
          valueOfHash: 1,
        },
        {
          nameOfHash: INSURED_BODY_SECTION,
          valueOfHash: SubSideNavItemDestinationsEnum.CLAIMS_LANDING,
        },
        {
          nameOfHash: CLAIM_NUMBER,
          valueOfHash:
            responseNewClaimProcessGet.axiosResponse?.data.numberGenerated ??
            "",
        },
        {
          nameOfHash: OPEN_CLAIM_INSIDE_INSURED,
          valueOfHash: 1,
        },
      ]);
      navigate(newURL);
    }
  }, [responseNewClaimProcessGet]);

  useEffect(() => {
    if (claimIncidentAtom?.claimIncidentInformation?.incidentSection === 0)
      setIncidentContextMenuValues({
        ...incidentContextMenuValues,
        readyToRender: true,
      });
  }, [claimIncidentAtom?.claimIncidentInformation?.incidentSection]);

  return (
    <>
      {incidentContextMenuValues?.readyToRender && (
        <>
          {/* <BaseTable2 name={tableName} /> */}
          <BaseGrid name={tableName} />
          <PrintIncident
            dispatchPrintFROI={incidentContextMenuValues?.dispatchPrintFROI}
            setDispatchPrintFROI={(value) =>
              setIncidentContextMenuValues({
                ...incidentContextMenuValues,
                dispatchPrintFROI: value,
              })
            }
            incidentId={parseInt(incidentContextMenuValues?.incidentId ?? "0")}
            stateCode={incidentContextMenuValues?.incidentStateCode ?? ""}
            claimantName={incidentContextMenuValues?.incidentClaimantName ?? ""}
          />
          <IncidentInteractiveDialog
            incidentContextMenuValues={incidentContextMenuValues}
            setIncidentContextMenuValues={setIncidentContextMenuValues}
            ReasonComponent={() =>
              ReasonComponent(incidentContextMenuValues?.reason, reasonOnChange)
            }
            AssigneeComponent={() =>
              CreateClaimModalContent(
                incidentStatusAndAssigneesOptions?.assignees ?? [],
                assigneeValueSelected,
                assigneeOnChange,
                parseInt(incidentContextMenuValues?.incidentId ?? "-1")
              )
            }
          />
        </>
      )}
    </>
  );
};

export default IncidentTable;
