import { FC, useEffect, useState } from "react";
import { BaseTable2, Button } from "../../TrueUI";
import {
  getTableConfig,
  tableName,
  PayrollReportingTabContentProps,
  defaultColsAndData,
  allOption,
  activeStringValue,
} from "./PayrollReportingConstants";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { SelectOptions } from "../../../dtos/select-options";
import { PayrollReportTableDataDto } from "../../../dtos/payroll-report-table-data-dto";
import { useApiGet, useApiPost, useFormRequest } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import BaseTableDropdownFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { AllPayrollReportJSON } from "../../../dtos/all-payroll-report-json";
import { ReportingPeriodsDto } from "../../../dtos/reporting-periods-dto";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";
import { FormTypeEnum } from "../../../dtos/form-type-enum";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../TrueUI/Dialogs/DialogConfirmation";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { getDataIndexByColumnNameWithoutExternal } from "../../TrueUI/Tables/tableFunctions";

const dynamicShowButtonAtom = atom<boolean>({
  key: "dynamicShowButtonAtom",
  default: false,
});

const PayrollReportingTabContent: FC<PayrollReportingTabContentProps> = ({
  programId,
}) => {
  const [reportPeriodsSelectOptions, setReportPeriodsSelectOptions] = useState<
    Partial<SelectOptions>[]
  >([]);
  const [selectedReportPeriod, setSelectedReportPeriod] =
    useState<ReportingPeriodsDto | null>(null);
  const [colsAndData, setColsAndData] = useState<{
    columns: any[];
    data: any[][];
  } | null>(null);

  const { responseGet, dispatchGet } = useApiGet<PayrollReportTableDataDto>(
    `api/PayrollReport/GetPayrollReportTableData?programId=${programId}`
  );
  const [sortIdsToPrint, setSortIdsToPrint] = useState<string[]>([]);
  const {
    responsePost: responsePrintPayrolls,
    dispatchPost: dispatchPrintPayrolls,
  } = useApiPost<AllPayrollReportJSON>(
    `api/PayrollReport/GetAllPayrollReportsToPrintByReportingPeriod?reportFrom=${
      selectedReportPeriod?.reportFrom ?? ""
    }&reportTo=${selectedReportPeriod?.reportTo ?? ""}`,
    sortIdsToPrint
  );
  const { sendMergeFormRequest } = useFormRequest();
  const [dispatchGetToPrint, setDispatchGetToPrint] = useState<boolean>(false);
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const setDynamicShowButtonAtom = useSetRecoilState(dynamicShowButtonAtom);

  const printPayrollReports = (allPayrollReportJSON?: AllPayrollReportJSON) => {
    if (
      allPayrollReportJSON !== undefined &&
      allPayrollReportJSON !== null &&
      !isEmptyValue(allPayrollReportJSON.templateName)
    ) {
      const programName =
        (allPayrollReportJSON.payrollReports?.length ?? 0) > 0
          ? `${allPayrollReportJSON.payrollReports?.[0].programName} `
          : "";
      const reportingPeriod = selectedReportPeriod
        ? `${selectedReportPeriod?.displayValue} `
        : "";
      sendMergeFormRequest({
        formType: FormTypeEnum.PAYROLL_REPORT_FORM,
        allPayrollReportMergeFields: allPayrollReportJSON,
        templateNameWithExtensionList: [
          allPayrollReportJSON.templateName ?? "",
        ],
        customFileName: `${programName}${reportingPeriod}Payroll Reports`,
      });
    }
    if (isEmptyValue(allPayrollReportJSON?.templateName)) {
      setDialogConfiguration({
        dialogDescriptionText: "No print template found.",
        open: true,
        optionYesOverrideLabel: "OK",
        onOptionYesEvent: () => setDialogConfiguration(null),
      });
    }
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const reportPeriods =
        responseGet?.axiosResponse?.data?.reportingPeriods ?? [];
      setReportPeriodsSelectOptions(
        reportPeriods.map((reportPeriod) => ({
          displayName: reportPeriod?.displayValue ?? "",
          stringValue: reportPeriod?.displayValue ?? "",
        }))
      );
      setColsAndData(
        responseGet?.axiosResponse?.data?.tableData ?? defaultColsAndData
      );
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePrintPayrolls))
      printPayrollReports(responsePrintPayrolls.axiosResponse?.data);
  }, [responsePrintPayrolls]);

  useEffect(() => {
    if (dispatchGetToPrint) {
      dispatchPrintPayrolls();
      setDispatchGetToPrint(false);
    }
  }, [dispatchGetToPrint]);

  useEffect(() => {
    if (selectedReportPeriod === null) setDynamicShowButtonAtom(false);
    else setDynamicShowButtonAtom(true);
  }, [selectedReportPeriod]);

  const PayrollReportLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          console.log({ rowData });
        }}
      >
        <LaunchIcon fontSize="small" />
      </IconButton>
    );
  };

  const DynamicHideButton = () => {
    const dynamicShowButtonAtomValue = useRecoilValue(dynamicShowButtonAtom);

    return dynamicShowButtonAtomValue ? (
      <Button
        id="id-print-all-button"
        name="print-all-button"
        sx={{ width: "120px", marginRight: "10px" }}
        variantStyle="outlined"
        onClick={() => setDispatchGetToPrint(true)}
      >
        PRINT ALL
      </Button>
    ) : null;
  };

  const baseTableConfig: BaseTable2Properties = {
    ...getTableConfig(colsAndData ?? defaultColsAndData, PayrollReportLink),
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "reporting-period-filter",
          labelText: "Reporting Period:",
          filterOptions: actionOptions,
          columnsNames: ["ReportStatus", "PayrollReportingPeriod"],
          optionsOverride: [allOption, ...(reportPeriodsSelectOptions ?? [])],
          defaultValue: activeStringValue,
          selectWidth: "190px",
          onChange: (value) =>
            setSelectedReportPeriod(
              responseGet?.axiosResponse?.data?.reportingPeriods?.find(
                (reportingPeriod) => reportingPeriod.displayValue === value
              ) ?? null
            ),
        }),
      () => (
        <Button
          id="id-new-report-button"
          name="new-report-button"
          sx={{ width: "120px", marginRight: "10px" }}
          onClick={() => alert("Add Payroll Report")}
        >
          NEW REPORT
        </Button>
      ),
      () => <DynamicHideButton />,
    ],
    events: {
      onDataFilteredChange: (data, columns) => {
        const idIndex = getDataIndexByColumnNameWithoutExternal(
          columns,
          "PayrollReportId"
        );
        const sortIdsToPrint = data.map((row) => {
          return row.length > 0 ? row[idIndex] : "";
        });
        setSortIdsToPrint(sortIdsToPrint);
      },
    },
  };

  useBaseTable(baseTableConfig);

  return (
    <div id={"payroll_reporting_table_container"}>
      {colsAndData !== null && <BaseTable2 name={tableName} />}
      <DialogConfirmation
        id="id-dialog-confirmation-payroll-reporting"
        name="dialog-confirmation-payroll-reporting"
        {...dialogConfiguration}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </div>
  );
};

export default PayrollReportingTabContent;
