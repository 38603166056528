import { AuditTypeEnum } from "../../../../../dtos/audit-type-enum";
import { DiscountDto } from "../../../../../dtos/discount-dto";
import { PolicyAuditInformationBlob } from "../../../../../dtos/policy-audit-information-blob";
import { PolicyExposureBlob } from "../../../../../dtos/policy-exposure-blob";
import { PolicyLimitDto } from "../../../../../dtos/policy-limit-dto";
import { PolicyRatingBlob } from "../../../../../dtos/policy-rating-blob";
import { PolicyStateBlob } from "../../../../../dtos/policy-state-blob";
import { QuoteRatingTypeEnum } from "../../../../../dtos/quote-rating-type-enum";
import { RateElementsConfigurationDto } from "../../../../../dtos/rate-elements-configuration-dto";
import { RateOptionElementDto } from "../../../../../dtos/rate-option-element-dto";
import { ScheduleRatingsDto } from "../../../../../dtos/schedule-ratings-dto";
import { SelectOptions } from "../../../../../dtos/select-options";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import {
  getDateObject,
  getDaysBetweenDates,
  isDateEqualDate,
} from "../../../../../utilities/dateFunctions";
import { customRound } from "../../../../../utilities/stringFunctions";
import { GlobalInsuredAtomProperties } from "../../../InsuredAtoms";
import { getStatesWithGeneralPropsUpdated } from "../../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
} from "../../PolicyQuoteForm/PolicyQuoteTypes";
import { getStateByStateCodeAndDatesByStates } from "../../PolicyQuoteForm/PolicyQuoteUtils";
import { updateQuoteInPolicyQuote } from "../../updatesPolicyQuoteFunctions";
import { getHazardGroupOfHighestManualPremiumItem } from "../ExposureTable/ExposureTableRowFunctions";
import {
  calculatedResultForAgencyDiscount,
  calculatedResultForDiscountEditableTable,
  calculatedResultForDiscountTable,
  calculatedResultForDiscountTier,
  getConfigurationListBySourceName,
  getDefaultOptionForPolicyLimits,
  getDefaultOptionForRateOption,
  getDifferenceBetweenMinimumPremiumAndRunningTotal,
  getDiscountRates,
  getPreviousRunningTotal,
  getProRateCalculation,
  getRatingsUpdatedByRowResult,
  getRunningTotalByPremiumBasisRow,
  getRunningTotalByTotalExposure,
  getStatesUpdatedByRowResult,
  getSumOfPremiumBasisForSpecifiedRatingMultiState,
  getValidatedCalculatedAmountByMinMax,
  isCurrentStateTheGoverningState,
  isCurrentStateTheStateWithHighestMinimumPremium,
  sumManualPremium,
  sumRunningTotalBySpecificPremiumBasis,
} from "./PremiumTableRows/PremiumTableRowsUtils";

//#region METHODS FOR CALCULATIONS
// Agency discount calculations
const getCalculationsForAgencyDiscount = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  states: PolicyStateBlob[]
) => {
  const standardPremium = sumRunningTotalBySpecificPremiumBasis(
    QuoteRatingTypeEnum.STANDARD_PREMIUM,
    states
  );
  return calculatedResultForAgencyDiscount(
    stateCode,
    effectiveDate,
    expirationDate,
    rateIndex,
    standardPremium,
    rating,
    discountRates,
    states
  );
};

// Discount editable calculations
const getCalculationsForDiscountEditable = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  isRateEdited: boolean,
  rateIndex: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  states: PolicyStateBlob[]
) => {
  const standardPremium = sumRunningTotalBySpecificPremiumBasis(
    QuoteRatingTypeEnum.STANDARD_PREMIUM,
    states
  );
  return calculatedResultForDiscountEditableTable(
    stateCode,
    effectiveDate,
    expirationDate,
    rate,
    isRateEdited,
    rateIndex,
    standardPremium,
    rating,
    discountRates,
    states
  );
};

// Discount calculations
const getCalculationsForDiscount = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  states: PolicyStateBlob[]
) => {
  const standardPremium = sumRunningTotalBySpecificPremiumBasis(
    QuoteRatingTypeEnum.STANDARD_PREMIUM,
    states
  );
  if (rating.elementType === QuoteRatingTypeEnum.DISCOUNT_TABLE) {
    return calculatedResultForDiscountTable(
      stateCode,
      effectiveDate,
      expirationDate,
      rateIndex,
      standardPremium,
      rating,
      discountRates,
      states
    );
  } else {
    return calculatedResultForDiscountTier(
      stateCode,
      effectiveDate,
      expirationDate,
      rateIndex,
      standardPremium,
      rating,
      discountRates,
      states
    );
  }
};

//Balance to min calculations
const getCalculationsForBalanceToMin = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  policyLimitOptions: PolicyLimitDto[],
  selectedPolicyLimitId: number,
  sumOfAllPolicyLimitRunningTotal,
  states: PolicyStateBlob[],
  rating: PolicyRatingBlob,
  isGoverningState: boolean
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const selectedOption = policyLimitOptions.filter(
    (policyLimit) => policyLimit.id === selectedPolicyLimitId
  );

  const policyLimitMinAmount =
    selectedOption.length > 0 ? selectedOption[0]?.minimumValue ?? 0 : 0;

  const balanceToMinimumAmount =
    sumOfAllPolicyLimitRunningTotal < policyLimitMinAmount
      ? policyLimitMinAmount - sumOfAllPolicyLimitRunningTotal
      : 0;

  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );

  const newRunningTotal = customRound(
    (previousRunningTotal + balanceToMinimumAmount).toString(),
    rating.rounding ?? 0
  );

  const rowResult: PremiumTableRowResultProps = {
    rate: balanceToMinimumAmount,
    calculatedAmount: balanceToMinimumAmount,
    runningTotal: newRunningTotal,
    isHidden: !isGoverningState,
  };

  return rowResult;
};

//Entered amount calculations
const getCalculationsForEnteredAmount = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  rating: PolicyRatingBlob,
  rateIndex: number,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const newRunningTotal = customRound(
    (previousRunningTotal + rate).toString(),
    rating.rounding ?? 0
  );
  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: rate,
    rate: rate,
  };
  return rowResult;
};

//Entered rate calculations
const getCalculationForEnteredRate = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  rateIndex: number,
  rating: PolicyRatingBlob,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * rate).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
    rate: rate,
  };
  return rowResult;
};

//Expense constant calculations
const getCalculationsForExpenseConstant = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  numberOfDaysInPolicy: number,
  isGoverningState: boolean,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const actualStateEffectiveDate = getDateObject(effectiveDate);
  const actualStateEffectiveDatePlusOneYear = new Date(
    actualStateEffectiveDate.getFullYear() + 1,
    actualStateEffectiveDate.getMonth(),
    actualStateEffectiveDate.getDate()
  );
  const policyPeriodTotalDays = getDaysBetweenDates(
    actualStateEffectiveDate,
    actualStateEffectiveDatePlusOneYear
  );
  const rate = customRound(rating?.rate?.toString() ?? "0", 0);
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );

  const newRunningTotal = rating.proRate
    ? customRound(
        (
          previousRunningTotal +
          getProRateCalculation(
            rating.rate ?? 1,
            policyPeriodTotalDays,
            numberOfDaysInPolicy
          )
        ).toString(),
        rating.rounding ?? 0
      )
    : customRound(
        (previousRunningTotal + rate).toString(),
        rating.rounding ?? 0
      );

  const calculatedAmount = isGoverningState
    ? customRound(
        (Number(newRunningTotal) - previousRunningTotal).toString(),
        0
      )
    : 0;
  const rowResult: PremiumTableRowResultProps = {
    calculatedAmount: calculatedAmount,
    runningTotal: isGoverningState ? newRunningTotal : previousRunningTotal,
    rate: rate,
    isHidden: !isGoverningState,
  };

  return rowResult;
};

//Min prem calculations
const getCalculatedAmountValue = (
  minimumValue: number,
  numberOfDaysInYear: number,
  numberOfDaysInPolicy: number,
  sumOfAllPremiumBasis: number,
  isProRated?: boolean
) => {
  if (isProRated) {
    const minimumPremiumProRated = getProRateCalculation(
      minimumValue,
      numberOfDaysInYear,
      numberOfDaysInPolicy
    );

    return customRound(
      getDifferenceBetweenMinimumPremiumAndRunningTotal(
        sumOfAllPremiumBasis,
        minimumPremiumProRated
      ).toString(),
      0
    );
  } else {
    return customRound(
      getDifferenceBetweenMinimumPremiumAndRunningTotal(
        sumOfAllPremiumBasis,
        minimumValue
      ).toString(),
      0
    );
  }
};

const getCalculationsForMinPrem = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  isStateWithHighestMinimumPremium: boolean,
  sumOfAllPremiumBasis: number,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const actualStateEffectiveDate = getDateObject(effectiveDate);

  const actualStateEffectiveDatePlusOneYear = new Date(
    actualStateEffectiveDate.getFullYear() + 1,
    actualStateEffectiveDate.getMonth(),
    actualStateEffectiveDate.getDate()
  );

  const numberOfDaysInYear = getDaysBetweenDates(
    actualStateEffectiveDate,
    actualStateEffectiveDatePlusOneYear
  );

  const numberOfDaysInPolicy = getDaysBetweenDates(
    getDateObject(effectiveDate),
    getDateObject(expirationDate)
  );

  const rate = customRound(rating.minimumValue?.toString() ?? "0", 0);

  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );

  const calculatedAmount = isStateWithHighestMinimumPremium
    ? customRound(
        getCalculatedAmountValue(
          rating.minimumValue ?? 0,
          numberOfDaysInYear,
          numberOfDaysInPolicy,
          sumOfAllPremiumBasis,
          rating.proRate ?? false
        ).toString(),
        rating.rounding ?? 0
      )
    : 0;

  const newRunningTotal = isStateWithHighestMinimumPremium
    ? customRound(
        (previousRunningTotal + calculatedAmount).toString(),
        rating.rounding ?? 0
      )
    : customRound(previousRunningTotal.toString(), rating.rounding ?? 0);

  const rowResult: PremiumTableRowResultProps = {
    calculatedAmount: calculatedAmount,
    runningTotal: newRunningTotal,
    rate: rate,
    isHidden: !isStateWithHighestMinimumPremium,
  };

  return rowResult;
};

//Optional calculations
const getCalculationsForOptional = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  optionalElement: boolean,
  rateIndex: number,
  rating: PolicyRatingBlob,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const rate = rating?.rate ?? 0;
  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * rate).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const validatedCalculatedAmount = getValidatedCalculatedAmountByMinMax(
    calculatedAmount,
    rating
  );
  const newRunningTotal = previousRunningTotal + validatedCalculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: optionalElement ? newRunningTotal : previousRunningTotal,
    calculatedAmount: validatedCalculatedAmount,
    rate: rate,
    optionalElement,
  };
  return rowResult;
};

//Policy limits calculations
const getCalculationsForPolicyLimits = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  states: PolicyStateBlob[],
  selectedPolicyLimit: PolicyLimitDto | null
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * (selectedPolicyLimit?.rate ?? 0)).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
    rate: selectedPolicyLimit?.rate ?? 0,
    policyLimits: {
      policyLimitsID: selectedPolicyLimit?.id ?? 0,
      optionDisplay: selectedPolicyLimit?.displayValue ?? "",
      elRate: selectedPolicyLimit?.rate ?? 0,
    },
  };
  return rowResult;
};

//Rating calculations
const getCalculationsForRating = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rating: PolicyRatingBlob,
  rateIndex: number,
  exposures: PolicyExposureBlob[],
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const newRunningTotal =
    rateIndex === 0
      ? sumManualPremium(exposures ?? [])
      : getPreviousRunningTotal(rateIndex, currentState?.ratings);
  const rowResult: PremiumTableRowResultProps = {
    runningTotal: customRound(newRunningTotal.toString(), rating.rounding ?? 0),
    calculatedAmount: 0,
    rate: 0,
  };
  return rowResult;
};

//Region calculations
const getRateByRegionExistenceOnInsuredAddress = (
  rateOptionList: RateOptionElementDto[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const insuredAddressList =
    atomValue?.policyQuoteInformation?.policyQuote?.insured?.addresses;
  if (
    rateOptionList.length > 0 &&
    insuredAddressList !== null &&
    insuredAddressList !== undefined &&
    insuredAddressList.length > 0
  ) {
    const rateOptionFound = rateOptionList.find((rateOption) => {
      return insuredAddressList.find(
        (insuredAddress) =>
          insuredAddress.postalCodeRegion === rateOption.selectionValue // should it also filter by insuredAddress.PostalCodeStateCode === props.stateCode??
      )
        ? true
        : false;
    });

    return rateOptionFound ?? null;
  }

  return null;
};

const getCalculationsForRegion = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  rateOptionList: RateOptionElementDto[],
  states: PolicyStateBlob[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );

  const rateOption = getRateByRegionExistenceOnInsuredAddress(
    rateOptionList,
    atomValue
  );

  const rate = rateOption !== null ? rateOption.rate : 1;

  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * rate).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
    rate: rate,
  };

  return rowResult;
};

//Required calculations
const getCalculationsForRequired = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const rate = rating?.rate ?? 0;
  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const exposuresTotal = getRunningTotalByTotalExposure(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );
  const calculatedRunningTotal =
    rating.elementType === QuoteRatingTypeEnum.REQUIRED
      ? customRound(
          (runningTotalByPremiumBasis * rate).toString(),
          rating.rounding ?? 0
        )
      : customRound(
          (exposuresTotal * rate + runningTotalByPremiumBasis).toString(),
          rating.rounding ?? 0
        );
  const calculatedAmount =
    rating.elementType === QuoteRatingTypeEnum.REQUIRED
      ? customRound(
          (calculatedRunningTotal - runningTotalByPremiumBasis).toString(),
          rating.rounding ?? 0
        )
      : customRound((exposuresTotal * rate).toString(), rating.rounding ?? 0);
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    calculatedAmount: calculatedAmount,
    runningTotal: newRunningTotal,
    rate: rate,
  };

  return rowResult;
};

//Schedule form calculations
const getCalculationsForScheduleForm = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  rateIndex: number,
  rating: PolicyRatingBlob,
  scheduleRatingUI: ScheduleRatingsDto[] | null,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );

  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * rate).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    2
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
    rate: rate,
    scheduleRatings: scheduleRatingUI,
  };
  return rowResult;
};

//Selection calculations
const getCalculationsForSelection = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  states: PolicyStateBlob[],
  selectedRateOption: RateOptionElementDto | null
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );

  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * (selectedRateOption?.rate ?? 0)).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
    rate: selectedRateOption?.rate ?? 0,
    rateOption: {
      rateOptionID: selectedRateOption?.id ?? 0,
      optionDisplay: selectedRateOption?.displayValue ?? "",
    },
  };

  return rowResult;
};

//Specific waiver form calculations
const getCalculationsForSpecificWaiverForm = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  calculatedValue: number,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );

  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const validatedCalculatedAmount = getValidatedCalculatedAmountByMinMax(
    calculatedValue,
    rating
  );
  const newRunningTotal = customRound(
    (validatedCalculatedAmount + previousRunningTotal).toString(),
    rating.rounding ?? 0
  );

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: validatedCalculatedAmount,
    rate: rating.rate ?? 0,
  };

  return rowResult;
};

//XMod calculations
const getCalculationsForXMod = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rate: number,
  rateIndex: number,
  rating: PolicyRatingBlob,
  selectedOptionObj: SelectOptions,
  states: PolicyStateBlob[]
) => {
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );

  const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
    rateIndex,
    rating,
    currentState
  );
  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );
  const runningTotalCalculated = customRound(
    (runningTotalByPremiumBasis * rate).toString(),
    rating.rounding ?? 0
  );
  const calculatedAmount = customRound(
    (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
    rating.rounding ?? 0
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
    rate: rate,
    policyReference: {
      policyReferenceID: selectedOptionObj.intValue ?? -1,
      optionDisplay: selectedOptionObj.displayName ?? "",
    },
  };
  return rowResult;
};

//Short Rate calculations
const getCalculatedAmountForShortRate = (
  shouldCalculate: boolean,
  rateIndex: number,
  rating: PolicyRatingBlob,
  numberOfDaysInPolicy: number,
  shortRate: number,
  currentState?: PolicyStateBlob
) => {
  if (shouldCalculate) {
    const runningTotalByPremiumBasis = getRunningTotalByPremiumBasisRow(
      rateIndex,
      rating,
      currentState
    );

    const dailyPremiumAmount =
      runningTotalByPremiumBasis / numberOfDaysInPolicy;
    const annualPremium = dailyPremiumAmount * 365;

    const runningTotalCalculated = customRound(
      (annualPremium * shortRate).toString(),
      rating.rounding ?? 0
    );
    const calculatedAmount = customRound(
      (runningTotalCalculated - runningTotalByPremiumBasis).toString(),
      rating.rounding ?? 0
    );
    return calculatedAmount;
  }

  return 0;
};

const getCalculationsForShortRate = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  auditInformation: PolicyAuditInformationBlob,
  numberOfDaysInPolicy: number,
  rateIndex: number,
  rating: PolicyRatingBlob,
  states: PolicyStateBlob[]
) => {
  const shouldCalculate =
    auditInformation.applyShortRate === true &&
    auditInformation.auditType?.value === AuditTypeEnum.EXIT;
  const shortRate = auditInformation.shortRateFactor ?? 1;
  const currentState = getStateByStateCodeAndDatesByStates(
    stateCode,
    effectiveDate,
    expirationDate,
    states
  );

  const previousRunningTotal = getPreviousRunningTotal(
    rateIndex,
    currentState?.ratings
  );

  const calculatedAmount = getCalculatedAmountForShortRate(
    shouldCalculate,
    rateIndex,
    rating,
    numberOfDaysInPolicy,
    shortRate,
    currentState
  );
  const newRunningTotal = previousRunningTotal + calculatedAmount;

  const rowResult: PremiumTableRowResultProps = {
    runningTotal: newRunningTotal,
    calculatedAmount: calculatedAmount,
    rate: shouldCalculate ? shortRate : 1,
  };
  return rowResult;
};
//#endregion

//#region CALCULATIONS BY RATING TYPE
//Calculations by rating type
const getCalculationsByRatingType = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  rateIndex: number,
  rating: PolicyRatingBlob,
  discountRates: DiscountDto[],
  exposures: PolicyExposureBlob[],
  rate: number,
  auditInformation: PolicyAuditInformationBlob,
  isRateEdited: boolean,
  policyLimitOptions: PolicyLimitDto[],
  selectedPolicyLimitId: number,
  sumOfAllPremiumBasis: number,
  isGoverningState: boolean,
  isStateWithHighestMinimumPremium: boolean,
  optionalValue: boolean,
  rateOptionList: RateOptionElementDto[],
  scheduleRatings: ScheduleRatingsDto[],
  selectedRateOption: RateOptionElementDto | null,
  selectedPolicyLimit: PolicyLimitDto | null,
  calculatedValue: number,
  selectedOptionObj: SelectOptions,
  numberOfDaysInPolicy: number,
  states: PolicyStateBlob[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  switch (rating.elementType) {
    case QuoteRatingTypeEnum.AGENCY_DISCOUNT:
      return getCalculationsForAgencyDiscount(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        rating,
        discountRates,
        states
      );
    case QuoteRatingTypeEnum.DISCOUNT_TABLE_EDIT:
      return getCalculationsForDiscountEditable(
        stateCode,
        effectiveDate,
        expirationDate,
        rate,
        isRateEdited,
        rateIndex,
        rating,
        discountRates,
        states
      );
    case QuoteRatingTypeEnum.DISCOUNT_TIER:
    case QuoteRatingTypeEnum.DISCOUNT_TABLE:
      return getCalculationsForDiscount(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        rating,
        discountRates,
        states
      );
    case QuoteRatingTypeEnum.EL_BALANCE_TO_MIN:
      return getCalculationsForBalanceToMin(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        policyLimitOptions,
        selectedPolicyLimitId,
        sumOfAllPremiumBasis,
        states,
        rating,
        isGoverningState
      );
    case QuoteRatingTypeEnum.ENTERED_AMOUNT:
      return getCalculationsForEnteredAmount(
        stateCode,
        effectiveDate,
        expirationDate,
        rate,
        rating,
        rateIndex,
        states
      );
    case QuoteRatingTypeEnum.ENTERED_RATE:
      return getCalculationForEnteredRate(
        stateCode,
        effectiveDate,
        expirationDate,
        rate,
        rateIndex,
        rating,
        states
      );
    case QuoteRatingTypeEnum.EXPENSE_CONSTANT:
      return getCalculationsForExpenseConstant(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        rating,
        numberOfDaysInPolicy,
        isGoverningState,
        states
      );
    case QuoteRatingTypeEnum.MIN_PREM:
      return getCalculationsForMinPrem(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        rating,
        isStateWithHighestMinimumPremium,
        sumOfAllPremiumBasis,
        states
      );
    case QuoteRatingTypeEnum.OPTION:
      return getCalculationsForOptional(
        stateCode,
        effectiveDate,
        expirationDate,
        optionalValue,
        rateIndex,
        rating,
        states
      );
    case QuoteRatingTypeEnum.POLICY_LIMITS:
      return getCalculationsForPolicyLimits(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        rating,
        states,
        selectedPolicyLimit
      );
    case QuoteRatingTypeEnum.MANUAL_PREMIUM:
    case QuoteRatingTypeEnum.SUBJECT_PREMIUM:
    case QuoteRatingTypeEnum.MODIFIED_PREMIUM:
    case QuoteRatingTypeEnum.STANDARD_PREMIUM:
    case QuoteRatingTypeEnum.ESTIMATED_ANNUAL_PREMIUM:
      return getCalculationsForRating(
        stateCode,
        effectiveDate,
        expirationDate,
        rating,
        rateIndex,
        exposures,
        states
      );
    case QuoteRatingTypeEnum.REGION:
      return getCalculationsForRegion(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        rating,
        rateOptionList,
        states,
        atomValue
      );
    case QuoteRatingTypeEnum.REQUIRED:
    case QuoteRatingTypeEnum.EXPOSURE_BASIS:
      return getCalculationsForRequired(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        rating,
        states
      );
    case QuoteRatingTypeEnum.SCHEDULE_FORM:
      return getCalculationsForScheduleForm(
        stateCode,
        effectiveDate,
        expirationDate,
        rate,
        rateIndex,
        rating,
        scheduleRatings,
        states
      );
    case QuoteRatingTypeEnum.SELECTION:
      return getCalculationsForSelection(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        rating,
        states,
        selectedRateOption
      );
    case QuoteRatingTypeEnum.SPECIFIC_WAIVER_FORM:
      return getCalculationsForSpecificWaiverForm(
        stateCode,
        effectiveDate,
        expirationDate,
        rateIndex,
        rating,
        calculatedValue,
        states
      );
    case QuoteRatingTypeEnum.X_MOD:
      return getCalculationsForXMod(
        stateCode,
        effectiveDate,
        expirationDate,
        rate,
        rateIndex,
        rating,
        selectedOptionObj,
        states
      );
    case QuoteRatingTypeEnum.SHORT_RATE:
      return getCalculationsForShortRate(
        stateCode,
        effectiveDate,
        expirationDate,
        auditInformation,
        numberOfDaysInPolicy,
        rateIndex,
        rating,
        states
      );
    default:
      return getCalculationsForRating(
        stateCode,
        effectiveDate,
        expirationDate,
        rating,
        rateIndex,
        exposures,
        states
      );
  }
};
//#endregion

//#region METHODS TO RE-CALCULATE IN ORDER
const getRatingsUpdatedByCalculations = (
  state: PolicyStateBlob,
  allStates: PolicyStateBlob[],
  atomValue: GlobalInsuredAtomProperties | null,
  governingStateEvaluation: {
    isGoverningState: boolean;
    classCodeWithHighestPremium: string;
  },
  isStateWithHighestMinimumPremium: {
    isStateWithHMP: boolean;
    stateCode?: string | null;
    effectiveDate?: Date | null;
    expirationDate?: Date | null;
  },
  hazardGroup: string,
  index: number,
  configurations?: RateElementsConfigurationDto | null
) => {
  if (
    state.ratings !== undefined &&
    state.ratings !== null &&
    index < state.ratings.length
  ) {
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
    const rating = state.ratings[index];
    const discountRates = configurations
      ? getDiscountRates(configurations, rating)
      : [];
    const isRateEdited = conditionHasValue(rating.rate);
    const sumOfAllPremiumBasis =
      getSumOfPremiumBasisForSpecifiedRatingMultiState(allStates, rating);
    const rateOptionList = getConfigurationListBySourceName(
      rating.sourceName ?? "",
      hazardGroup,
      configurations
    ).sort((a, b) => b.rate - a.rate);
    const selectedRateOption = getDefaultOptionForRateOption(
      rateOptionList,
      rating.rateOption
    );
    const policyReference = {
      displayName: rating.policyReference?.optionDisplay ?? "",
      intValue: rating.policyReference?.policyReferenceID ?? -1,
    } as SelectOptions;
    const selectedPolicyLimit = getDefaultOptionForPolicyLimits(
      configurations?.policyLimitsOptions ?? [],
      rating.policyLimits,
      policyJSON?.quote?.policyLimits
    );
    const auditInformation =
      policyJSON?.auditInformation ?? ({} as PolicyAuditInformationBlob);
    const numberOfDaysInPolicy = policyJSON?.policyDays;

    const calculations = getCalculationsByRatingType(
      state.stateCode ?? "",
      state.effectiveDate ?? new Date(),
      state.expirationDate ?? new Date(),
      index,
      rating,
      discountRates,
      state.exposures ?? [],
      rating.rate ?? 1,
      auditInformation,
      isRateEdited,
      configurations?.policyLimitsOptions ?? [],
      rating.policyLimits?.policyLimitsID ?? -1,
      sumOfAllPremiumBasis,
      governingStateEvaluation.isGoverningState,
      isStateWithHighestMinimumPremium.isStateWithHMP,
      rating.optionalElement ?? false,
      rateOptionList,
      rating.scheduleRatings ?? [],
      selectedRateOption ?? null,
      selectedPolicyLimit,
      rating.amount ?? 0,
      policyReference,
      numberOfDaysInPolicy ?? 1,
      allStates,
      atomValue
    );

    const allStatesUpdated = getStatesUpdatedByRowResult(
      state.stateCode ?? "",
      state.effectiveDate ?? new Date(),
      state.expirationDate ?? new Date(),
      index,
      calculations,
      allStates
    );

    const stateUpdated = getStateByStateCodeAndDatesByStates(
      state.stateCode ?? "",
      state.effectiveDate ?? new Date(),
      state.expirationDate ?? new Date(),
      allStatesUpdated
    );

    if (stateUpdated !== undefined) {
      return getRatingsUpdatedByCalculations(
        stateUpdated,
        allStatesUpdated,
        atomValue,
        governingStateEvaluation,
        isStateWithHighestMinimumPremium,
        hazardGroup,
        index + 1,
        configurations
      );
    }
    return state.ratings;
  }
  return state.ratings ?? [];
};

const getOrderToRecalculateStates = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  currentStates: PolicyStateBlob[]
) => {
  /* Multi state element row type collection, add more types if more multi-state rows are added. */
  const multiStateRowTypeList = [
    QuoteRatingTypeEnum.EXPENSE_CONSTANT,
    QuoteRatingTypeEnum.MIN_PREM,
    QuoteRatingTypeEnum.EL_BALANCE_TO_MIN,
  ];

  /* Multi state rating information per state to trigger calculations */
  const multiStateRowsPerState = currentStates.map((state) => {
    if (
      state.stateCode !== stateCode ||
      !isDateEqualDate(state.effectiveDate, effectiveDate) ||
      !isDateEqualDate(state.expirationDate, expirationDate)
    ) {
      const ratingsWithIndexForRelevance =
        state.ratings?.map((rating, index) => {
          if (multiStateRowTypeList.includes(rating.elementType ?? -1)) {
            return { rating, index, state };
          }
          return null;
        }) ?? [];
      const ratingsWithIndexForRelevanceWithoutNulls =
        ratingsWithIndexForRelevance.filter((x) => x !== null);

      return ratingsWithIndexForRelevanceWithoutNulls.length > 0
        ? [ratingsWithIndexForRelevanceWithoutNulls[0]]
        : [];
    }
    return [];
  });

  const allRatingsWithPriorityOrder = multiStateRowsPerState.flatMap(
    (stateInformation) => stateInformation
  );

  /*
      Order the ratings by order of appearance (the order of execution for the re calculation does affect the outcome)
  
      IMPORTANT NOTE:
        atm of developing this function, there are only 3 multi-state row types in the system and it is expected that the "Balance To Min" row is always before the
        "minimum premium" row in the configurations on the DB, if we were to add a new multi-state row with a very special behavior we would need to re-visit
        this function and see if the order for the re-calculations will be altered or managed differently.
  
        Notice how I did not mention the "Expense Constant" this is because even though it has multi-state interaction it is not a complex one as the other two
        so it doesn't really affect the calculations.
  
        This note was made on 10/10/2024.
      */
  const allRatingsSortedByPriorityOfCalculation =
    allRatingsWithPriorityOrder.sort((a, b) => a.index - b.index);

  return allRatingsSortedByPriorityOfCalculation;
};

const recalculateRatingsForSingleState = (
  state: PolicyStateBlob,
  states: PolicyStateBlob[],
  recalculate: boolean,
  atomValue: GlobalInsuredAtomProperties | null,
  rateIndex?: number,
  rowResult?: PremiumTableRowResultProps
) => {
  const configurations = state.rateElementConfigurations;
  const governingStateEvaluation = isCurrentStateTheGoverningState(
    state.stateCode ?? "",
    state.effectiveDate ?? new Date(),
    state.expirationDate ?? new Date(),
    states
  );
  const isStateWithHighestMinimumPremium =
    isCurrentStateTheStateWithHighestMinimumPremium(
      state.stateCode ?? "",
      state.effectiveDate ?? new Date(),
      state.expirationDate ?? new Date(),
      states
    );
  const hazardGroup = getHazardGroupOfHighestManualPremiumItem(
    state?.exposures ?? []
  );
  return {
    ...state,
    ratings:
      !recalculate && rowResult !== undefined
        ? getRatingsUpdatedByRowResult(rateIndex ?? 0, rowResult, state)
        : getRatingsUpdatedByCalculations(
            state,
            states,
            atomValue,
            governingStateEvaluation,
            isStateWithHighestMinimumPremium,
            hazardGroup,
            0,
            configurations
          ),
  };
};

const recalculateAllStatesButCurrent = (
  states: PolicyStateBlob[],
  orderToRecalculateStates: {
    rating: PolicyRatingBlob;
    index: number;
    state: PolicyStateBlob;
  }[],
  index: number,
  atomValue: GlobalInsuredAtomProperties | null,
  configurations?: RateElementsConfigurationDto | null
) => {
  if (index < orderToRecalculateStates.length) {
    const stateToUpdate = orderToRecalculateStates[index].state;
    const statesUpdated = states.map((state) => {
      if (
        state.stateCode === stateToUpdate.stateCode &&
        isDateEqualDate(state.effectiveDate, stateToUpdate.effectiveDate) &&
        isDateEqualDate(state.expirationDate, stateToUpdate.expirationDate)
      ) {
        return recalculateRatingsForSingleState(state, states, true, atomValue);
      }
      return state;
    });

    return recalculateAllStatesButCurrent(
      statesUpdated,
      orderToRecalculateStates,
      index + 1,
      atomValue,
      configurations
    );
  } else {
    return states;
  }
};

export const getStatesUpdatedByRecalculations = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  states: PolicyStateBlob[],
  atomValue: GlobalInsuredAtomProperties | null,
  rateIndex?: number,
  rowResult?: PremiumTableRowResultProps
) => {
  const statesWithCurrentStateUpdated = states.map((state) => {
    if (
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
    ) {
      return recalculateRatingsForSingleState(
        state,
        states,
        false,
        atomValue,
        rateIndex,
        rowResult
      );
    } else {
      return state;
    }
  });

  if (states.length > 1) {
    // Multi state recalculation
    const orderToRecalculateStates = getOrderToRecalculateStates(
      stateCode,
      effectiveDate,
      expirationDate,
      statesWithCurrentStateUpdated
    );

    const allStatesButCurrentRecalculated = recalculateAllStatesButCurrent(
      statesWithCurrentStateUpdated,
      orderToRecalculateStates,
      0,
      atomValue
    );

    const currentStateRecalculatedOneLastTime =
      allStatesButCurrentRecalculated.map((state) => {
        if (
          state.stateCode === stateCode &&
          isDateEqualDate(state.effectiveDate, effectiveDate) &&
          isDateEqualDate(state.expirationDate, expirationDate)
        ) {
          return recalculateRatingsForSingleState(
            state,
            states,
            true,
            atomValue
          );
        } else {
          return state;
        }
      });

    return getStatesWithGeneralPropsUpdated(
      currentStateRecalculatedOneLastTime
    );
  }

  return getStatesWithGeneralPropsUpdated(statesWithCurrentStateUpdated);
};
//#endregion

//#region ATOM METHODS FOR RECALCULATIONS
export const getAtomUpdatedByRecalculations = (
  props: PremiumTableRowProps,
  rowResult: PremiumTableRowResultProps,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const currentStates =
    atomValue?.policyQuoteInformation?.policyQuote?.quote?.states ?? [];

  const statesUpdatedByRatingChanged = getStatesUpdatedByRowResult(
    props.stateCode,
    props.effectiveDate,
    props.expirationDate,
    props.rateIndex,
    rowResult,
    currentStates
  );

  const statesUpdatedAfterCalculations = getStatesUpdatedByRecalculations(
    props.stateCode ?? "",
    props.effectiveDate ?? new Date(),
    props.expirationDate ?? new Date(),
    statesUpdatedByRatingChanged,
    atomValue
  );
  const newAtomValue = updateQuoteInPolicyQuote(
    atomValue,
    "states",
    statesUpdatedAfterCalculations
  );

  return newAtomValue;
};
//#endregion
