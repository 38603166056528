import { atomFamily, atom } from "recoil";
import { TaskDto } from "../../../../dtos/task-dto";
import { FilesInfo } from "./TaskForm";

export type TaskAtomProps = {
  isAddOrEditExpanded: boolean;
  editData: Partial<TaskDto> | null;
  filesInfo?: FilesInfo | null;
};

export const TASK_ATOM_KEY = "TASK_ATOM";
const TaskAtomFamily = atomFamily<TaskAtomProps, string>({
  key: "TaskAtomFamily",
  default: { editData: null, isAddOrEditExpanded: false },
});

const TaskIdFromAssignedCardTasksTable = atom<number | null>({
  key: "TaskIdFromAssignedCardTasksTable",
  default: null,
});
export { TaskAtomFamily, TaskIdFromAssignedCardTasksTable };
