import { useEffect } from "react";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { BaseTableComputeResponse } from "../../../../dtos/base-table-compute-response";
import { useApiGet, useApiPost } from "../../../../hooks";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { NO_URL_PROVIDED_ERROR } from "../TableConstants";
import {
  castToDataType,
  getDataWithoutDeletedRows,
  getSafeRowKey,
  hydrateData2,
} from "../tableFunctions";
import { TableInstanceType2 } from "./TableProperties";

const BodyListenerManager = (props) => {
  const { uiid } = props;

  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );

  const { responsePost, dispatchPost } = useApiPost(
    tableInstance?.postURL ?? NO_URL_PROVIDED_ERROR,
    {
      [tableInstance?.postTarget ?? "changedTableData"]:
        tableInstance?.dataToBeSaved,
    }
  );

  const { responseGet: responseDynamicURL, dispatchGet: dispatchDynamicURL } =
    useApiGet<BaseTableComputeResponse | BaseTableComputeResponse[]>(
      tableInstance?._inProcessAPIResponse?.getURL ?? ""
    );

  const setTableInstance = useRecoilCallback(
    ({ snapshot, set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        const _tableInstance = snapshot.getLoadable(
          tableInstanceAtomFamily2(uiid)
        ).contents as any;
        set(tableInstanceAtomFamily2(uiid), {
          ..._tableInstance,
          ...newValueTableInstance,
          uiid: uiid,
        });
      },
    []
  );

  useEffect(() => {
    if (tableInstance?._newestRowKey !== null) {
      tableInstance.events?.onAddRow?.(tableInstance?._newestRowKey ?? "");
    }
  }, [tableInstance._newestRowKey]);

  const save = () => {
    if (tableInstance?.changedData.length ?? 0 > 0) {
      const castedUpdatedData = castToDataType(
        tableInstance?.columns ?? [],
        tableInstance?.changedData ?? []
      );

      const dataToSave = hydrateData2(
        tableInstance?.columns ?? [],
        castedUpdatedData
      ).map((row) => {
        return { ...row };
      });

      setTableInstance({
        ...(tableInstance as TableInstanceType2),
        dataToBeSaved: dataToSave,
      });
      tableInstance?.events?.onDataToBeSavedChange?.(dataToSave);
    }
    tableInstance?.methods?.onSaveClick?.();
  };

  useEffect(() => {
    if (tableInstance?.isSave) {
      save();
    }
  }, [tableInstance?.isSave]);

  // useEffect(() => {
  //   if (
  //     (tableInstance?.dataToBeSaved.length ?? 0) > 0 &&
  //     tableInstance?.postURL !== undefined &&
  //     tableInstance?.postURL !== null &&
  //     tableInstance?.postURL !== ""
  //   ) {
  //     dispatchPost();
  //   }
  // }, [tableInstance?.dataToBeSaved]);

  useEffect(() => {
    if (
      (tableInstance?.dataToBeSaved.length ?? 0) > 0 &&
      tableInstance?.postURL !== undefined &&
      tableInstance?.postURL !== null &&
      tableInstance?.postURL !== ""
    ) {
      dispatchPost();
    }
  }, [tableInstance?.dataToBeSaved]);

  useEffect(() => {
    if (responsePost?.errorResponse) {
      setTableInstance({
        ...(tableInstance as TableInstanceType2),
        validationErrors: responsePost.errorResponse.errorDetails,
        isSave: false,
      });
    }
    if (responsePost?.requestInstanceSuccessful) {
      setTableInstance({
        ...tableInstance,
        dataToBeSaved: [],
        changedData: [],
        _recentlyAddedRowKey: [],
        isSave: false,
        isEdit: false,
        toggleEditModeState: false,
        validationErrors: null,
      });
      tableInstance?.events?.onDataToBeSavedChange?.([]);
    }
  }, [responsePost]);

  useEffect(() => {
    if (tableInstance?.hasDataChanged) {
      setTableInstance({
        ...tableInstance,
        hasDataChanged: false,
      });
    }
  }, [tableInstance?.hasDataChanged]);

  useEffect(() => {
    if (tableInstance.isPreProcessingComplete) {
      const updatedData = getDataWithoutDeletedRows(
        tableInstance?.data,
        tableInstance.columns
      );

      // TODO - remove internal columns

      tableInstance?.methods?.onDataChange?.(
        updatedData ?? [],
        tableInstance?.columns ?? []
      );

      if (tableInstance.allSelectedRows) {
        const allRowKeys = tableInstance.data.map((row) => getSafeRowKey(row));
        setTableInstance({ ...tableInstance, selectedRows: allRowKeys });
      }
    }
  }, [tableInstance?.data]);

  const addOrReplace = (arr, newObj) => [
    ...arr.filter((o) => o.getDataName !== newObj.getDataName),
    { ...newObj },
  ];

  const updateComputeAPIData = () => {
    if (
      (tableInstance !== undefined &&
        tableInstance?._inProcessAPIResponse !== null &&
        tableInstance?._inProcessAPIResponse?.triggerComputes !== null &&
        tableInstance?._inProcessAPIResponse?.triggerComputes !== undefined &&
        tableInstance?._inProcessAPIResponse?.triggerComputes?.length > 0 &&
        responseDynamicURL.errorResponse !== null) ||
      (responseDynamicURL.axiosResponse?.data !== undefined &&
        responseDynamicURL.axiosResponse?.data !== null)
    ) {
      // TODO - update this to do "find" existing objects and just replace the object by name

      const foundComputeAPIObject =
        tableInstance?._computeAPIData.find(
          (c) =>
            c.getDataName === tableInstance?._inProcessAPIResponse?.getDataName
        ) ?? null;

      const mergedComputeAPIObject = {
        ...foundComputeAPIObject,
        rowKey:
          tableInstance?._lastOnChangeInitiatorCellKey?.rowKey ??
          "NO_ROW_KEY_FOUND",
        getDataName:
          tableInstance?._inProcessAPIResponse?.getDataName ??
          "Replace with table const val error or guid",
        url: tableInstance?._inProcessAPIResponse?.getURL ?? "NO_URL_FOUND",
        data: responseDynamicURL?.axiosResponse?.data ?? null,
        targetFieldNames:
          tableInstance?._inProcessAPIResponse?.triggerComputes ?? [],
        initiatingCellKey:
          tableInstance?._lastOnChangeInitiatorCellKey?.cellKey ??
          "NO_CELL_KEY_FOUND",
        status: responseDynamicURL?.axiosResponse?.status ?? null,
        errorResponse: responseDynamicURL?.errorResponse ?? null,
      };

      const updatedComputeAPIObject = addOrReplace(
        tableInstance._computeAPIData,
        mergedComputeAPIObject
      );

      setTableInstance({
        ...tableInstance,
        _computeAPIData: updatedComputeAPIObject,
        _inProcessAPIResponse: null,
        _inProcessCell: null,
      });
    }
  };

  useEffect(() => {
    updateComputeAPIData();
  }, [
    responseDynamicURL.axiosResponse?.data,
    responseDynamicURL.errorResponse,
  ]);

  useEffect(() => {
    if (
      tableInstance?._inProcessAPIResponse?.getURL !== "" &&
      tableInstance?._inProcessAPIResponse?.getURL !== undefined &&
      tableInstance?._inProcessAPIResponse?.getURL !== null
    ) {
      dispatchDynamicURL();
    }
  }, [tableInstance?._inProcessAPIResponse?.getURL]);

  return null;
};

export default BodyListenerManager;
