import { FC } from "react";
import EnteredAmountRow from "./EnteredAmountRow";
import EnteredRateRow from "./EnteredRateRow";
import ExpenseConstantRow from "./ExpenseConstantRow";
import OptionalRow from "./OptionalRow";
import MinPremRow from "./MinPremRow";
import RatingRow from "./RatingRow";
import RequiredRow from "./RequiredRow";
import ELBalanceToMinRow from "./ELBalanceToMinRow";
import SelectionRow from "./SelectionRow";
import XModRow from "./XModRow";
import { QuoteRatingTypeEnum } from "../../../../../../dtos/quote-rating-type-enum";
import { PremiumTableRowProps } from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import DiscountRow from "./DiscountRow";
import ScheduleFormRow from "./ScheduleFormRow";
import PolicyLimitsRow from "./PolicyLimitsRow";
import SpecificWaiverFormRow from "./SpecificWaiverFormRow";
import AgencyDiscountRow from "./AgencyDiscountRow";
import DiscountEditableRow from "./DiscountEditableRow";
import RegionRow from "./RegionRow";
import ShortRateRow from "./ShortRateRow";

const PremiumRowSelector: FC<PremiumTableRowProps> = (props) => {
  switch (props.rating.elementType) {
    case QuoteRatingTypeEnum.OPTION:
      return <OptionalRow {...props} />;
    case QuoteRatingTypeEnum.SELECTION:
      return <SelectionRow {...props} />;
    case QuoteRatingTypeEnum.X_MOD:
      return <XModRow {...props} />;
    case QuoteRatingTypeEnum.MANUAL_PREMIUM:
    case QuoteRatingTypeEnum.SUBJECT_PREMIUM:
    case QuoteRatingTypeEnum.MODIFIED_PREMIUM:
    case QuoteRatingTypeEnum.STANDARD_PREMIUM:
    case QuoteRatingTypeEnum.ESTIMATED_ANNUAL_PREMIUM:
      return <RatingRow {...props} />;
    case QuoteRatingTypeEnum.ENTERED_RATE:
      return <EnteredRateRow {...props} />;
    case QuoteRatingTypeEnum.SCHEDULE_FORM:
      return <ScheduleFormRow {...props} />;
    case QuoteRatingTypeEnum.MIN_PREM:
      return <MinPremRow {...props} />;
    case QuoteRatingTypeEnum.DISCOUNT_TIER:
    case QuoteRatingTypeEnum.DISCOUNT_TABLE:
      return <DiscountRow {...props} />;
    case QuoteRatingTypeEnum.DISCOUNT_TABLE_EDIT:
      return <DiscountEditableRow {...props} />;
    case QuoteRatingTypeEnum.AGENCY_DISCOUNT:
      return <AgencyDiscountRow {...props} />;
    case QuoteRatingTypeEnum.EXPENSE_CONSTANT:
      return <ExpenseConstantRow {...props} />;
    case QuoteRatingTypeEnum.REQUIRED:
    case QuoteRatingTypeEnum.EXPOSURE_BASIS:
      return <RequiredRow {...props} />;
    case QuoteRatingTypeEnum.LCM:
      return <></>;
    case QuoteRatingTypeEnum.EL_BALANCE_TO_MIN:
      return <ELBalanceToMinRow {...props} />;
    case QuoteRatingTypeEnum.ENTERED_AMOUNT:
      return <EnteredAmountRow {...props} />;
    case QuoteRatingTypeEnum.POLICY_LIMITS:
      return <PolicyLimitsRow {...props} />;
    case QuoteRatingTypeEnum.SPECIFIC_WAIVER_FORM:
      return <SpecificWaiverFormRow {...props} />;
    case QuoteRatingTypeEnum.REGION:
      return <RegionRow {...props} />;
    case QuoteRatingTypeEnum.SHORT_RATE:
      return <ShortRateRow {...props} />;
    default:
      return <></>;
  }
};

export default PremiumRowSelector;
