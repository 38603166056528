import { FC } from "react";
import { getQueryValue } from "../../../../utilities/URLUtilities_OBSOLETE";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  INCIDENT_ID,
  INSURED_ATOM_KEY,
  INCIDENT_BODY_SECTION,
} from "../../../../utilities/queryStringsHash";
import AddNewIncidentNew from "./AddNewIncident/AddNewIncidentNew";
import { IncidentBodySectionEnums } from "../../../../dtos/incident-body-section-enums";

type IncidentProps = {
  insuredId: number;
  tabKey: string;
};

export type IncidentTableOrNewIncidentProps = {
  isIncidentSection: boolean;
};

export type IncidentPropsForContextMenu = {
  row?: any;
  assigneeId?: number;
  dialogConfirmationAnswerIsYes?: boolean;
  dialogConfirmationOpen?: boolean;
  dialogDescriptionText?: string;
  incidentId?: string;
  incidentInReadOnly?: boolean;
  incidentStatus?: number;
  insuredId?: string;
  readyToRender?: boolean;
  reason?: string;
  runCreateClaimProcess?: boolean;
  dispatchConfirmation?: boolean;
  incidentClaimantName?: string;
  incidentStateCode?: string;
  dispatchPrintFROI?: boolean;
};

const Incident: FC<IncidentProps> = ({ insuredId, tabKey }) => {
  const isIncidentOpen = getQueryValue(INCIDENT_ID);

  const insuredIdKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdKey));

  const claimIncidentAtom = getAtom();

  const getSectionId = () => {
    if (getQueryValue(INCIDENT_BODY_SECTION) !== "") {
      return parseInt(getQueryValue(INCIDENT_BODY_SECTION));
    }
    if (claimIncidentAtom?.claimIncidentInformation?.incidentSection) {
      return claimIncidentAtom?.claimIncidentInformation?.incidentSection;
    }
    return IncidentBodySectionEnums.NEW_INCIDENT;
  };

  // In Case that will be different screens
  const renderIncidentSection = (sectionId: number) => {
    switch (sectionId) {
      default:
        return (
          <AddNewIncidentNew
            insuredId={insuredId}
            incidentId={parseInt(isIncidentOpen ?? "-1")}
            tabKey={tabKey}
          />
        );
    }
  };

  return <>{renderIncidentSection(getSectionId())}</>;
};

export default Incident;
