import { Box, Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { DetailsConfigurationDto } from "../../../../../../dtos/details-configuration-dto";
import { IncidentContainerDto } from "../../../../../../dtos/incident-container-dto";
import { SubSideNavChildrenIndexEnum } from "../../../../../../dtos/sub-side-nav-children-index-enum";
import { SubSideNavItemDestinationsEnum } from "../../../../../../dtos/sub-side-nav-item-destinations-enum";
import { SubSideNavParentsIndexEnum } from "../../../../../../dtos/sub-side-nav-parents-index-enum";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { getQueryStringsURL } from "../../../../../../utilities/URLUtilities_OBSOLETE";

import {
  INSURED_PARENT_ITEM,
  INSURED_CHILD_ITEM,
  INSURED_BODY_SECTION,
  INCIDENT_ID,
  INCIDENT_BODY_SECTION,
  INCIDENT_STEPPER_SECTION,
} from "../../../../../../utilities/queryStringsHash";
import {
  Col,
  Collapse,
  Input,
  InputAddress,
  Row,
  Select,
} from "../../../../../TrueUI";
import InputMemo from "../../../../../TrueUI/Inputs/InputMemo";
import Link from "../../../../../TrueUI/Typography/Link";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import { spreadDetailsContainersInformationWithTarget } from "../spreadClaimTabsFunctions";
import { formInputConfiguration } from "../../../../../../utilities/inputPropsConfigurationFunctions";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { IncidentBodySectionEnums } from "../../../../../../dtos/incident-body-section-enums";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";

type ClaimIncidentContainerProps = {
  configuration?: DetailsConfigurationDto;
  claimIncidentData?: IncidentContainerDto;
  errorDetails?: any;
  insuredIdAtomKey?: string;
  hasClaimsManagementPermissions: boolean;
};

const RowElementWithFullWidth = ({ children }) => (
  <Row
    {...rowWithNoMarginNorGutter}
    verticalAlign="flex-start"
    horizontalAlign="flex-start"
  >
    <Col
      breakpoints={{ md: 12, lg: 12, xl: 12 }}
      horizontalGutter="5px"
      horizontalAlign="flex-start"
    >
      {children}
    </Col>
  </Row>
);

const ClaimIncidentContainer: FC<ClaimIncidentContainerProps> = ({
  claimIncidentData,
  configuration,
  errorDetails,
  insuredIdAtomKey = "",
  hasClaimsManagementPermissions,
}) => {
  const [incidentUI, setIncidentUI] = useState(claimIncidentData ?? null);
  const { setAtom, getAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const incidentStepperSection = 0;

  const readOnly = conditionHasValue(incidentUI?.isExpanded)
    ? !incidentUI?.isExpanded
    : true;

  const setIncidentData = (updatedContainerData: any) => {
    const atomValue = getAtom();
    const newAtomValue = spreadDetailsContainersInformationWithTarget(
      atomValue,
      "incidentContainerData",
      updatedContainerData
    );
    setAtom(newAtomValue);
    setIncidentUI(updatedContainerData);
  };

  const updateData = (fieldName: string, value: any) => {
    setIncidentData({
      ...incidentUI,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    setIncidentUI(claimIncidentData ?? null);
  }, [claimIncidentData]);

  const listenerIncidentComponent = useRecoilValue(
    TriggerComponentUpdateAtom("incidentComponent")
  );

  useEffect(() => {
    if (listenerIncidentComponent !== null) {
      const updatedAtom = getAtom();
      setIncidentUI({
        ...updatedAtom?.claimTabsInformation?.claimDetailsTab
          ?.detailsContainersInformation?.incidentContainerData,
      });
    }
  }, [listenerIncidentComponent]);

  useEffect(() => {
    if (conditionHasValue(errorDetails)) updateData("isExpanded", true);
  }, [errorDetails]);

  return (
    <Collapse
      id="claim-details-incident-collapse"
      collapsedHeight={"225px"}
      title={
        <Link
          linkFontType={"BOLD_TITLE"}
          displayValue={"Incident"}
          name="incidentCollapseTitle"
        ></Link>
      }
      fontType="BOLD_TITLE"
      hasContentOnReadOnly
      content={
        readOnly ? (
          <Stack width={"100%"} zIndex={99}>
            <Row
              {...rowWithNoMarginNorGutter}
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              allowWrap
            >
              <Col
                breakpoints={{ md: 2, lg: 2, xl: 2 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
                verticalAlignSelf={"stretch"}
              >
                <Link
                  href={getQueryStringsURL([
                    {
                      nameOfHash: INSURED_PARENT_ITEM,
                      valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
                    },
                    {
                      nameOfHash: INSURED_CHILD_ITEM,
                      valueOfHash: SubSideNavChildrenIndexEnum.CLAIMS_INCIDENTS,
                    },
                    {
                      nameOfHash: INSURED_BODY_SECTION,
                      valueOfHash:
                        SubSideNavItemDestinationsEnum.INCIDENTS_INCIDENT_SECTIONS,
                    },
                    {
                      nameOfHash: INCIDENT_ID,
                      valueOfHash: incidentUI?.incidentID?.toString() ?? "",
                    },
                    {
                      nameOfHash: INCIDENT_BODY_SECTION,
                      valueOfHash: IncidentBodySectionEnums.NEW_INCIDENT,
                    },
                    {
                      nameOfHash: INCIDENT_STEPPER_SECTION,
                      valueOfHash: 0,
                    },
                  ])}
                  label="Incident ID"
                  labelPosition="top"
                  displayValue={incidentUI?.incidentID?.toString()}
                  linkFontType="BODY"
                  labelFontType="CAPTION"
                  name="incidentCollapseIncidentIdLink"
                />
              </Col>
              <Col
                breakpoints={{ md: 7, lg: 7, xl: 7 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  id="readonly-id-incident-location"
                  name="incidentCollapseReadOnlyIncidentLocation"
                  label="Incident Location"
                  inputFontType="BODY"
                  labelFontType="CAPTION"
                  readOnly
                  value={`${incidentUI?.incidentLocation}${
                    (incidentUI?.address ?? "") !== ""
                      ? ` - ${incidentUI?.address}`
                      : ""
                  }`}
                />
              </Col>
              <Col
                breakpoints={{ md: 3, lg: 3, xl: 3 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  id="id-ocurred-on-premises"
                  name="incidentCollapseReadOnlyOcurredPremises"
                  label="Occurred on Premises"
                  inputFontType="BODY"
                  labelFontType="CAPTION"
                  options={configuration?.yesNoAndEmptyOptions ?? []}
                  readOnly
                  value={incidentUI?.onPremises}
                  onChange={(value) => {
                    updateData("onPremises", value);
                  }}
                />
              </Col>
            </Row>
            <RowElementWithFullWidth>
              <Input
                id="id-incident-description"
                name="incidentCollapseReadOnlyIncidentDescription"
                label="Incident Description"
                inputFontType="BODY"
                labelFontType="CAPTION"
                readOnly
                value={incidentUI?.incidentDescription}
                onChangeRawValue={(value) =>
                  updateData("incidentDescription", value)
                }
                resize={false}
                variant={"standard"}
                rows={1}
                inputWidth="96%"
              />
            </RowElementWithFullWidth>
            <RowElementWithFullWidth>
              <Input
                id="id-injury-description"
                name="incidentCollapseReadOnlyInjuryDescription"
                label="Injury Description"
                inputFontType="BODY"
                labelFontType="CAPTION"
                readOnly
                value={incidentUI?.injuryDescription}
                onChangeRawValue={(value) =>
                  updateData("injuryDescription", value)
                }
                resize={false}
                variant={"standard"}
                rows={1}
                inputWidth="96%"
              />
            </RowElementWithFullWidth>
          </Stack>
        ) : (
          <Box width={"100%"}>
            <Row
              {...rowWithNoMarginNorGutter}
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              allowWrap
            >
              <Col
                breakpoints={{ md: 2, lg: 2, xl: 2 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Link
                  {...formInputConfiguration(
                    "id-incident-id",
                    "incidentCollapseIncidentId",
                    "Incident ID",
                    true
                  )}
                  href={getQueryStringsURL([
                    {
                      nameOfHash: INSURED_PARENT_ITEM,
                      valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
                    },
                    {
                      nameOfHash: INSURED_CHILD_ITEM,
                      valueOfHash: SubSideNavChildrenIndexEnum.CLAIMS_INCIDENTS,
                    },
                    {
                      nameOfHash: INSURED_BODY_SECTION,
                      valueOfHash:
                        SubSideNavItemDestinationsEnum.INCIDENTS_INCIDENT_SECTIONS,
                    },
                    {
                      nameOfHash: INCIDENT_ID,
                      valueOfHash: incidentUI?.incidentID?.toString() ?? "",
                    },
                    {
                      nameOfHash: INCIDENT_BODY_SECTION,
                      valueOfHash: IncidentBodySectionEnums.NEW_INCIDENT,
                    },
                    {
                      nameOfHash: INCIDENT_STEPPER_SECTION,
                      valueOfHash: incidentStepperSection,
                    },
                  ])}
                  labelPosition="top"
                  displayValue={incidentUI?.incidentID?.toString()}
                  containerWidth="92%"
                />
              </Col>
              <Col
                breakpoints={{ md: 7, lg: 7, xl: 7 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={23}
                  {...formInputConfiguration(
                    "id-incident-location",
                    "incidentCollapseIncidentLocation",
                    "Incident Location",
                    !hasClaimsManagementPermissions
                  )}
                  inputFontType="BODY"
                  value={`${incidentUI?.incidentLocation}${
                    (incidentUI?.address ?? "") !== ""
                      ? ` - ${incidentUI?.address}`
                      : ""
                  }`}
                  onChange={(value) => {
                    updateData("incidentLocation", value);
                  }}
                  readOnly
                />
              </Col>
              <Col
                breakpoints={{ md: 3, lg: 3, xl: 3 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  tabIndex={24}
                  {...formInputConfiguration(
                    "id-on-premises",
                    "incidentCollapseOnPremises",
                    "On Premises?",
                    !hasClaimsManagementPermissions
                  )}
                  inputFontType="BODY"
                  options={configuration?.yesNoAndEmptyOptions ?? []}
                  value={incidentUI?.onPremises}
                  onChange={(value) => {
                    updateData("onPremises", value);
                  }}
                />
              </Col>
            </Row>
            <Row
              {...rowWithNoMarginNorGutter}
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
            >
              <Col
                breakpoints={{ md: 12, lg: 12, xl: 12 }}
                horizontalGutter="0px"
                horizontalAlign="flex-start"
              >
                <InputAddress
                  tabIndex={25}
                  id="id-incident-address"
                  name="incidentCollapseIncidentAddress"
                  initValueAddress1={incidentUI?.address ?? ""}
                  initValueAddress2={incidentUI?.address2}
                  initValueCity={incidentUI?.city ?? ""}
                  initValueStateCode={incidentUI?.state ?? ""}
                  initValuePostCode={incidentUI?.zip ?? ""}
                  inputFontType="BODY"
                  labelFontType="BOLD_CAPTION"
                  showSingleLabel={false}
                  readOnly={!hasClaimsManagementPermissions}
                  address1ErrorMessage={errorDetails?.address}
                  address2ErrorMessage={errorDetails?.address2}
                  cityErrorMessage={errorDetails?.city}
                  stateCodeErrorMessage={errorDetails?.state}
                  postCodeErrorMessage={errorDetails?.zip}
                  onChangeValueAddress1={(value) => {
                    updateData("address", value);
                  }}
                  onChangeValueAddress2={(value) => {
                    updateData("address2", value);
                  }}
                  onChangeStateCode={(value) => {
                    updateData("state", value);
                  }}
                  onChangeValueCity={(value) => {
                    updateData("city", value);
                  }}
                  onChangeValuePostCode={(value) => {
                    updateData("zip", value);
                  }}
                  variant={"filled"}
                />
              </Col>
            </Row>
            <RowElementWithFullWidth>
              <InputMemo
                tabIndex={31}
                {...formInputConfiguration(
                  "id-incident-description",
                  "incidentCollapseIncidentDescription",
                  "Incident Description",
                  !hasClaimsManagementPermissions
                )}
                inputFontType="BODY"
                value={incidentUI?.incidentDescription}
                errorMessage={errorDetails?.incidentDescription}
                onChangeRawValue={(value) =>
                  updateData("incidentDescription", value)
                }
              />
            </RowElementWithFullWidth>
            <RowElementWithFullWidth>
              <InputMemo
                tabIndex={32}
                {...formInputConfiguration(
                  "id-injury-description",
                  "incidentCollapseInjuryDescription",
                  "Injury Description",
                  !hasClaimsManagementPermissions
                )}
                inputFontType="BODY"
                value={incidentUI?.injuryDescription}
                errorMessage={errorDetails?.injuryDescription}
                onChangeRawValue={(value) =>
                  updateData("injuryDescription", value)
                }
              />
            </RowElementWithFullWidth>
            <RowElementWithFullWidth>
              <Input
                tabIndex={33}
                {...formInputConfiguration(
                  "id-activity-engaged-in",
                  "incidentCollapseActivityEngagedIn",
                  "Activity Engaged In",
                  !hasClaimsManagementPermissions
                )}
                inputFontType="BODY"
                value={incidentUI?.activityEngagedIn}
                errorMessage={errorDetails?.activityEngagedIn}
                onChangeRawValue={(value) =>
                  updateData("activityEngagedIn", value)
                }
              />
            </RowElementWithFullWidth>
            <RowElementWithFullWidth>
              <Input
                tabIndex={34}
                {...formInputConfiguration(
                  "id-equipment-used",
                  "incidentCollapseEquipmentUsed",
                  "Equipment Used",
                  !hasClaimsManagementPermissions
                )}
                inputFontType="BODY"
                value={incidentUI?.equipmentUsed}
                errorMessage={errorDetails?.equipmentUsed}
                onChangeRawValue={(value) => updateData("equipmentUsed", value)}
              />
            </RowElementWithFullWidth>
            <RowElementWithFullWidth>
              <Select
                tabIndex={35}
                {...formInputConfiguration(
                  "id-cause-of-injury",
                  "incidentCollapseCauseOfInjury",
                  "Cause Of Injury",
                  !hasClaimsManagementPermissions
                )}
                inputFontType="BODY"
                optionsMaxHeight="200px"
                options={configuration?.causeOfInjuryOptions ?? []}
                value={incidentUI?.causeOfInjuryId}
                onChange={(value) => {
                  updateData("causeOfInjuryId", parseInt(value));
                }}
              />
            </RowElementWithFullWidth>
            <RowElementWithFullWidth>
              <Select
                tabIndex={36}
                {...formInputConfiguration(
                  "id-nature-of-injury",
                  "incidentCollapseNatureOfInjury",
                  "Nature Of Injury",
                  !hasClaimsManagementPermissions
                )}
                inputFontType="BODY"
                optionsMaxHeight="200px"
                options={configuration?.natureOfInjuryOptions ?? []}
                value={incidentUI?.natureOfInjuryId}
                onChange={(value) => {
                  updateData("natureOfInjuryId", parseInt(value));
                }}
              />
            </RowElementWithFullWidth>
            <RowElementWithFullWidth>
              <Select
                tabIndex={37}
                {...formInputConfiguration(
                  "trending-category",
                  "incidentCollapseTrendingCategory",
                  "Trending Category",
                  !hasClaimsManagementPermissions
                )}
                inputFontType="BODY"
                optionsMaxHeight="200px"
                options={configuration?.trendingCategoryOptions ?? []}
                value={incidentUI?.trendingCategory}
                errorMessage={errorDetails?.trendingCategory}
                onChange={(value) => {
                  updateData("trendingCategory", value);
                }}
                firstOptionAsDefault={false}
              />
            </RowElementWithFullWidth>
            <RowElementWithFullWidth>
              <Input
                tabIndex={38}
                {...formInputConfiguration(
                  "id-osha-description",
                  "incidentCollapseOshaDescription",
                  "OSHA Description",
                  !hasClaimsManagementPermissions
                )}
                inputFontType="BODY"
                value={incidentUI?.oshaDescription}
                errorMessage={errorDetails?.oshaDescription}
                onChangeRawValue={(value) =>
                  updateData("oshaDescription", value)
                }
              />
            </RowElementWithFullWidth>
            <Row
              {...rowWithNoMarginNorGutter}
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              allowWrap
            >
              <Col
                breakpoints={{ md: 6, lg: 3, xl: 3 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  tabIndex={39}
                  {...formInputConfiguration(
                    "id-er-treatment",
                    "incidentCollapseErTreatment",
                    "ER",
                    !hasClaimsManagementPermissions
                  )}
                  inputFontType="BODY"
                  options={configuration?.yesNoAndEmptyOptions ?? []}
                  value={incidentUI?.erTreatment}
                  onChange={(value) => {
                    updateData("erTreatment", value);
                  }}
                />
              </Col>
              <Col
                breakpoints={{ md: 6, lg: 3, xl: 3 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  tabIndex={40}
                  {...formInputConfiguration(
                    "id-hospitalized",
                    "incidentCollapseHospitalized",
                    "Hospitalized",
                    !hasClaimsManagementPermissions
                  )}
                  inputFontType="BODY"
                  options={configuration?.yesNoAndEmptyOptions ?? []}
                  value={incidentUI?.hospitalized}
                  onChange={(value) => {
                    updateData("hospitalized", value);
                  }}
                />
              </Col>
              <Col
                breakpoints={{ md: 6, lg: 3, xl: 3 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  tabIndex={41}
                  {...formInputConfiguration(
                    "id-physical-restrictions",
                    "incidentCollapsePhysicalRestrictions",
                    "Physical Restrictions",
                    !hasClaimsManagementPermissions
                  )}
                  inputFontType="BODY"
                  options={configuration?.yesNoAndEmptyOptions ?? []}
                  value={incidentUI?.physicalRestrictions}
                  onChange={(value) => {
                    updateData("physicalRestrictions", value);
                  }}
                />
              </Col>
              <Col
                breakpoints={{ md: 6, lg: 3, xl: 3 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={42}
                  {...formInputConfiguration(
                    "id-other-claim-number",
                    "incidentCollapseOtherClaimNumber",
                    "Other Claim Number",
                    !hasClaimsManagementPermissions
                  )}
                  inputFontType="BODY"
                  value={incidentUI?.otherClaimNumber}
                  errorMessage={errorDetails?.otherClaimNumber}
                  maxLength={20}
                  onChangeRawValue={(value) =>
                    updateData("otherClaimNumber", value)
                  }
                />
              </Col>
            </Row>
          </Box>
        )
      }
      expanded={!readOnly}
      onChange={(value) => {
        updateData("isExpanded", value);
        setComponentTriggers(["buttonComponent"]);
      }}
    />
  );
};

export default ClaimIncidentContainer;
