import { FC } from "react";
import { BaseGridStructureBody } from "./BaseGridStructure";
import { useGridInstance } from "./Hooks/useGridInstance";
import { useRecoilValue } from "recoil";
import style from "./Styles/basegrid.module.css";
import BaseGridRenderProcessor_v3 from "./BaseGridRenderProcessor_v3";

const BaseGridBody: FC<any> = ({ uiid }) => {
  const bodyStructureId = `true_base_grid_structure_body_${uiid}`;
  const { instanceSelector, instanceInternalSelector } = useGridInstance(
    uiid,
    "BaseGridBody"
  );
  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());

  return instanceInternal?.isReadyToRender &&
    instanceInternal?.hasSortedAndFilteredProcessingCompleted ? (
    <BaseGridStructureBody id={bodyStructureId}>
      {/* Let's hang on to BaseGridRenderProcessor_v2 in the event we need to swap back. Let's re-review things so that we can possibly drop it in the a few months - Antonio (11/18/24) */}
      <BaseGridRenderProcessor_v3 uiid={uiid} />
    </BaseGridStructureBody>
  ) : (
    <div className={style.true_base_grid_no_result_found}>
      {instance?.advancedOptions?.noResultFoundMessageOverride ??
        "No results found."}
    </div>
  );
};

export default BaseGridBody;
