import { FC } from "react";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../../TrueUI";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import BaseTablePolicyDropdownAsyncFilter from "../../../Policy/BaseTablePolicyDropdownAsyncFilter";
import {
  columnOptionsProps,
  CustomFooterComponent,
  MultiTablePatternComponent,
} from "./InvoicesTableUtils";
import MultiAlignHeader from "../../../../TrueUI/Tables/MultiTable/MultiAlignHeader";

type InvoicesTableProps = {
  insuredId: number;
  tabKey: string;
  currentPolicy?: number | null;
  setCurrentPolicy: (currentPolicy: number | null) => void;
  editInvoiceIconClicked: (metaData: any) => void;
  addButtonClicked: () => void;
};

const InvoicesTable: FC<InvoicesTableProps> = ({
  insuredId,
  tabKey,
  currentPolicy,
  setCurrentPolicy,
  editInvoiceIconClicked,
  addButtonClicked,
}) => {
  const tableName = "invoices_multi_table";

  const invoicesConfig: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Invoice/GetInsuredInvoices?insuredId=${insuredId}&policyId=${
      currentPolicy ?? ""
    }`,
    tableType: "multi",
    filterOptions: [
      (actionOptions) =>
        BaseTablePolicyDropdownAsyncFilter({
          selectWidth: "230px",
          columnsNames: ["PolicyId"],
          insuredId: insuredId,
          filterOptions: actionOptions,
          showAllOption: false,
          labelText: "Policy:",
          onChange: (value) => {
            //This is because the policy dropdown is returning an string.
            setCurrentPolicy(typeof value !== "number" ? null : value);
          },
          tabKey: tabKey,
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "line-filter",
          columnsNames: ["Line"],
          filterOptions: actionOptions,
          usingExternalFiltering: true,
          labelText: "Line of Coverage:",
          optionsOverride: [{ displayName: "WC", stringValue: "WC" }],
          defaultValue: "WC",
        }),
    ],
    toolbarOptions: {
      addButtonText: "New Invoice",
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
    },
    columnOptions: columnOptionsProps,
    advancedOptions: {
      paginate: false,
      tableStyle: {
        gapColumnAtTheEnd: true,
        multiTableHeaderStyle: "transparent",
      },
      multiTableProperties: {
        MultiTablePatternComponent: MultiTablePatternComponent,
        MultiTableHeaderPatternComponent: (params, metaData, columnOptions) => {
          const titleParams = params ?? [];
          return (
            <MultiAlignHeader
              headerParams={titleParams}
              metaData={metaData}
              columnOptions={columnOptions}
              contextConfiguration={{
                editRow: () => {
                  editInvoiceIconClicked(metaData);
                },
              }}
            />
          );
        },
      },
      headerExportType: "aligned",
    },
    events: {
      addEventOverrideCallback: () => addButtonClicked(),
    },
    footerOptions: {
      CustomFooterComponent: (rows) => CustomFooterComponent(rows),
    },
  };

  useBaseTable(invoicesConfig);

  return (
    <div
      style={{
        marginTop: 10,
        display: "flex",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <BaseTable2 name={tableName} />
    </div>
  );
};

export default InvoicesTable;
