import { FC, useState } from "react";
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import { PolicyQuoteDocumentSplitterProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import DocumentGroup from "./DocumentGroup";
import DocumentPacket from "./DocumentPacket";
import DocumentIndividual from "./DocumentIndividual";

const DocumentSplitter: FC<PolicyQuoteDocumentSplitterProps> = ({
  insuredId,
  tabKey,
  programPackets,
  programDocuments,
  policyDocuments,
  selectedPackets,
  selectedDocuments,
  customJSONToPrint,
  onSelectedCheckboxDocument,
}) => {
  const [panes, setPanes] = useState<Array<any>>([
    { size: "20%", min: "15%", max: "30%" },
    { min: "40%", max: "70%" },
    { size: "30%", min: "20%", max: "40%" },
  ]);

  const onChangeSplitterPanes = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  return (
    <Splitter panes={panes} onChange={onChangeSplitterPanes}>
      <DocumentGroup
        programPackets={programPackets}
        selectedPackets={selectedPackets}
        onSelectedCheckboxDocument={onSelectedCheckboxDocument}
      />
      <DocumentPacket
        tabKey={tabKey}
        insuredId={insuredId}
        programDocuments={programDocuments}
        customJSONToPrint={customJSONToPrint}
      />
      <DocumentIndividual
        tabKey={tabKey}
        insuredId={insuredId}
        policyDocuments={policyDocuments}
        selectedDocuments={selectedDocuments}
        customJSONToPrint={customJSONToPrint}
        onSelectedCheckboxDocument={onSelectedCheckboxDocument}
      />
    </Splitter>
  );
};

export default DocumentSplitter;
