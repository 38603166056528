import { FC } from "react";
import styles from "../Styles/basegrid.module.css";

type BaseGridStructureBodyProperties = {
  id?: string;
  children?: any;
};

const BaseGridStructureBody: FC<BaseGridStructureBodyProperties> = ({
  id,
  children,
}) => {
  return (
    <div id={id} className={styles.true_base_grid_body}>
      <>{children}</>
    </div>
  );
};

export default BaseGridStructureBody;
