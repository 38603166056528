import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { Input } from "../../../TrueUI";
import { ColumnOptionsProperties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { PayrollReportDetailRowDto } from "../../../../dtos/payroll-report-detail-row-dto";
import {
  formatNegativeNumbers,
  formatToCurrency,
  getNumberAsStringWithComasWithDecimals,
  parseFloatValueIfExist,
} from "../../../../utilities/stringFunctions";
import { sumInt, sumFloat } from "../../../../utilities/arrayFunctions";
import { PayrollReportAdjustmentTypeEnum } from "../../../../dtos/payroll-report-adjustment-type-enum";
import { PayrollReportAdjustmentsDto } from "../../../../dtos/payroll-report-adjustments-dto";
import { EditedPayrollReportDetailsDto } from "../../../../dtos/edited-payroll-report-details-dto";

export const columnOptionsProps: ColumnOptionsProperties<any>[] = [
  { fieldName: "PayrollReportId", width: 0 },
  { fieldName: "PayrollReportDetailId", width: 0 },
  { fieldName: "NameId", width: 0 },
  { fieldName: "State", width: 5 },
  { fieldName: "ClassCode", width: 5 },
  { fieldName: "Description", width: 40 },
  { fieldName: "NumEE", width: 10, align: "right" },
  { fieldName: "NetPayroll", width: 10, align: "right", decimalScale: 2 },
  { fieldName: "BaseRate", width: 10, align: "right", decimalScale: 2 },
  { fieldName: "ModifiedRate", width: 10, align: "right", decimalScale: 2 },
  { fieldName: "Amount", width: 10, align: "right", decimalScale: 2 },
];

export const getColumnWidth = (columns, column) => {
  const selected = columnOptionsProps?.find((c) => c?.fieldName === column);
  if (conditionHasValue(selected)) {
    return `${selected?.width}%`;
  }
  return `${100 / (columns?.length ?? 1)}%`;
};

const caclDescriptionColumnWidth = () => {
  const description = columnOptionsProps?.find(
    (c) => c?.fieldName === "Description"
  );
  const numEE = columnOptionsProps?.find((c) => c?.fieldName === "NumEE");
  const netPayroll = columnOptionsProps?.find(
    (c) => c?.fieldName === "NetPayroll"
  );
  const baseRate = columnOptionsProps?.find((c) => c?.fieldName === "BaseRate");
  const total =
    (parseFloatValueIfExist(description?.width) ?? 0) +
    (parseFloatValueIfExist(numEE?.width) ?? 0) +
    (parseFloatValueIfExist(netPayroll?.width) ?? 0) +
    (parseFloatValueIfExist(baseRate?.width) ?? 0);

  return `${total}%`;
};

export const getColumnWidthForAdjustments = (columns, column) => {
  switch (column) {
    case "Description":
      return caclDescriptionColumnWidth();
    case "NumEE":
      return "0";
    case "NetPayroll":
      return "0";
    case "BaseRate":
      return "0";
    default:
      return getColumnWidth(columns, column);
  }
};

export const getCellValueRowOne = (
  column,
  totalEmployees,
  totalPayroll,
  totalAmount,
  hasAdjustments?: boolean
) => {
  switch (column) {
    case "Description":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"text"}
            id={"total"}
            name={"total"}
            value={hasAdjustments ? "Subtotal" : "Totals"}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
          />
        </div>
      );
    case "NumEE":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"number"}
            id={"total-employees"}
            name={"total-employees"}
            value={totalEmployees}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
            key={totalEmployees}
          />
        </div>
      );
    case "NetPayroll":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"fixedCurrency"}
            id={"total-payroll"}
            name={"total-payroll"}
            value={totalPayroll}
            inputFontType={"BOLD_BODY"}
            align="right"
            prefix=""
            readOnly
            key={totalPayroll}
          />
        </div>
      );
    case "Amount":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"fixedCurrency"}
            id={"total-amount"}
            name={"total-amount"}
            value={totalAmount}
            inputFontType={"BOLD_BODY"}
            align="right"
            prefix=""
            readOnly
            key={totalAmount}
          />
        </div>
      );
    default:
      return "";
  }
};

export const getCellValueRowTwo = (column, totalPaid) => {
  switch (column) {
    case "ModifiedRate":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"text"}
            id={"paid"}
            name={"paid"}
            value={"Paid"}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
          />
        </div>
      );
    case "Amount":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"fixedCurrency"}
            id={"total-paid"}
            name={"total-paid"}
            value={totalPaid}
            inputFontType={"BOLD_BODY"}
            align="right"
            prefix=""
            readOnly
            key={totalPaid}
          />
        </div>
      );
    default:
      return "";
  }
};

export const getCellValueRowThree = (column, totalAmount, totalPaid) => {
  switch (column) {
    case "ModifiedRate":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"text"}
            id={"balance"}
            name={"balance"}
            value={"Balance"}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
          />
        </div>
      );
    case "Amount":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            id={"balance"}
            name={"balance"}
            value={
              totalAmount - totalPaid < 0
                ? `(${formatToCurrency(Math.abs(totalAmount - totalPaid))})`
                : `${formatToCurrency(totalAmount - totalPaid)}`
            }
            inputFontType={"BOLD_BODY"}
            align="right"
            prefix=""
            readOnly
            key={totalAmount - totalPaid}
          />
        </div>
      );
    default:
      return "";
  }
};

export const getCellValueAdjustmentRow = (
  adjustment: PayrollReportAdjustmentsDto,
  column: string,
  totalAmount: number,
  onChangeAdjustment: (
    updatedValue: any,
    column: string,
    adjustmentId: number
  ) => void
) => {
  const isPercentage =
    adjustment.adjustmentType ===
      PayrollReportAdjustmentTypeEnum.PERCENT_CREDIT ||
    adjustment.adjustmentType === PayrollReportAdjustmentTypeEnum.PERCENT_DEBIT;
  switch (column) {
    case "Description":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"text"}
            id={"adjustment-description"}
            name={"adjustment-description"}
            value={adjustment.adjustmentDescription}
            inputFontType={"BODY"}
            align="left"
            readOnly
          />
        </div>
      );
    case "ModifiedRate":
      return isPercentage ? (
        <div
          style={{
            width: "100%",
            paddingRight: adjustment.isEditable ? "0px" : "12px",
          }}
        >
          <Input
            type={"currency"}
            id={"adjustment-value"}
            name={"adjustment-value"}
            value={adjustment.adjustmentValue ?? 0}
            inputFontType={"BODY"}
            align="right"
            prefix=""
            suffix="%"
            maxLength={7}
            decimalScale={1}
            readOnly={!adjustment.isEditable}
            onChangeRawValue={(e) =>
              onChangeAdjustment(
                e,
                "adjustmentValue",
                adjustment.payrollReportAjustmentId
              )
            }
          />
        </div>
      ) : (
        ""
      );
    case "Amount":
      return (
        <div
          style={{
            width: "100%",
            paddingRight: adjustment.isEditable ? "0px" : "12px",
          }}
        >
          <Input
            type={
              isPercentage || !adjustment.isEditable ? "text" : "fixedCurrency"
            }
            id={"adjustment-amount"}
            name={"adjustment-amount"}
            value={getAdjustmentFormattedValue(adjustment, totalAmount)}
            inputFontType={"BODY"}
            align="right"
            prefix=""
            maxLength={10}
            decimalScale={2}
            readOnly={isPercentage || !adjustment.isEditable}
            onChangeRawValue={(e) => {
              onChangeAdjustment(
                e,
                "adjustmentAmount",
                adjustment.payrollReportAjustmentId
              );
            }}
          />
        </div>
      );
    default:
      return "";
  }
};

export const getCellValueAdjustmentTotals = (column, totalDue) => {
  switch (column) {
    case "Description":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"text"}
            id={"total-due"}
            name={"total-due"}
            value={"Total Due"}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
          />
        </div>
      );
    case "Amount":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"fixedCurrency"}
            id={"total-amount"}
            name={"total-amount"}
            value={totalDue}
            inputFontType={"BOLD_BODY"}
            align="right"
            prefix=""
            readOnly
          />
        </div>
      );
    default:
      return "";
  }
};

const getAdjustmentFormattedValue = (
  adjustment: PayrollReportAdjustmentsDto,
  total: number
) => {
  switch (adjustment.adjustmentType) {
    case PayrollReportAdjustmentTypeEnum.AMOUNT_CREDIT:
      return adjustment.isEditable
        ? getNumberAsStringWithComasWithDecimals(
            adjustment.adjustmentAmount ?? 0,
            2
          )
        : formatNegativeNumbers(adjustment.adjustmentAmount * -1);
    case PayrollReportAdjustmentTypeEnum.AMOUNT_DEBIT:
      return getNumberAsStringWithComasWithDecimals(
        adjustment.adjustmentAmount ?? 0,
        2
      );
    case PayrollReportAdjustmentTypeEnum.PERCENT_CREDIT:
      const calcAmount =
        adjustment.adjustmentValue > 0
          ? (total * adjustment.adjustmentValue) / 100
          : 0;
      return calcAmount > 0
        ? formatNegativeNumbers(calcAmount * -1)
        : getNumberAsStringWithComasWithDecimals(0, 2);
    case PayrollReportAdjustmentTypeEnum.PERCENT_DEBIT:
      return getNumberAsStringWithComasWithDecimals(
        (total * (adjustment.adjustmentValue ?? 0)) / 100,
        2
      );
    default:
      return "";
  }
};

const updateReferenceObjectByKeyValue = (referenceObject: any, key: string) =>
  Object.values(referenceObject).reduce(
    (accumulator: number, obj: any) => accumulator + obj?.[key],
    0
  );

export const getUpdatedTotals = (
  data: PayrollReportDetailRowDto[],
  changedData: PayrollReportDetailRowDto[],
  rows: any
) => {
  if (changedData.length > 0) {
    // Step 1: Create a reference object from the initial data array
    const referenceObject = data.reduce((obj, item) => {
      obj[item.payrollReportDetailId] = item;
      return obj;
    }, {});
    // Step 2: Iterate through changedData and update the values in the reference object
    changedData.forEach((changedItem) => {
      const { payrollReportDetailId, numEE, netPayroll, amount } = changedItem;
      if (referenceObject[payrollReportDetailId] !== undefined) {
        referenceObject[payrollReportDetailId].numEE = numEE;
        referenceObject[payrollReportDetailId].netPayroll = netPayroll;
        referenceObject[payrollReportDetailId].amount = amount;
      }
    });

    // Step 3: Calculate the updated sum of the 'value' property from the reference object
    const updatedNumEE = updateReferenceObjectByKeyValue(
      referenceObject,
      "numEE"
    );

    const updatedNetPayroll = updateReferenceObjectByKeyValue(
      referenceObject,
      "netPayroll"
    );

    const updatedAmount = updateReferenceObjectByKeyValue(
      referenceObject,
      "amount"
    );
    return {
      totalEmployees: Number(updatedNumEE.toFixed(2)),
      totalPayroll: Number(updatedNetPayroll.toFixed(2)),
      totalAmount: Number(updatedAmount.toFixed(2)),
    };
  } else {
    return {
      totalEmployees: Number(
        sumInt(rows?.map((row) => row?.Metadata?.TotalEmployees ?? 0)).toFixed(
          2
        )
      ),
      totalPayroll: Number(
        sumFloat(rows?.map((row) => row?.Metadata?.TotalPayroll ?? 0)).toFixed(
          2
        )
      ),
      totalAmount: Number(
        sumFloat(rows?.map((row) => row?.Metadata?.TotalAmount ?? 0)).toFixed(2)
      ),
    };
  }
};

export const getUpdatedTotalsDetail = (rows: any[]) => {
  return {
    totalEmployees: sumInt(rows?.map((row) => row?.NumEE ?? 0)),
    totalPayroll: sumFloat(rows?.map((row) => row?.NetPayroll ?? 0)),
    totalAmount: sumFloat(rows?.map((row) => row?.Amount ?? 0)),
  };
};

export const getAdjustmentTotal = (
  adjustments: PayrollReportAdjustmentsDto[],
  subTotal
) => {
  const totalAmountCredit = adjustments.reduce((sum, adjustment) => {
    return adjustment.adjustmentType ===
      PayrollReportAdjustmentTypeEnum.AMOUNT_CREDIT
      ? sum + Number(Math.abs(adjustment.adjustmentAmount).toFixed(2))
      : sum;
  }, 0);
  const totalAmountDebit = adjustments.reduce((sum, adjustment) => {
    return adjustment.adjustmentType ===
      PayrollReportAdjustmentTypeEnum.AMOUNT_DEBIT
      ? sum + Number(Math.abs(adjustment.adjustmentAmount).toFixed(2))
      : sum;
  }, 0);
  const totalCalcPercentDebit = adjustments.reduce((sum, adjustment) => {
    return adjustment.adjustmentType ===
      PayrollReportAdjustmentTypeEnum.PERCENT_DEBIT
      ? sum +
          (conditionHasValue(adjustment.adjustmentValue)
            ? Number(
                (
                  (subTotal * Math.abs(adjustment.adjustmentValue)) /
                  100
                ).toFixed(2)
              )
            : 0)
      : sum;
  }, 0);
  const totalCalcPercentCredit = adjustments.reduce((sum, adjustment) => {
    return adjustment.adjustmentType ===
      PayrollReportAdjustmentTypeEnum.PERCENT_CREDIT
      ? sum +
          (conditionHasValue(adjustment.adjustmentValue)
            ? Number(
                (
                  (subTotal * Math.abs(adjustment.adjustmentValue)) /
                  100
                ).toFixed(2)
              )
            : 0)
      : sum;
  }, 0);

  const total =
    subTotal -
    totalAmountCredit +
    totalAmountDebit -
    totalCalcPercentCredit +
    totalCalcPercentDebit;

  return total > 0 ? total : 0;
};

export const checkUpdated = (
  editedData: Partial<EditedPayrollReportDetailsDto> | null | undefined
) => {
  if (conditionHasValue(editedData)) {
    const editedAdjustments = editedData.adjustmentsRows?.filter(
      (adjustment) => adjustment.isUpdated
    );
    return (
      ((editedData?.detailsRows?.length ?? 0) > 0 ||
        editedAdjustments?.length) ??
      0 > 0
    );
  }
  return false;
};
