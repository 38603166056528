import AdminToolsMain from "./components/AdminTools/Main/AdminToolsMain";
import RouteError from "./RouteError";
import { Underwriting } from "./components/Underwriting";
import { UserPermissions, UserPreferences } from "./components/User";
import { Insured } from "./components/Insured";
import { Dashboard } from "./components/Dashboard";
import { AgencyInstanceWrapper, AgencyTable } from "./components/Agency";
import { LiveStyleGuide, Sandbox } from "./components/Sandbox";
import { ProgramMain } from "./components/AdminTools/AdminProgramMain";
import { Program } from "./components/AdminTools/AdminProgram";
import { AdminReference } from "./components/AdminTools/AdminReference";
import Claims from "./components/Claims/Claims";
import EmmaSandbox from "./components/Sandbox/EmmaSandbox";
import FelipeSandbox from "./components/Sandbox/FelipeSandbox";
import AntonioSandbox from "./components/Sandbox/AntonioSandbox/AntonioSandbox";
import NoeSandbox from "./components/Sandbox/NoeSandbox";
import AlbertoSandBox from "./components/Sandbox/AlbertoSandBox";
import AlfonsoSandbox from "./components/Sandbox/AlfonsoSandbox";
import IvanSandbox from "./components/Sandbox/IvanSandbox/IvanSandbox";
import OzSandbox from "./components/Sandbox/OzSandbox";
import MaferSandBox from "./components/Sandbox/MaferSandBox";
import MergeSandbox from "./components/Sandbox/MergeSandbox";
import Acord130Main from "./components/Insured/Acord130/Acord130Main";
import BillingMain from "./components/Billing/BillingMain";
import { PermissionsEnums } from "./dtos/permissions-enums";
import AuthenticatedRoutes from "./components/Security/AuthenticatedRoutes";
import { FileUploadMain } from "./components/FileUpload";
import { Audit } from "./components/Audit";
import Logout from "./Logout";
import AdminSecurity from "./components/AdminTools/AdminSecurity/AdminSecurity";
import PaymentRedirectPostBack from "./components/Insured/Billing/Activity/PaymentsTab/CreditCardPaymentRedirect/PaymentRedirectPostBack";
import AdminSecurityUser from "./components/AdminTools/AdminSecurity/UserView/AdminSecurityUser";

const ENV_LOCAL = "local";
const ENV_DEV = "dev";
const ENV_QA = "qa";

const AppRoutes = (
  <AuthenticatedRoutes
    defaultPermissions={[
      PermissionsEnums.TRUE_ADMIN,
      PermissionsEnums.PROGRAM_ADMIN,
    ]}
    routesOptions={[
      {
        path: "/",
        element: <Dashboard />,
        noAuth: true,
      },
      {
        path: "/home",
        element: <Dashboard />,
        noAuth: true,
      },
      {
        path: "/audit",
        element: <Audit />,
        noAuth: true, // TODO - not sure what permissions this needs
      },
      {
        path: "/mailroom",
        element: <FileUploadMain />,
        noAuth: true, // TODO - not sure what permissions this needs
      },
      {
        path: "/user-permissions",
        element: <UserPermissions />,
        permissions: [PermissionsEnums.PROGRAM_ADMIN],
      },
      {
        path: "/user-preferences",
        element: <UserPreferences />,
        noAuth: true,
      },
      {
        path: "/acord130/:newSubmissionForm",
        element: <Acord130Main />,
        noAuth: true, // TODO - not sure what permissions this needs
      },
      {
        path: "/insured",
        element: <Insured />,
        noAuth: true,
      },
      {
        path: "/insured/user",
        element: <Insured />,
        noAuth: true,
      },
      {
        path: "/insured/item",
        element: <Insured />,
        noAuth: true,
      },
      {
        path: "/insured/:insuredId",
        element: <Insured />,
        noAuth: true,
      },

      {
        path: "/agency",
        element: <AgencyTable />,
        permissions: [
          PermissionsEnums.AGENCY_ADMIN,
          PermissionsEnums.AGENCY_VIEW,
          PermissionsEnums.AGENCY_BIND,
          PermissionsEnums.AGENCY_CLAIMS,
          PermissionsEnums.AGENCY_GENERAL,
          PermissionsEnums.AGENCY_SUBMISSION,
        ],
      },
      {
        path: "/agency/:agencyId",
        element: <AgencyInstanceWrapper />,
        permissions: [
          PermissionsEnums.AGENCY_ADMIN,
          PermissionsEnums.AGENCY_VIEW,
          PermissionsEnums.AGENCY_BIND,
          PermissionsEnums.AGENCY_CLAIMS,
          PermissionsEnums.AGENCY_GENERAL,
          PermissionsEnums.AGENCY_SUBMISSION,
        ],
      },
      {
        path: "/underwriting",
        element: <Underwriting />,
        permissions: [
          PermissionsEnums.UNDERWRITTER,
          PermissionsEnums.UNDERWRITING_VIEW,
          PermissionsEnums.UNDERWRITING_QUOTE,
        ],
      },
      {
        path: "/billing",
        element: <BillingMain />,
        permissions: [
          PermissionsEnums.BILLING_ADMIN,
          PermissionsEnums.BILLING_VIEW,
          PermissionsEnums.BILLING_USER,
          PermissionsEnums.BILLING_PAYMENT,
        ],
      },
      {
        path: "/style-guide",
        element: <LiveStyleGuide />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/claims",
        element: <Claims />,
        permissions: [
          PermissionsEnums.CLAIMS_ADMIN,
          PermissionsEnums.CLAIMS_ADJUSTER,
          PermissionsEnums.CLAIMS_MANAGER,
          PermissionsEnums.CLAIMS_VIEW,
        ],
      },
      {
        path: "/reports",
        element: <OzSandbox />,
        noAuth: true,
      },
      {
        path: "/admin-tools",
        element: <AdminToolsMain />,
        permissions: [
          PermissionsEnums.PROGRAM_ADMIN,
          PermissionsEnums.TRUE_ADMIN,
          PermissionsEnums.CUSTOMER_ADMIN,
        ],
      },
      {
        path: "/admin-tools/program",
        element: <ProgramMain />,
        permissions: [PermissionsEnums.PROGRAM_ADMIN],
      },
      {
        path: "/admin-tools/program/new",
        element: <Program />,
        permissions: [PermissionsEnums.PROGRAM_ADMIN],
      },
      {
        path: "/admin-tools/program/:programId",
        element: <Program />,
        permissions: [PermissionsEnums.PROGRAM_ADMIN],
      },
      {
        path: "/admin-tools/reference-tables",
        element: <AdminReference />,
        permissions: [PermissionsEnums.PROGRAM_ADMIN],
      },

      {
        path: "/admin-tools/security",
        element: <AdminSecurity />,
        permissions: [
          PermissionsEnums.PROGRAM_ADMIN,
          PermissionsEnums.CUSTOMER_ADMIN,
        ],
      },
      {
        path: "/admin-tools/security/user/:userId",
        element: <AdminSecurityUser />,
        permissions: [
          PermissionsEnums.PROGRAM_ADMIN,
          PermissionsEnums.CUSTOMER_ADMIN,
        ],
      },
      {
        path: "/",
        element: <Dashboard />,
        permissions: [PermissionsEnums.READ_ONLY],
      },
      {
        path: "/sandbox",
        element: <Sandbox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/felipe",
        element: <FelipeSandbox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/antonio",
        element: <AntonioSandbox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/noe",
        element: <NoeSandbox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/alberto",
        element: <AlbertoSandBox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/alfonso",
        element: <AlfonsoSandbox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/ivan",
        element: <IvanSandbox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/emmasandbox",
        element: <EmmaSandbox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/oz",
        element: <OzSandbox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/mafer",
        element: <MaferSandBox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/mergesandbox",
        element: <MergeSandbox />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/acord130",
        element: <Acord130Main />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/payment-redirect",
        element: <PaymentRedirectPostBack />,
        noAuth: true, // TODO - not sure what permissions this needs
      },
      {
        path: "/*",
        element: <RouteError />,
        noAuth: true,
      },
      {
        path: "/logout",
        element: <Logout />,
        noAuth: true,
      },
    ]}
  />
);

export default AppRoutes;
