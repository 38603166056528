import { FC } from "react";
import styles from "../Styles/basegrid.module.css";

type BaseGridEndRowProperties = {
  rowHeight: number;
  scrollToTop?: () => void;
};
const BaseGridEndRow: FC<BaseGridEndRowProperties> = ({
  rowHeight,
  scrollToTop,
}) => {
  return (
    <div
      className={styles.true_base_grid_end_row}
      style={{
        height: `${rowHeight}px`,
      }}
      onClick={scrollToTop}
    >
      You've reached the end, click here to return to the top
    </div>
  );
};

export default BaseGridEndRow;
