import { Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  Col,
  Collapse,
  Input,
  InputDate,
  Row,
  Select,
  Switch,
} from "../../../../../TrueUI";
import Link from "../../../../../TrueUI/Typography/Link";
import { DetailsContainerDto } from "../../../../../../dtos/details-container-dto";
import { DetailsConfigurationDto } from "../../../../../../dtos/details-configuration-dto";
import { SelectOptions } from "../../../../../../dtos/select-options";
import {
  getWeekDay,
  isDateAfterDate,
  isEndDateSameOrAfterStartDate,
} from "../../../../../../utilities/dateFunctions";
import { ClaimReferenceDto } from "../../../../../../dtos/claim-reference-dto";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import { spreadDetailsContainersInformationWithTarget } from "../spreadClaimTabsFunctions";
import { useRecoilValue } from "recoil";
import { formInputConfiguration } from "../../../../../../utilities/inputPropsConfigurationFunctions";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import {
  addEmptyOption,
  yesNoBlankOptions,
} from "../../../../../../utilities/selectFunctions";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";

type ClaimDetailsContainerProps = {
  configuration?: DetailsConfigurationDto;
  claimDetailsData?: DetailsContainerDto;
  errorDetails?: any;
  insuredIdAtomKey?: string;
  hasClaimsManagementPermissions: boolean;
};

const ClaimDetailsContainer: FC<ClaimDetailsContainerProps> = ({
  claimDetailsData,
  configuration,
  errorDetails,
  insuredIdAtomKey = "",
  hasClaimsManagementPermissions,
}) => {
  const [detailsUI, setDetailsUI] = useState<DetailsContainerDto | null>(
    claimDetailsData ?? null
  );
  const [claimClassOptions, setClaimClassOptions] = useState<
    Partial<SelectOptions>[] | null
  >(null);
  const [denialReasonsOptions, setDenialReasonsOptions] =
    useState<Partial<SelectOptions>[]>();
  const [claimTypeOptions, setClaimTypeOptions] = useState<
    Partial<SelectOptions>[] | null
  >(null);
  const { setAtom, getAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const readOnly = conditionHasValue(detailsUI?.isExpanded)
    ? !detailsUI?.isExpanded
    : true;
  const currentUrl = window.location.href;

  const adjusterOptions = configuration?.assigneeOptions
    ? addEmptyOption(configuration?.assigneeOptions)
    : [];

  const setDetailsData = (updatedDetailsData: any) => {
    const atomValue = getAtom();

    const newAtomValue = spreadDetailsContainersInformationWithTarget(
      atomValue,
      "detailsContainerData",
      updatedDetailsData
    );

    setAtom(newAtomValue);
    setDetailsUI(updatedDetailsData);
  };

  const updateData = (fieldName: string, value: any) => {
    setDetailsData({
      ...detailsUI,
      [fieldName]: value,
    });
  };

  const filterOutClaimReferencesNotBetweenEffectiveAndExpirationDates = (
    dropDownOptions: ClaimReferenceDto[],
    setDropDownOptions: any,
    showValue2: boolean,
    dateOfIncident?: Date
  ) => {
    const dropDownFilteredOptions: Partial<SelectOptions>[] =
      dropDownOptions
        ?.filter((o) => {
          return (
            dateOfIncident &&
            isEndDateSameOrAfterStartDate(o.effectiveDate, dateOfIncident) &&
            (o.expirationDate
              ? isDateAfterDate(o.expirationDate, dateOfIncident)
              : true)
          );
        })
        .map((o) => ({
          displayName: (showValue2 ? o.value2 + " " : "") + o.value1,
          intValue: Number(o.value2 ?? "-1"),
        })) ?? [];
    setDropDownOptions(dropDownFilteredOptions);
  };

  useEffect(() => {
    if (claimClassOptions?.length === 0 && claimTypeOptions?.length === 0) {
      setDetailsData({
        ...detailsUI,
        claimClass: undefined,
        claimType: undefined,
      });
    }
  }, [claimClassOptions, claimTypeOptions]);

  useEffect(() => {
    setDetailsUI(claimDetailsData ?? null);
  }, [claimDetailsData]);

  useEffect(() => {
    filterOutClaimReferencesNotBetweenEffectiveAndExpirationDates(
      configuration?.claimClassOptions ?? [],
      setClaimClassOptions,
      true,
      detailsUI?.dateOfIncident
    );
    filterOutClaimReferencesNotBetweenEffectiveAndExpirationDates(
      configuration?.claimTypeOptions ?? [],
      setClaimTypeOptions,
      false,
      detailsUI?.dateOfIncident
    );
  }, [detailsUI?.dateOfIncident]);

  const listenerDetailsComponent = useRecoilValue(
    TriggerComponentUpdateAtom("detailsComponent")
  );

  useEffect(() => {
    if (listenerDetailsComponent !== null) {
      const updatedAtom = getAtom();
      setDetailsUI({
        ...updatedAtom?.claimTabsInformation?.claimDetailsTab
          ?.detailsContainersInformation?.detailsContainerData,
      });
    }
  }, [listenerDetailsComponent]);

  useEffect(() => {
    setDenialReasonsOptions(configuration?.denialReasons);
  }, [configuration?.denialReasons]);

  useEffect(() => {
    if (conditionHasValue(errorDetails)) updateData("isExpanded", true);
  }, [errorDetails]);

  return (
    <Collapse
      id="claim-details-collapse"
      collapsedHeight={"200px"}
      hasContentOnReadOnly
      title={
        <Link
          linkFontType={"BOLD_TITLE"}
          displayValue={"Details"}
          name="claimDetailsCollapseTitle"
        ></Link>
      }
      expanded={!readOnly}
      onChange={(value) => {
        updateData("isExpanded", value);
        setComponentTriggers(["buttonComponent"]);
      }}
      content={
        readOnly ? (
          <Stack width={"100%"} zIndex={99}>
            <Row
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              horizontalMargin="0px"
              horizontalGutter="0px"
              verticalMargin="0px"
              verticalGutter="0px"
              allowWrap
            >
              <Col
                breakpoints={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  id="claim-detail-class"
                  name="claimDetailsCollapseReadOnlyClaimClass"
                  label="Claim Class"
                  options={claimClassOptions ?? []}
                  readOnly={readOnly}
                  value={claimClassOptions ? detailsUI?.claimClass : null}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
              <Col
                breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  id="claim-detail-type"
                  name="claimDetailsCollapseReadOnlyClaimType"
                  label="Claim Type"
                  options={claimTypeOptions ?? []}
                  readOnly={readOnly}
                  value={claimTypeOptions ? detailsUI?.claimType : null}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
              <Col
                breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  id="id-jurisdiction"
                  name="claimDetailsCollapseReadOnlyJurisdiction"
                  readOnly
                  value={detailsUI?.jurisdiction}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                  label="Jurisdiction"
                />
              </Col>
            </Row>
            <Row
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              horizontalMargin="0px"
              horizontalGutter="0px"
              verticalMargin="0px"
              verticalGutter="0px"
            >
              <Col
                breakpoints={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  id="id-insured"
                  name="claimDetailsCollapseReadOnlyInsured"
                  label="Insured"
                  readOnly
                  value={detailsUI?.insuredName}
                  labelFontType={"CAPTION"}
                  inputFontType={"BODY"}
                />
              </Col>
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Link
                  href={
                    currentUrl
                  } /* TODO - Refactor this url when there is a page to display details of one policy */
                  label="Policy"
                  labelPosition="top"
                  displayValue={detailsUI?.policyNumber}
                  containerWidth="92%"
                  onClick={() =>
                    alert(
                      "Sorry for the inconvinience. This page is under construction."
                    )
                  }
                  labelFontType={"CAPTION"}
                  linkFontType={"BODY"}
                  name="claimDetailsCollapseReadOnlyPolicyLink"
                />
              </Col>
            </Row>
          </Stack>
        ) : (
          <Stack width={"100%"} zIndex={99}>
            <Row
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              horizontalMargin="0px"
              horizontalGutter="0px"
              verticalMargin="0px"
              verticalGutter="0px"
              allowWrap
            >
              <Col
                breakpoints={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  tabIndex={1}
                  {...formInputConfiguration(
                    "id-claim-class",
                    "claimDetailsCollapseClaimClass",
                    "Claim Class",
                    !hasClaimsManagementPermissions
                  )}
                  options={claimClassOptions ?? []}
                  value={detailsUI?.claimClass}
                  onChange={(value) => {
                    updateData("claimClass", value);
                  }}
                  errorMessage={errorDetails?.claimClass}
                />
              </Col>
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  tabIndex={2}
                  {...formInputConfiguration(
                    "id-claim-type",
                    "claimDetailsCollapseClaimType",
                    "Claim Type",
                    !hasClaimsManagementPermissions
                  )}
                  options={claimTypeOptions ?? []}
                  value={detailsUI?.claimType}
                  onChange={(value) => {
                    updateData("claimType", value);
                  }}
                  errorMessage={errorDetails?.claimType}
                />
              </Col>
            </Row>
            <Row
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              horizontalMargin="0px"
              horizontalGutter="0px"
              verticalMargin="0px"
              verticalGutter="0px"
              numberOfColumns={12}
            >
              <Col
                breakpoints={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={3}
                  {...formInputConfiguration(
                    "id-jurisdiction",
                    "claimDetailsCollapseJurisdiction",
                    "Jurisdiction",
                    !hasClaimsManagementPermissions
                  )}
                  readOnly
                  value={detailsUI?.jurisdiction}
                />
              </Col>
              <Col
                breakpoints={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Link
                  {...formInputConfiguration(
                    "id-policy",
                    "name-policy",
                    "Policy",
                    true
                  )}
                  disabled={!hasClaimsManagementPermissions}
                  href={
                    currentUrl
                  } /* TODO - Refactor this url when there is a page to display details of one policy */
                  labelPosition="top"
                  displayValue={detailsUI?.policyNumber}
                  containerWidth="92%"
                  onClick={() =>
                    alert(
                      "Sorry for the inconvinience. This page is under construction."
                    )
                  }
                  name="claimDetailsCollapsePolicyLink"
                />
              </Col>
              <Col
                breakpoints={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={4}
                  {...formInputConfiguration(
                    "id-insured",
                    "claimDetailsCollapseInsured",
                    "Insured",
                    true
                  )}
                  value={detailsUI?.insuredName}
                />
              </Col>
            </Row>
            <Row
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              horizontalMargin="0px"
              horizontalGutter="0px"
              verticalMargin="0px"
              verticalGutter="0px"
            >
              <Col
                //breakpoints={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
                verticalAlign="flex-end"
              >
                <InputDate
                  tabIndex={5}
                  {...formInputConfiguration(
                    "id-date-of-incident",
                    "claimDetailsCollapseDateOfIncident",
                    "Date Of Incident",
                    !hasClaimsManagementPermissions
                  )}
                  value={detailsUI?.dateOfIncident}
                  onChangeRawValue={(value) => {
                    updateData("dateOfIncident", value);
                  }}
                  errorMessage={errorDetails?.dateOfIncident}
                />
                <div style={{ marginLeft: "10px" }}>
                  <Input
                    {...formInputConfiguration(
                      "id-day-of-week",
                      "dayOfWeek",
                      "Day of Week",
                      true
                    )}
                    value={getWeekDay(detailsUI?.dateOfIncident)}
                  />
                </div>
              </Col>
              <Col
                breakpoints={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={6}
                  {...formInputConfiguration(
                    "id-date-reported",
                    "claimDetailsCollapseDateReported",
                    "Date Reported",
                    !hasClaimsManagementPermissions
                  )}
                  value={detailsUI?.dateReported}
                  onChangeRawValue={(value) => {
                    updateData("dateReported", value);
                  }}
                />
              </Col>
              <Col
                breakpoints={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={7}
                  {...formInputConfiguration(
                    "id-date-opened",
                    "claimDetailsCollapseDateOpened",
                    "Date Opened",
                    !hasClaimsManagementPermissions
                  )}
                  value={detailsUI?.dateOpened}
                  onChangeRawValue={(value) => {
                    updateData("dateOpened", value);
                  }}
                  errorMessage={errorDetails?.dateOpened}
                />
              </Col>
            </Row>
            <Row
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              horizontalMargin="0px"
              horizontalGutter="0px"
              verticalMargin="0px"
              verticalGutter="0px"
            >
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={8}
                  {...formInputConfiguration(
                    "id-date-closed",
                    "claimDetailsCollapseClaimDateClosed",
                    "Date Closed",
                    true
                  )}
                  value={detailsUI?.dateClosed}
                />
              </Col>
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={9}
                  {...formInputConfiguration(
                    "id-date-reopened",
                    "claimDetailsCollapseClaimDateReopened",
                    "Date Reopened",
                    true
                  )}
                  value={detailsUI?.dateReOpened}
                />
              </Col>
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  id="id-adjuster"
                  name="adjuster"
                  label="Adjuster"
                  tabIndex={10}
                  optionsMaxHeight="255px"
                  readOnly={!hasClaimsManagementPermissions}
                  firstOptionAsDefault
                  options={adjusterOptions}
                  value={detailsUI?.adjusterAssigneeId}
                  labelFontType="BOLD_CAPTION"
                  onChange={(value) => {
                    updateData("adjusterAssigneeId", value);
                  }}
                  errorMessage={errorDetails?.adjusterAssigneeId}
                />
              </Col>
            </Row>
            <Row
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              horizontalMargin="0px"
              horizontalGutter="0px"
              verticalMargin="0px"
              verticalGutter="0px"
            >
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={11}
                  {...formInputConfiguration(
                    "id-reopened-reason",
                    "claimDetailsCollapseReopenedReason",
                    "Reopened Reason",
                    true
                  )}
                  value={detailsUI?.reopenReason}
                />
              </Col>
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={12}
                  {...formInputConfiguration(
                    "id-date-form-provided",
                    "claimDetailsCollapseDateFormProvided",
                    "Date Form Provided",
                    !hasClaimsManagementPermissions
                  )}
                  value={detailsUI?.dateFormProvided}
                  onChangeRawValue={(value) => {
                    updateData("dateFormProvided", value);
                  }}
                />
              </Col>
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={13}
                  {...formInputConfiguration(
                    "id-date-received",
                    "claimDetailsCollapseDateReceived",
                    "Date Received",
                    !hasClaimsManagementPermissions
                  )}
                  value={detailsUI?.dateReceived}
                  onChangeRawValue={(value) => {
                    updateData("dateReceived", value);
                  }}
                />
              </Col>
            </Row>
            <Row
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              horizontalMargin="0px"
              horizontalGutter="0px"
              verticalMargin="0px"
              verticalGutter="0px"
            >
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={14}
                  {...formInputConfiguration(
                    "id-subrogation-amount",
                    "claimDetailsCollapseSubrogationAmount",
                    "Subrogation Amount",
                    true
                  )}
                  type="currency"
                  value={detailsUI?.subroAmount ?? 0}
                  maxLength={22}
                  errorMessage={errorDetails?.subroAmount}
                  onChangeRawValue={(value) => {
                    updateData(
                      "subroAmount",
                      value === "" || value === undefined ? null : value
                    );
                  }}
                />
              </Col>
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={15}
                  {...formInputConfiguration(
                    "id-external-key",
                    "claimDetailsCollapseExternalKey",
                    "External Key",
                    true
                  )}
                  value={detailsUI?.externalKey}
                />
              </Col>
              <Col
                breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={16}
                  {...formInputConfiguration(
                    "id-claim-source",
                    "claimDetailsCollapseClaimSource",
                    "Claim Source",
                    true
                  )}
                  value={detailsUI?.claimSource}
                />
              </Col>
              <Col
                breakpoints={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Switch
                  tabIndex={17}
                  name={"drugCardFlag"}
                  readOnly={!hasClaimsManagementPermissions}
                  label={"Drug Card"}
                  labelPlacement={"top"}
                  labelFontType={"BOLD_CAPTION"}
                  control={"checkbox"}
                  isChecked={detailsUI?.drugCard}
                  onChangeIsChecked={(value) => {
                    updateData("drugCard", value);
                  }}
                />
              </Col>
            </Row>
            <Row verticalAlign="flex-start" {...rowWithNoMarginNorGutter}></Row>
            <Row verticalAlign="flex-start" {...rowWithNoMarginNorGutter}>
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  id="id-aoe"
                  name="aoe"
                  label="AOE/COE"
                  tabIndex={18}
                  readOnly={!hasClaimsManagementPermissions}
                  options={yesNoBlankOptions}
                  value={detailsUI?.aoe}
                  labelFontType="BOLD_CAPTION"
                  onChange={(value) => {
                    updateData("aoe", value);
                  }}
                />
              </Col>
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={19}
                  {...formInputConfiguration(
                    "id-date-aoe",
                    "aoeDate",
                    "AOE/COE Date",
                    !hasClaimsManagementPermissions
                  )}
                  value={detailsUI?.aoeDate}
                  onChangeRawValue={(value) => {
                    updateData("aoeDate", value);
                  }}
                />
              </Col>
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={20}
                  {...formInputConfiguration(
                    "id-date-denied",
                    "claimDetailsCollapseClaimDateDenied",
                    "Denied Date",
                    !hasClaimsManagementPermissions
                  )}
                  value={detailsUI?.dateDenied}
                  onChangeRawValue={(value) => {
                    updateData("dateDenied", value);
                  }}
                />
              </Col>
            </Row>
            <Row
              {...rowWithNoMarginNorGutter}
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
            >
              <Col
                breakpoints={{ md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Select
                  tabIndex={21}
                  {...formInputConfiguration(
                    "id-denial-reason",
                    "denialReason",
                    "Denial Reason",
                    !hasClaimsManagementPermissions
                  )}
                  options={denialReasonsOptions ?? []}
                  value={detailsUI?.denialReason}
                  onChange={(value) => {
                    updateData("denialReason", value);
                  }}
                  errorMessage={errorDetails?.denialReason}
                  optionsMaxHeight="250px"
                />
              </Col>
              <Col
                breakpoints={{ md: 8, lg: 8, xl: 8 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={22}
                  id={"denial-narrative"}
                  name={"denial-narrative"}
                  errorMessage={errorDetails?.denialNarrative}
                  label={"Denial Narrative"}
                  maxLength={300}
                  readOnly={!hasClaimsManagementPermissions}
                  value={detailsUI?.denialNarrative}
                  onChangeRawValue={(value) => {
                    updateData("denialNarrative", value);
                  }}
                />
              </Col>
            </Row>
            <Row
              verticalAlign="flex-start"
              horizontalAlign="flex-start"
              horizontalMargin="0px"
              horizontalGutter="0px"
              verticalMargin="0px"
              verticalGutter="0px"
            >
              <Col
                breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                horizontalGutter="5px"
                horizontalAlign="flex-start"
              >
                <Stack>
                  <Switch
                    tabIndex={23}
                    name={"claimDetailsCollapseDeniedFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Denied"}
                    labelPlacement={"start"}
                    labelAlignment="left"
                    control={"switch"}
                    isChecked={detailsUI?.denied}
                    onChangeIsChecked={(value) => {
                      updateData("denied", value);
                    }}
                  />
                  <Switch
                    tabIndex={24}
                    name={"claimDetailsCollapseDisputedFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Disputed"}
                    labelPlacement={"start"}
                    labelAlignment="left"
                    control={"switch"}
                    isChecked={detailsUI?.disputed}
                    onChangeIsChecked={(value) => {
                      updateData("disputed", value);
                    }}
                  />
                  <Switch
                    tabIndex={25}
                    name={"claimDetailsCollapseTriagedFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Triaged"}
                    labelPlacement={"start"}
                    labelAlignment="left"
                    control={"switch"}
                    isChecked={detailsUI?.triaged}
                    onChangeIsChecked={(value) => {
                      updateData("triaged", value);
                    }}
                  />
                  <Switch
                    tabIndex={26}
                    name={"claimDetailsCollapseLitigatedFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Litigated"}
                    labelPlacement={"start"}
                    labelAlignment="left"
                    control={"switch"}
                    isChecked={detailsUI?.litigated}
                    onChangeIsChecked={(value) => {
                      updateData("litigated", value);
                    }}
                  />
                </Stack>
              </Col>

              <Col
                breakpoints={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                horizontalGutter="5px"
                horizontalAlign="flex-end"
              >
                <Stack>
                  <Switch
                    tabIndex={27}
                    name={"claimDetailsCollapsePossibleFraudFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Possible Fraud"}
                    labelPlacement={"start"}
                    control={"switch"}
                    isChecked={detailsUI?.possibleFraud}
                    onChangeIsChecked={(value) => {
                      updateData("possibleFraud", value);
                    }}
                  />
                  <Switch
                    tabIndex={28}
                    name={"claimDetailsCollapseLienFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Lien"}
                    labelPlacement={"start"}
                    control={"switch"}
                    isChecked={detailsUI?.lien}
                    onChangeIsChecked={(value) => {
                      updateData("lien", value);
                    }}
                  />
                  <Switch
                    tabIndex={29}
                    name={"claimDetailsCollapseEscalatedFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Escalated"}
                    labelPlacement={"start"}
                    control={"switch"}
                    isChecked={detailsUI?.escalated}
                    onChangeIsChecked={(value) => {
                      updateData("escalated", value);
                    }}
                  />
                  <Switch
                    tabIndex={30}
                    name={"claimDetailsCollapseSurveillanceFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Surveillance"}
                    labelPlacement={"start"}
                    control={"switch"}
                    isChecked={detailsUI?.surveillance}
                    onChangeIsChecked={(value) => {
                      updateData("surveillance", value);
                    }}
                  />
                </Stack>
              </Col>

              <Col
                breakpoints={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                horizontalGutter="5px"
                horizontalAlign="flex-end"
              >
                <Stack>
                  <Switch
                    tabIndex={31}
                    name={"claimDetailsCollapseSubroPotentialFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Subro Potential"}
                    labelPlacement={"start"}
                    control={"switch"}
                    isChecked={detailsUI?.subroPotential}
                    onChangeIsChecked={(value) => {
                      updateData("subroPotential", value);
                    }}
                  />
                  <Switch
                    tabIndex={32}
                    name={"claimDetailsCollapseSubrogatedFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Subrogated"}
                    labelPlacement={"start"}
                    control={"switch"}
                    isChecked={detailsUI?.subrogated}
                    onChangeIsChecked={(value) => {
                      updateData("subrogated", value);
                    }}
                  />
                  <Switch
                    tabIndex={33}
                    name={"claimDetailsCollapseUnderReviewFlag"}
                    readOnly={!hasClaimsManagementPermissions}
                    label={"Under Review"}
                    labelPlacement={"start"}
                    control={"switch"}
                    isChecked={detailsUI?.underReview}
                    onChangeIsChecked={(value) => {
                      updateData("underReview", value);
                    }}
                  />
                </Stack>
              </Col>
            </Row>
          </Stack>
        )
      }
    />
  );
};

export default ClaimDetailsContainer;
