import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { PayrollReportDetailInfoPage } from "../../../../dtos/payroll-report-detail-info-page";
import { PayrollReportPayInlineDto } from "../../../../dtos/payroll-report-pay-inline-dto";
import { PayrollReportStatusEnum } from "../../../../dtos/payroll-report-status-enum";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { TableData, TableRow } from "../../../TrueUI/Tables/BaseTableStructure";
import {
  getAdjustmentTotal,
  getCellValueAdjustmentRow,
  getCellValueAdjustmentTotals,
  getCellValueRowOne,
  getCellValueRowThree,
  getCellValueRowTwo,
  getColumnWidth,
  getColumnWidthForAdjustments,
  getUpdatedTotals,
  getUpdatedTotalsDetail,
} from "./PayrollReportDetailUtils";
import PayrollReportPayInlineForm from "./PayrollReportPayInlineForm";
import { PayrollReportAdjustmentsDto } from "../../../../dtos/payroll-report-adjustments-dto";
import { EditedPayrollReportDetailsDto } from "../../../../dtos/edited-payroll-report-details-dto";

export const CustomFooterComponentGeneralPayrollReport = (
  rows: any,
  isCheckPaymentDisabled: boolean,
  payrollReportDetailInfo: Partial<PayrollReportDetailInfoPage> | null,
  editedPayrollReportDetails: Partial<EditedPayrollReportDetailsDto> | null,
  onAdjustmentChange: (
    updatedValue: any,
    parameterName: string,
    adjustmentId: number
  ) => void,
  payInlineData: PayrollReportPayInlineDto | null,
  setPayInlineData: (value: PayrollReportPayInlineDto | null) => void,
  errorDetails: any
) => {
  const rowBase = rows?.[0];
  const { totalEmployees, totalPayroll, totalAmount } = getUpdatedTotals(
    payrollReportDetailInfo?.referenceDetailsRows ?? [],
    editedPayrollReportDetails?.detailsRows ?? [],
    rows
  );
  const hasAdjustments =
    conditionHasValue(payrollReportDetailInfo?.payrollReportAdjustments) &&
    payrollReportDetailInfo?.payrollReportAdjustments?.length > 0;

  return (
    <>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
        }}
      >
        {rowBase?.Columns.map((column, key) => (
          <TableData key={key} width={getColumnWidth(rowBase?.Columns, column)}>
            {getCellValueRowOne(
              column,
              totalEmployees,
              totalPayroll,
              totalAmount,
              hasAdjustments
            )}
          </TableData>
        ))}
        <TableData width={"40px"}></TableData>
      </TableRow>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
        }}
      >
        {rowBase?.Columns.map((column, key) => (
          <TableData key={key} width={getColumnWidth(rowBase?.Columns, column)}>
            {getCellValueRowTwo(column, payrollReportDetailInfo?.amountPaid)}
          </TableData>
        ))}
        <TableData width={"40px"}></TableData>
      </TableRow>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
        }}
      >
        {rowBase?.Columns.map((column, key) => (
          <TableData key={key} width={getColumnWidth(rowBase?.Columns, column)}>
            {getCellValueRowThree(
              column,
              totalAmount,
              payrollReportDetailInfo?.amountPaid
            )}
          </TableData>
        ))}
        <TableData width={"40px"}></TableData>
      </TableRow>
      {hasAdjustments &&
        MappedAdjustmentsRows(
          rowBase,
          totalAmount,
          editedPayrollReportDetails?.adjustmentsRows ?? [],
          onAdjustmentChange
        )}
      {payrollReportDetailInfo?.statusId === PayrollReportStatusEnum.ACTIVE && (
        <TableRow
          style={{
            width: "100%",
            display: "flex",
            fontWeight: "700",
          }}
        >
          <PayrollReportPayInlineForm
            allColumns={rowBase?.Columns}
            totalAmount={totalAmount}
            payInlineData={payInlineData}
            setPayInlineData={setPayInlineData}
            errorDetails={errorDetails}
            isCheckPaymentDisabled={isCheckPaymentDisabled}
          />
        </TableRow>
      )}
    </>
  );
};

const MappedAdjustmentsRows = (
  rowBase: any,
  totalAmount: any,
  payrollReportAdjustments: PayrollReportAdjustmentsDto[],
  onAdjustmentChange: (
    updatedValue: any,
    parameterName: string,
    adjustmentId: number
  ) => void
) => {
  return (
    <>
      {payrollReportAdjustments?.map((adjustment, adjustmentKey) => (
        <TableRow
          key={adjustmentKey}
          style={{
            width: "100%",
            display: "flex",
            fontWeight: "700",
            height: "48px",
          }}
        >
          {rowBase?.Columns.map((column, key) => (
            <TableData
              key={key}
              width={getColumnWidthForAdjustments(rowBase?.Columns, column)}
            >
              {getCellValueAdjustmentRow(
                adjustment,
                column,
                totalAmount,
                onAdjustmentChange
              )}
            </TableData>
          ))}
          <TableData width={"40px"}></TableData>
        </TableRow>
      ))}
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
        }}
      >
        {rowBase?.Columns.map((column, key) => (
          <TableData key={key} width={getColumnWidth(rowBase?.Columns, column)}>
            {getCellValueAdjustmentTotals(
              column,
              getAdjustmentTotal(payrollReportAdjustments ?? [], totalAmount)
            )}
          </TableData>
        ))}
        <TableData width={"40px"}></TableData>
      </TableRow>
    </>
  );
};

export const CustomFooterComponentDetail = (
  rows: any,
  columns: BaseTableColumn[]
) => {
  const { totalEmployees, totalPayroll, totalAmount } =
    getUpdatedTotalsDetail(rows);
  return (
    <>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
        }}
      >
        {columns.map((column, key) => (
          <TableData
            key={key}
            width={getColumnWidth(columns, column.fieldName)}
          >
            {getCellValueRowOne(
              column.fieldName,
              totalEmployees,
              totalPayroll,
              totalAmount
            )}
          </TableData>
        ))}
        <TableData width={"35px"}></TableData>
      </TableRow>
    </>
  );
};
