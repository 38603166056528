import { FC, useEffect, useState } from "react";
import { useApiGet, useApiPost } from "../../../hooks";
import { QuickSearchClaimsDto } from "../../../dtos/quick-search-claims-dto";
import { TabPanel, Tabs } from "../../TrueUI";
import { SelectOptions } from "../../../dtos/select-options";
import { useNavigate } from "react-router";
import { IncidentPropsForContextMenu } from "../../Insured/Claim/Incident/Incident";
import { SystemNumberDto } from "../../../dtos/system-number-dto";
import { SubSideNavItemDestinationsEnum } from "../../../dtos/sub-side-nav-item-destinations-enum";
import {
  IncidentInteractiveDialog,
  ReasonComponent,
} from "../../Insured/Claim/Incident/IncidentTable/IncidentInteractiveDialog";
// import { IncidentTableConfiguration } from "../../Insured/Claim/Incident/IncidentTable/IncidentTableConfiguration";
import {
  CLAIM_NUMBER,
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
  TOP_LEVEL_CLAIMS_TABS,
} from "../../../utilities/queryStringsHash";
// import { useBaseTable } from "../../../hooks/useBaseTable";
import { getQueryStringsURL } from "../../../utilities/URLUtilities_OBSOLETE";
import { SubSideNavParentsIndexEnum } from "../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../dtos/sub-side-nav-children-index-enum";
import AddIncidentModal from "./AddIncidentModal";
import "../ClaimsStyles.css";
import { isAPITotallyCompleteNoContentResponse } from "../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { IncidentStatusEnums } from "../../../dtos/incident-status-enums";
// import { FormattingDate } from "../../../utilities/dateFunctions";
// import { formattingKey } from "../../../utilities/enumFunctions";
// import { useAtomFamily } from "../../../hooks/useAtomFamily";
// import { globalOptions } from "../../../GlobalAtoms";
// import { ClaimStatusEnums } from "../../../dtos/claim-status-enums";
// import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
// import BaseGrid from "../../BaseGrid/BaseGrid";
// import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
// import BaseGridSearchFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridSearchFilter";
import TopClaimsGrid from "./TopClaimsGrid";
import TopIncidentsGrid from "./TopIncidentsGrid";
import CreateClaimModalContent from "../../Insured/Claim/Incident/IncidentTable/CreateClaimModalContent/CreateClaimModalContent";
import PrintIncident from "../../Incident/PrintIncident";

export type ClaimsIncidentsFiltersOptionsProps = {
  assigneesFilter: Partial<SelectOptions>[];
  claimTypes: Partial<SelectOptions>[];
  claimStatuses: Partial<SelectOptions>[];
  incidentStatuses: Partial<SelectOptions>[];
  allAssignees: Partial<SelectOptions>[];
  optionsRendered: boolean;
};

const optionAll: Partial<SelectOptions>[] = [
  {
    displayName: "All",
    stringValue: "all",
  },
];

type TopClaimsAndIncidentsTabsProperties = {};
const TopClaimsAndIncidentsTabs: FC<
  TopClaimsAndIncidentsTabsProperties
> = () => {
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchFilters, setSearchFilters] =
    useState<ClaimsIncidentsFiltersOptionsProps | null>(null);

  const navigate = useNavigate();

  const [incidentContextMenuValues, setIncidentContextMenuValues] =
    useState<IncidentPropsForContextMenu>();

  const { responseGet: responseGetOptions, dispatchGet: dispatchGetOptions } =
    useApiGet<QuickSearchClaimsDto>("api/Claims/GetQuickSearchOptions");

  const { responsePost: responseStatusPost, dispatchPost: dispatchStatusPost } =
    useApiPost(
      `api/Incident/UpdateIncidentStatusByIncidentId?incidentId=${incidentContextMenuValues?.incidentId}` +
        `&incidentStatus=${incidentContextMenuValues?.incidentStatus}&reason=${incidentContextMenuValues?.reason}`
    );

  const {
    responseGet: responseNewClaimProcessGet,
    dispatchGet: dispatchNewClaimProcessGet,
  } = useApiGet<SystemNumberDto>(
    `api/Claims/NewClaimProcess?incidentId=${incidentContextMenuValues?.incidentId}` +
      `&assigneeId=${incidentContextMenuValues?.assigneeId}`
  );

  useEffect(() => {
    dispatchGetOptions();
  }, []);

  useEffect(() => {
    if (
      responseGetOptions?.requestInstanceSuccessful === true &&
      responseGetOptions?.axiosResponse?.data !== undefined
    ) {
      const quickSearchOptions = responseGetOptions?.axiosResponse
        ?.data as QuickSearchClaimsDto;
      setSearchFilters({
        ...searchFilters,
        assigneesFilter: optionAll.concat(
          quickSearchOptions.assigneesListFilter.map((assignee) => ({
            displayName: assignee.displayName,
            intValue: assignee?.intValue,
          })) ?? []
        ),
        claimTypes: optionAll.concat(quickSearchOptions.claimTypes ?? []),
        claimStatuses: optionAll.concat(quickSearchOptions.claimStatuses ?? []),
        incidentStatuses: optionAll.concat(
          quickSearchOptions.incidentStatuses ?? []
        ),
        allAssignees: quickSearchOptions.allAssignees,
        optionsRendered: true,
      });
    }
  }, [responseGetOptions]);

  // Hooks related to context menu values
  useEffect(() => {
    if (incidentContextMenuValues?.dialogConfirmationOpen)
      setIncidentContextMenuValues({
        ...incidentContextMenuValues,
        readyToRender: false,
      });
    if (
      !incidentContextMenuValues?.dialogConfirmationOpen &&
      !incidentContextMenuValues?.dialogConfirmationAnswerIsYes
    )
      setIncidentContextMenuValues({
        readyToRender: true,
      });
  }, [incidentContextMenuValues?.dialogConfirmationOpen]);

  const validateAssigneeId = () => {
    return searchFilters !== undefined &&
      (searchFilters?.allAssignees ?? []).length > 0 &&
      searchFilters?.allAssignees[0].intValue !== null
      ? searchFilters?.allAssignees[0].intValue
      : undefined;
  };

  const [executeClaimsTableAction, setExecuteClaimsTableAction] = useState<
    "update" | "delete" | null
  >(null);
  const updateTable = () => {
    if (conditionHasValue(incidentContextMenuValues?.row)) {
      if (
        incidentContextMenuValues?.incidentStatus != IncidentStatusEnums.DELETED
      ) {
        setExecuteClaimsTableAction("update");
      } else {
        setExecuteClaimsTableAction("delete");
      }
    }
  };

  useEffect(() => {
    if (incidentContextMenuValues?.runCreateClaimProcess)
      setIncidentContextMenuValues({
        ...incidentContextMenuValues,
        assigneeId: validateAssigneeId(),
      });
  }, [incidentContextMenuValues?.runCreateClaimProcess]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseStatusPost)) {
      updateTable();
    }
  }, [responseStatusPost]);

  useEffect(() => {
    if (responseNewClaimProcessGet.requestInstanceSuccessful) {
      setIncidentContextMenuValues({
        ...incidentContextMenuValues,
        assigneeId: undefined,
      });

      const queryStringsToIncident = getQueryStringsURL([
        {
          nameOfHash: INSURED_PARENT_ITEM,
          valueOfHash: SubSideNavParentsIndexEnum.CLAIMS,
        },
        {
          nameOfHash: INSURED_CHILD_ITEM,
          valueOfHash: SubSideNavChildrenIndexEnum.CLAIMS_CLAIMS,
        },
        {
          nameOfHash: INSURED_BODY_SECTION,
          valueOfHash: SubSideNavItemDestinationsEnum.CLAIMS_LANDING,
        },
        {
          nameOfHash: CLAIM_NUMBER,
          valueOfHash:
            responseNewClaimProcessGet.axiosResponse?.data.numberGenerated ?? 0,
        },
      ]);
      navigate(
        `/insured/${incidentContextMenuValues?.insuredId}${queryStringsToIncident}`
      );
    }
  }, [responseNewClaimProcessGet]);

  const assigneeOnChange = (select) => {
    setIncidentContextMenuValues({
      ...incidentContextMenuValues,
      assigneeId: select?.value === undefined ? select : select?.value,
    });
  };

  const assigneeValueSelected = () => {
    return searchFilters?.allAssignees !== undefined &&
      searchFilters?.allAssignees.length > 0
      ? searchFilters?.allAssignees[0].intValue
      : "";
  };

  const reasonOnChange = (value) =>
    setIncidentContextMenuValues({
      ...incidentContextMenuValues,
      reason: value,
    });

  //End of - Methods for dialog displayed components

  // const { tableMethods } = useBaseTable([
  //   tableConfigurationClaimsTab,
  //   tableIncidentsConfiguration(),
  // ]);
  // const tableInstanceMethods = tableMethods[tableClaimsName];

  useEffect(() => {
    if (incidentContextMenuValues?.dispatchConfirmation) {
      if (incidentContextMenuValues?.runCreateClaimProcess) {
        dispatchNewClaimProcessGet();
      } else {
        dispatchStatusPost();
      }
    }
  }, [incidentContextMenuValues?.dispatchConfirmation]);

  return (
    <>
      <div id={"claims_main_container"}>
        {searchFilters !== null && searchFilters?.optionsRendered && (
          <Tabs name={TOP_LEVEL_CLAIMS_TABS} backgroundTransparent>
            <TabPanel title={"CLAIMS"} key={"topLevelClaimsClaimsTab"}>
              <div
                id={"claims_table_container"}
                style={{
                  flexGrow: "1",
                  display: "flex",
                  height: "100%",
                  width: "100%",
                }}
              >
                <TopClaimsGrid
                  searchFilters={searchFilters}
                  executeClaimsTableAction={executeClaimsTableAction}
                  incidentContextMenuValues={incidentContextMenuValues}
                />
              </div>
            </TabPanel>
            <TabPanel title={"INCIDENTS"} key={"topLevelClaimsIncidentTab"}>
              <TopIncidentsGrid
                searchFilters={searchFilters}
                setIncidentContextMenuValues={setIncidentContextMenuValues}
                navigate={navigate}
                setShowSearchModal={setShowSearchModal}
              />
              <PrintIncident
                dispatchPrintFROI={incidentContextMenuValues?.dispatchPrintFROI}
                setDispatchPrintFROI={(value) =>
                  setIncidentContextMenuValues({
                    ...incidentContextMenuValues,
                    dispatchPrintFROI: value,
                  })
                }
                incidentId={parseInt(
                  incidentContextMenuValues?.incidentId ?? "0"
                )}
                stateCode={incidentContextMenuValues?.incidentStateCode ?? ""}
                claimantName={
                  incidentContextMenuValues?.incidentClaimantName ?? ""
                }
              />
            </TabPanel>
          </Tabs>
        )}
      </div>
      <AddIncidentModal
        showModal={showSearchModal}
        setShowModal={setShowSearchModal}
      />
      <IncidentInteractiveDialog
        incidentContextMenuValues={incidentContextMenuValues}
        setIncidentContextMenuValues={setIncidentContextMenuValues}
        ReasonComponent={() =>
          ReasonComponent(incidentContextMenuValues?.reason, reasonOnChange)
        }
        AssigneeComponent={() =>
          CreateClaimModalContent(
            searchFilters?.allAssignees ?? [],
            assigneeValueSelected(),
            assigneeOnChange,
            parseInt(incidentContextMenuValues?.incidentId ?? "-1")
          )
        }
      />
    </>
  );
};

export default TopClaimsAndIncidentsTabs;
